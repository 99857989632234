import * as React from 'react';
import { TextStyle } from 'react-native';
import { colors } from 'react-native-elements';

export type MenuItem = {
  item?: React.FC;
  title?: string | React.ReactNode;
  titleBold?: boolean;
  titleColor?: string;
  onPress?: () => void;
  iconType?: string;
  iconName?: string;
  iconColor?: string;
  disabled?: boolean;
  hide?: boolean;
};

const FONT_SIZE = 17;

export const getTitleStyle = (color?:string, bold?:boolean, disabled?: boolean): TextStyle => {
  if (disabled) {
    return {
      fontSize: FONT_SIZE,
      fontWeight: bold ? 'bold' : 'normal',
      color: colors.grey3,
    };
  }
  if (color) {
    return {
      fontSize: FONT_SIZE,
      fontWeight: bold ? 'bold' : 'normal',
      color,
    };
  }
  return { fontSize: FONT_SIZE, fontWeight: bold ? 'bold' : 'normal' };
};
