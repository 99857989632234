import React from 'react';
import {
  StyleSheet, ActivityIndicator, Platform, Modal as NativeModal,
} from 'react-native';

import { colors } from 'react-native-elements';
import { View } from './Themed';

// This module does not support type script.
const Modal = require('modal-react-native-web');

export function LoadingOverlay({ visible }: { visible: boolean }) {
  const loadingIndicator = (
    <View style={styles.container}>
      <ActivityIndicator
        color={colors.grey3}
        size="large"
      />
    </View>
  );
  if (Platform.OS === 'web') {
    return (
      <Modal visible={visible} animationType="fade" transparent ariaHideApp={false}>
        {loadingIndicator}
      </Modal>
    );
  }
  return (
    <NativeModal visible={visible} animationType="fade" transparent>
      {loadingIndicator}
    </NativeModal>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    justifyContent: 'center',
    opacity: 0.6,
  },
});
