import * as React from 'react';
import { StyleSheet } from 'react-native';
import {
  Input, Text, Button, colors, ListItem,
} from 'react-native-elements';
import { Modalize } from 'react-native-modalize';
import { useTheme } from '@react-navigation/native';

import { View } from './Themed';
import { useDimension } from '../context';

type TagsModalProps = {
  tags: string[];
  allTags: string[];
  setTagsFn: (tags: string[]) => void;
  addTagFn: (tag: string) => void;
  onSave: () => void;
};

type TagProps = {
  title: string;
};

type TagsProps = {
  tags: string[];
};

export type TagsModalRef = {
  open: () => void;
  close: () => void;
};

export const Tag = (props: TagProps) => {
  const { title } = props;
  return (
    <View style={styles.tag}>
      <Text style={styles.tagText}>{title}</Text>
    </View>
  );
};

export const Tags = (props: TagsProps) => {
  const { tags } = props;
  return (
    <View style={styles.tags}>
      {tags.length === 0 ? (
        <Text style={styles.noTag}>No Tags</Text>
      ) : tags.map((t, i) => (<Tag title={t} key={i.toString()} />))}
    </View>
  );
};

const TagsModal = React.forwardRef((props: TagsModalProps, ref) => {
  const {
    setTagsFn, addTagFn, onSave, tags, allTags,
  } = props;
  const combinedTags = Array.from(new Set(allTags.concat(tags)));
  combinedTags.sort();
  const [inputTag, setInputTag] = React.useState('');
  const modalizeRef = React.useRef<Modalize>(null);
  const { screenHeight: height } = useDimension();
  const { colors } = useTheme();

  React.useImperativeHandle(ref, () => ({ open, close }));

  function open() {
    modalizeRef.current?.open();
  }

  function close() {
    modalizeRef.current?.close();
  }

  const addTag = async (tag: string) => {
    const newTags = tags.concat(tag);
    newTags.sort();
    setTagsFn(newTags);
  };

  const removeTag = (tag: string) => {
    setTagsFn(tags.filter((t) => t !== tag));
  };

  const header = (
    <>
      <View style={styles.header}>
        <Text style={styles.title}>Tags</Text>
        <Tags tags={tags} />
      </View>
      <Input
        placeholder="Search or enter a new tag"
        autoCapitalize="none"
        autoCorrect={false}
        value={inputTag}
        onChangeText={(text) => setInputTag(text)}
        onSubmitEditing={async (e) => {
          const t = e.nativeEvent.text;
          if (!t) {
            return;
          }
          if (!allTags.includes(t)) { await addTagFn(t); }
          if (!tags.includes(t)) { addTag(t); }
          setInputTag('');
        }}
        autoCompleteType="off"
      />
    </>
  );

  const footer = (
    <Button
      containerStyle={styles.button}
      title="Save"
      onPress={() => {
        onSave();
        close();
      }}
    />
  );

  return (
    <Modalize
      ref={modalizeRef}
      modalStyle={[styles.modal, { backgroundColor: colors.background }]}
      modalHeight={height * 0.85}
      withReactModal
      HeaderComponent={header}
      FooterComponent={footer}
    >
      {allTags.filter((t) => t.includes(inputTag)).map((t, i) => {
        const selected = tags.includes(t);
        return (
          <ListItem
            key={i.toString()}
            onPress={() => {
              if (selected) {
                removeTag(t);
              } else {
                addTag(t);
              }
            }}
            containerStyle={styles.tagItem}
            tvParallaxProperties={{}}
            hasTVPreferredFocus
          >
            <ListItem.Content>
              <ListItem.Title>{t}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron
              size={25}
              type="material-community"
              name={selected ? 'minus-circle' : 'plus-circle'}
              color={selected ? 'red' : colors.primary}
              tvParallaxProperties
              hasTVPreferredFocus
            />
          </ListItem>
        );
      })}
    </Modalize>
  );
});

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    padding: 15,
  },
  button: {
    marginTop: 10,
    marginBottom: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  header: {
    paddingTop: 20,
    paddingLeft: 10,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  tag: {
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    height: 30,
  },
  tagText: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tags: {
    paddingVertical: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'transparent',
  },
  noTag: {
    marginVertical: 10,
    color: colors.grey3,
  },
  tagItem: {
    paddingVertical: 10,
  },
});

export { TagsModal };
