/* eslint-disable class-methods-use-this */
import { IndexPost, Topics } from '../types';
import { ParserDiscourse } from './parserDiscourse';

class ParserPowerApple extends ParserDiscourse {
  BOARD_TO_TOPICS_MAP = new Map([
    [18, Topics.Gossip],
    [38, Topics.Parenting],
    [19, Topics.Parenting],
    [20, Topics.Parenting],
    [27, Topics.Home],
    [31, Topics.RealEstate],
    [29, Topics.RealEstate],
    [30, Topics.Home],
    [32, Topics.RealEstate],
    [28, Topics.Home],
    [5, Topics.Immigration],
    [12, Topics.Immigration],
    [7, Topics.Immigration],
    [8, Topics.Visa],
    [9, Topics.Visa],
    [10, Topics.Visa],
    [11, Topics.Visa],
    [13, Topics.Visa],
    [22, Topics.Gossip],
    [25, Topics.Car],
    [26, Topics.Play],
    [37, Topics.Gossip],
    [41, Topics.Bless],
  ]);

  SOURCE = 'powerapple.com';

  ID_PREFIX = 'powerapple-';

  BASE_URL = 'https://bbs.powerapple.com';

  getIndexPageUrls(): string[] {
    return [`${this.BASE_URL}/index_page_data.json`];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseIndexPage(html: string, url: string): IndexPost[] {
    const data = JSON.parse(html);
    const postItems: IndexPost[] = [];
    data.category_list.categories.forEach((c:any) => {
      c.topics.forEach((t: any) => postItems.push({ id: `${this.ID_PREFIX}${t.id}` }));
    });
    return postItems;
  }
}

export const parser = new ParserPowerApple();
