import firebase from 'firebase/app';

function auth() {
  return firebase.auth();
}

export async function currentUserAwait(): Promise<firebase.User | null> {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export function currentUser() {
  return auth().currentUser;
}

export function isEmailVerified(user?: User | null) {
  const currentUser = user === undefined ? auth().currentUser : user;
  return currentUser?.emailVerified;
}

export function isSignedIn(user?: User | null) {
  const currentUser = user === undefined ? auth().currentUser : user;
  return currentUser !== null && !currentUser.isAnonymous;
}

export function hasPassword(user?: User | null) {
  const currentUser = user === undefined ? auth().currentUser : user;
  return currentUser?.providerData.findIndex((d) => d?.providerId === 'password') !== -1;
}

export async function refreshUser() {
  return auth().currentUser?.reload();
}

export async function signInAnonymously() {
  const currentUser = await currentUserAwait();
  if (!currentUser) {
    console.log('Sign in anonymouly');
    await auth().signInAnonymously();
  }
}

export async function signInWithCredential(credential: firebase.auth.AuthCredential) {
  return auth().signInWithCredential(credential);
}

export async function signInWithEmailAndPassword(email: string, password: string) {
  return auth().signInWithEmailAndPassword(email, password);
}

export async function reauthenticateWithEmailAndPassword(email: string, password: string) {
  const cred = getEmailCredential(email, password);
  return auth().currentUser?.reauthenticateWithCredential(cred);
}

export async function signOut() {
  return auth().signOut().then(() => signInAnonymously());
}

export async function createUserWithEmailAndPassword(email: string, password: string) {
  return auth().createUserWithEmailAndPassword(email, password);
}

export async function linkWithCredential(credential: firebase.auth.AuthCredential) {
  try {
    return await auth().currentUser?.linkWithCredential(credential);
  } catch (error: any) {
    return signInWithCredential(error.credential);
  }
}

export async function sendEmailVerification() {
  return auth().currentUser?.sendEmailVerification();
}

export async function sendPasswordResetEmail(email: string) {
  return auth().sendPasswordResetEmail(email);
}

export async function verifyPasswordResetCode(code: string) {
  return auth().verifyPasswordResetCode(code);
}

export async function confirmPasswordReset(code: string, password: string) {
  return auth().confirmPasswordReset(code, password);
}

export async function updatePassword(password: string) {
  return auth().currentUser?.updatePassword(password);
}

export async function applyActionCode(code: string) {
  return auth().applyActionCode(code);
}

export async function getIdToken() {
  const { currentUser } = auth();
  return currentUser?.getIdToken();
}

export function getEmailCredential(email: string, password: string) {
  return firebase.auth.EmailAuthProvider.credential(email, password);
}

export function getGoogleAuthCredential(idToken?: string | null, accessToken?: string | null) {
  return firebase.auth.GoogleAuthProvider.credential(idToken, accessToken);
}

export function getOAuthCredential(name: string) {
  return new firebase.auth.OAuthProvider(name);
}

export function checkSignedIn(navigation: any) {
  if (!isSignedIn()) {
    navigation.navigate('AuthHome', { title: 'Sign in to continue ...' });
    return false;
  }
  return true;
}

type AuthCredential = firebase.auth.AuthCredential;

type User = firebase.User;

export type { AuthCredential, User };
