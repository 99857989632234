import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import AuthActionScreen from '../screens/AuthActionScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import PostScreen from '../screens/PostScreen';
import AuthScreen from '../screens/AuthScreen';
import AuthHomeScreen from '../screens/AuthHomeScreen';
import SettingsScreen from '../screens/SettingsScreen';
import ReportPostScreen from '../screens/ReportPostScreen';
import SavedPostsScreen from '../screens/SavedPostsScreen';
import ViewedPostsScreen from '../screens/ViewedPostsScreen';
import TopicPostsScreen from '../screens/TopicPostsScreen';
import BlockedUsersScreen from '../screens/BlockedUsersScreen';
import BlockedSourcesScreen from '../screens/BlockedSourcesScreen';
import PostItemScreen from '../screens/PostItemScreen';

import { RootStackParamList } from '../types';
import BottomTabNavigator from './BottomTabNavigator';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
// function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
//   const navTheme = colorScheme === 'dark' ? DarkTheme : DefaultTheme;
//   const theme = {
//     ...navTheme,
//     colors: {
//       ...navTheme.colors,
//       ...Colors[colorScheme || 'light'],
//     },
//   };
//   theme.colors.primary = Colors[colorScheme || 'light'].primary;
//   const { signInPrompted, setSignInPrompted } = useStores();
//   React.useEffect(() => {
//     setSignInPrompted(true);
//   }, []);
//   const navigationContainer = (
//     <NavigationContainer
//       linking={LinkingConfiguration}
//       theme={theme}
//       initialState={!signInPrompted ? {
//         type: 'tab',
//         index: 0,
//         routes: [
//           {
//             name: 'Root',
//             state: {
//               type: 'stack',
//               index: 0,
//               routes: [
//                 {
//                   name: 'BottomTab',
//                   state: {
//                     type: 'tab',
//                     index: 1,
//                     routes: [
//                       { name: 'Home' },
//                       { name: 'Topics' },
//                       { name: 'Notifications' },
//                       { name: 'Profile' },
//                     ],
//                   },
//                 },
//                 { name: 'AuthHome', params: { title: '' } },
//               ],
//             },
//           },
//         ],
//       } : undefined}
//     >
//       <RootBottomTabNavigator />
//     </NavigationContainer>
//   );
//   const { width } = useDimension();
//   return (
//     <View style={styles.container}>
//       <View style={{
// flex: 1, width, backgroundColor: Colors[colorScheme || 'light'].background }}>
//         {navigationContainer}
//       </View>
//     </View>
//   );
// }

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

export default function RootNavigator({ version }: { version: string }) {
  return (
    <Stack.Navigator screenOptions={{
      headerShown: false, headerBackTitle: 'Back', headerTitle: '', cardStyle: { flex: 1 },
    }}>
      <Stack.Screen name="BottomTab" component={BottomTabNavigator} />
      <Stack.Screen name="Post" component={PostScreen} />
      <Stack.Screen name="PostItem" component={PostItemScreen} options={({ route }) => ({ title: route.params.title })} />
      <Stack.Screen name="Auth" component={AuthScreen} options={{ headerShown: true }} />
      <Stack.Screen name="AuthAction" component={AuthActionScreen} />
      <Stack.Screen name="Settings" component={SettingsScreen} options={{ headerShown: true, headerTitle: 'Settings' }} initialParams={{ version }}/>
      <Stack.Screen name="NotFound" component={NotFoundScreen} />
      <Stack.Screen name="ReportPost" component={ReportPostScreen} options={{ headerShown: true, headerTitle: 'Report Post' }} />
      <Stack.Screen name="SavedPosts" component={SavedPostsScreen} options={{ headerShown: true, headerTitle: 'Saved Posts' }} />
      <Stack.Screen name="ViewedPosts" component={ViewedPostsScreen} options={{ headerShown: true, headerTitle: 'View History' }} />
      <Stack.Screen name="TopicPosts" component={TopicPostsScreen} options={({ route }) => ({ headerShown: true, headerTitle: route.params.title })} />
      <Stack.Screen name="BlockedUsers" component={BlockedUsersScreen} options={{ headerShown: true, headerTitle: 'Blocked Users' }} />
      <Stack.Screen name="BlockedSources" component={BlockedSourcesScreen} options={{ headerShown: true, headerTitle: 'Post Filters' }} />
      <Stack.Screen name="AuthHome" component={AuthHomeScreen} />
    </Stack.Navigator>
  );
}

// type BottomTabParamList = {
//   Root: undefined;
// };

// const BottomTab = createBottomTabNavigator<BottomTabParamList>();

// function RootBottomTabNavigator() {
//   return (
//     <BottomTab.Navigator
//       initialRouteName="Root"
//       tabBarOptions={{
//         activeTintColor: 'transparent',
//         style: {
//           height: 0, width: 0, padding: 0, margin: 0,
//         },
//         safeAreaInsets: { bottom: 0 },
//       }}
//     >
//       <BottomTab.Screen
//         name="Root"
//         component={RootNavigator}
//       />
//     </BottomTab.Navigator>
//   );
// }

// const styles = StyleSheet.create({ container: { flex: 1, alignItems: 'center' } });
