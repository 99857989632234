import * as React from 'react';
import {
  StyleSheet, TouchableOpacity, ScrollView, Linking,
} from 'react-native';
import {
  Text, Button, Input, colors,
} from 'react-native-elements';
import { showMessage } from 'react-native-flash-message';
import { useQueryClient } from 'react-query';

import { View } from '@topicfeed/appcommon/components/Themed';
import { LoadingOverlay } from '@topicfeed/appcommon/components/LoadingOverlay';
import { verifyEmailInput, verifyPasswordInput } from '@topicfeed/appcommon/utils/parser';
import * as auth from '@topicfeed/appcommon/utils/auth';
import { useUser } from '@topicfeed/appcommon/context/UserContext';
import { useStores } from '@topicfeed/appcommon/models';
import { openURL } from '@topicfeed/appcommon/utils/polyfill';

import { RootStackScreenProps } from '../types';

const URL_TOS = 'https://www.topicfeed.app/terms.html';
const URL_PRIVACY = 'https://www.topicfeed.app/privacy.html';

export default function AuthScreen({ navigation, route }: RootStackScreenProps<'Auth'>) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [mode, setMode] = React.useState(route.params.mode || 'signin');
  const [isLoading, setIsLoading] = React.useState(false);
  const { user, reloadUser } = useUser();
  const queryClient = useQueryClient();
  const store = useStores();

  const onChangePassword = () => {
    if (!auth.isSignedIn(user)) {
      showMessage({
        message: 'You are not signed in.',
        type: 'danger',
      });
      return;
    }
    const msg = verifyPasswordInput(oldPassword)
    || verifyPasswordInput(password, confirmPassword, true);
    if (msg) {
      showMessage({
        message: msg,
        type: 'danger',
      });
      return;
    }
    setIsLoading(true);
    auth.reauthenticateWithEmailAndPassword(user?.email || '', oldPassword)
      .then(() => auth.updatePassword(password))
      .then(() => {
        setIsLoading(false);
        showMessage({
          message: 'Successfully changed password.',
          type: 'success',
        });
        navigation.goBack();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        showMessage({
          message: error.message,
          type: 'danger',
        });
      });
  };

  const onSendPasswordResetEmail = () => {
    const msg = verifyEmailInput(email);
    if (msg) {
      showMessage({
        message: msg,
        type: 'danger',
      });
      return;
    }
    setIsLoading(true);
    auth.sendPasswordResetEmail(email.trim())
      .then(() => {
        setIsLoading(false);
        setMode('signin');
        showMessage({
          message: `Reset password email sent to ${email}`,
          type: 'success',
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        showMessage({
          message: error.message,
          type: 'danger',
        });
      });
  };

  const onSignIn = () => {
    const msg = verifyEmailInput(email) || verifyPasswordInput(password);
    if (msg) {
      showMessage({
        message: msg,
        type: 'danger',
      });
      return;
    }
    setIsLoading(true);
    auth.signInWithEmailAndPassword(email.trim(), password)
      .then(() => {
        console.log('User account signed in!');
        setIsLoading(false);
        showMessage({
          message: `You have signed in with ${email}`,
          type: 'success',
        });
        queryClient.invalidateQueries('topics');
        store.resetScreenStates();
        navigation.goBack();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        showMessage({
          message: error.message,
          type: 'danger',
        });
      });
  };

  const onSignUp = () => {
    const msg = verifyEmailInput(email) || verifyPasswordInput(password, confirmPassword, true);
    if (msg) {
      showMessage({
        message: msg,
        type: 'danger',
      });
      return;
    }
    setIsLoading(true);
    // auth.createUserWithEmailAndPassword(email.trim(), password)
    auth.linkWithCredential(
      auth.getEmailCredential(email.trim(), password),
    )
      .then(() => {
        console.log('User account linked with email, reloading user.');
        return reloadUser();
      })
      .then(() => {
        console.log('User reloaded, sending email verification.');
        return auth.sendEmailVerification();
      })
      .then(() => {
        setIsLoading(false);
        navigation.replace('AuthAction', { mode: 'sentEmail', oobCode: email });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        showMessage({
          message: error.message,
          type: 'danger',
        });
      });
  };

  const signInScreen = () => (
    <>
      <Input
        label="Email"
        style={styles.input}
        value={email}
        onChangeText={(text) => setEmail(text)}
        autoFocus
        autoCapitalize="none"
        autoCorrect={false}
        textContentType="emailAddress"
        autoCompleteType="username"
      />
      <Input
        label="Password"
        style={styles.input}
        value={password}
        onChangeText={(text) => setPassword(text)}
        autoCapitalize="none"
        autoCorrect={false}
        secureTextEntry
        textContentType="password"
        autoCompleteType="password"
      />
      <Button
        containerStyle={styles.button}
        title="Sign In"
        onPress={onSignIn}
      />
      <View style={styles.switchContainer}>
        <TouchableOpacity style={styles.switchItem} onPress={() => setMode('signup')}>
          <Text style={styles.switchText}>Do not have an account? Sign Up</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.switchItem} onPress={() => setMode('forgetpassword')}>
          <Text style={styles.switchText}>Forgot password?</Text>
        </TouchableOpacity>
      </View>
    </>
  );

  const signUpScreen = () => (
    <>
      <Input
        label="Email"
        style={styles.input}
        value={email}
        onChangeText={(text) => setEmail(text)}
        autoFocus
        autoCapitalize="none"
        autoCorrect={false}
        textContentType="emailAddress"
      />
      <Input
        label="Password"
        style={styles.input}
        value={password}
        onChangeText={(text) => setPassword(text)}
        autoCapitalize="none"
        autoCorrect={false}
        secureTextEntry
        textContentType="newPassword"
      />
      <Input
        label="Confirm Password"
        style={styles.input}
        value={confirmPassword}
        onChangeText={(text) => setConfirmPassword(text)}
        autoCapitalize="none"
        autoCorrect={false}
        secureTextEntry
      />
      <View style={styles.agreeContainer}>
        <Text style={styles.agreeText}>
          By singing up, you agree to Topicfeed
          {'\''}
          s
          {' '}
          <Text
            style={styles.agreeLink}
            onPress={() => openURL(URL_TOS)}
          >
            Terms of Service

          </Text>
          {' '}
          and
          {' '}
          <Text
            style={styles.agreeLink}
            onPress={() => Linking.openURL(URL_PRIVACY)}
          >
            Privacy Policy

          </Text>
          .
        </Text>
      </View>
      <Button
        containerStyle={styles.button}
        title="Sign Up"
        onPress={onSignUp}
      />
      <View style={styles.switchContainer}>
        <TouchableOpacity style={styles.switchItem} onPress={() => setMode('signin')}>
          <Text style={styles.switchText}>Already have an account? Sign In</Text>
        </TouchableOpacity>
      </View>
    </>
  );

  const forgetPasswordScreen = () => (
    <>
      <Input
        label="Email"
        style={styles.input}
        value={email}
        onChangeText={(text) => setEmail(text)}
        autoFocus
        autoCapitalize="none"
        autoCorrect={false}
        textContentType="emailAddress"
      />
      <Button
        containerStyle={styles.button}
        title="Reset Password"
        onPress={onSendPasswordResetEmail}
      />
      <View style={styles.switchContainer}>
        <TouchableOpacity style={styles.switchItem} onPress={() => setMode('signin')}>
          <Text style={styles.switchText}>Go back</Text>
        </TouchableOpacity>
      </View>
    </>
  );

  const changePasswordScreen = () => (
    <>
      <Input
        label="Current Password"
        style={styles.input}
        value={oldPassword}
        onChangeText={(text) => setOldPassword(text)}
        autoCapitalize="none"
        autoCorrect={false}
        secureTextEntry
        textContentType="password"
        autoCompleteType="password"
      />
      <Input
        label="New Password"
        style={styles.input}
        value={password}
        onChangeText={(text) => setPassword(text)}
        autoCapitalize="none"
        autoCorrect={false}
        secureTextEntry
        textContentType="newPassword"
      />
      <Input
        label="Confirm New Password"
        style={styles.input}
        value={confirmPassword}
        onChangeText={(text) => setConfirmPassword(text)}
        autoCapitalize="none"
        autoCorrect={false}
        secureTextEntry
      />
      <Button
        containerStyle={styles.button}
        title="Change Password"
        onPress={onChangePassword}
      />
    </>
  );

  const screen = () => {
    if (mode === 'signin') {
      return signInScreen();
    } if (mode === 'signup') {
      return signUpScreen();
    } if (mode === 'forgetpassword') {
      return forgetPasswordScreen();
    }
    return changePasswordScreen();
  };

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scroll}>
        {screen()}
      </ScrollView>
      <LoadingOverlay visible={isLoading} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    paddingTop: 30,
  },
  scroll: {
    flex: 1,
    width: '100%',
  },
  input: {
    width: '100%',
  },
  button: {
    marginHorizontal: 10,
    marginTop: 20,
  },
  switchContainer: {
    paddingTop: 30,
    paddingHorizontal: 10,
  },
  switchItem: {
    paddingBottom: 20,
  },
  switchText: {
    color: colors.primary,
    textDecorationLine: 'underline',
  },
  agreeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  agreeText: {
    color: colors.grey3,
  },
  agreeLink: {
    color: colors.primary,
    textDecorationLine: 'underline',
  },
});
