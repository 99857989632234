/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import {
  StyleSheet, KeyboardAvoidingView, Platform,
} from 'react-native';
import {
  Input, Text, Overlay, Button,
} from 'react-native-elements';
import { useDimension } from '../context';

import { View } from './Themed';

type Props = {
  onSubmit: (value: string) => void;
  onValidate: (value: string) => string;
  submitButtonTitle?: string;
  inputPlaceHolder?: string;
  title: string;
};

export type DialogInputRef = {
  show: () => void;
  hide: () => void;
};

const DialogInput = React.forwardRef((props: Props, ref) => {
  const {
    title, submitButtonTitle, inputPlaceHolder, onSubmit, onValidate,
  } = props;
  const [isVisible, setIsVisible] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState('');
  const { screenWidth: width } = useDimension();

  React.useImperativeHandle(ref, () => ({ show, hide }));

  function show() {
    setIsVisible(true);
  }

  function hide() {
    setIsVisible(false);
  }

  const onSubmitInput = () => {
    const msg = onValidate(value);
    setError(msg);
    if (msg) {
      return;
    }
    setIsVisible(false);
    onSubmit(value);
    setValue('');
  };

  return (
    <Overlay
      isVisible={isVisible}
      onBackdropPress={() => setIsVisible(false)}
      overlayStyle={[styles.overlay, { width }]}
      fullScreen
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <View style={styles.container}>
          <Text style={styles.title} h4>{title}</Text>
          <View style={styles.input}>
            <Text style={styles.error}>{error}</Text>
            <Input
              value={value}
              placeholder={inputPlaceHolder}
              autoCapitalize="none"
              autoCorrect={false}
              onChangeText={(text) => setValue(text)}
              onSubmitEditing={() => onSubmitInput()}
              autoCompleteType
            />
          </View>
          <Button
            containerStyle={styles.button}
            title={submitButtonTitle}
            onPress={onSubmitInput}
          />
          <Button
            containerStyle={styles.button}
            buttonStyle={styles.buttonOutline}
            type="outline"
            title="Cancel"
            onPress={() => {
              setValue('');
              setError('');
              setIsVisible(false);
            }}
          />
        </View>
      </KeyboardAvoidingView>
    </Overlay>
  );
});

DialogInput.defaultProps = {
  submitButtonTitle: 'Submit',
  inputPlaceHolder: '',
};

const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
  },
  container: {
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    paddingBottom: 10,
  },
  input: {
    width: '100%',
  },
  error: {
    color: 'red',
    paddingHorizontal: 10,
  },
  button: {
    width: '100%',
    margin: 5,
  },
  buttonOutline: {
    borderWidth: 2,
  },
});

export { DialogInput };
