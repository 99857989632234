import * as React from 'react';
import {
  StyleSheet, TouchableOpacity,
} from 'react-native';
import {
  Text, Button, Input, colors, Icon,
} from 'react-native-elements';
import { showMessage } from 'react-native-flash-message';

import { View, SafeAreaView } from '@topicfeed/appcommon/components/Themed';
import { LoadingOverlay } from '@topicfeed/appcommon/components/LoadingOverlay';
import { verifyPasswordInput } from '@topicfeed/appcommon/utils/parser';
import * as auth from '@topicfeed/appcommon/utils/auth';

import { RootStackScreenProps } from '../types';

export default function AuthActionScreen({ navigation, route }: RootStackScreenProps<'AuthAction'>) {
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [actionState, setActionState] = React.useState<'pending' | 'fail' | 'success'>('pending');
  const [message, setMessage] = React.useState('');
  const code = route.params.oobCode || '';
  const { mode } = route.params;
  console.log('Auth action params: ', route.params);

  React.useEffect(() => {
    if (mode === 'verifyEmail') {
      verifyEmail();
    } else if (mode === 'resetPassword') {
      verifyResetPasswordCode();
    } else if (mode === 'sentEmail') {
      setIsLoading(false);
    } else {
      navigation.replace('NotFound');
    }
  }, [code, mode]);

  const verifyEmail = () => {
    setMessage('Verifying email ...');
    auth.applyActionCode(code)
      .then(() => {
        setActionState('success');
        setMessage('Your email is verified.');
        setIsLoading(false);
      })
      .catch(() => {
        setActionState('fail');
        setMessage('Link has expired or already been used.');
      })
      .finally(() => setIsLoading(false));
  };

  const verifyResetPasswordCode = () => {
    auth.verifyPasswordResetCode(code)
      .catch(() => {
        setMessage('Link has expired or already been used.');
        setActionState('fail');
      })
      .finally(() => setIsLoading(false));
  };

  const onResetPassword = () => {
    const msg = verifyPasswordInput(password, confirmPassword, true);
    if (msg) {
      showMessage({
        message: msg,
        type: 'danger',
      });
      return;
    }
    setIsLoading(true);
    auth.confirmPasswordReset(code, password)
      .then(() => {
        setActionState('success');
        setMessage('Your password is reset.');
      })
      .catch(() => {
        setActionState('fail');
        setMessage('Link has expired or already been used.');
      })
      .finally(() => setIsLoading(false));
  };

  const resetPasswordScreen = () => {
    let name = 'lock';
    let color = colors.grey3;
    if (actionState === 'success') {
      name = 'lock-reset';
      color = colors.primary;
    } else if (actionState === 'fail') {
      name = 'close-circle-outline';
      color = 'orange';
    }
    if (actionState === 'pending') {
      return (
        <>
          <Input
            label="New Password"
            style={styles.input}
            value={password}
            onChangeText={(text) => setPassword(text)}
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry
            textContentType="newPassword"
          />
          <Input
            label="Confirm New Password"
            style={styles.input}
            value={confirmPassword}
            onChangeText={(text) => setConfirmPassword(text)}
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry
          />
          <Button
            containerStyle={styles.button}
            title="Reset Password"
            onPress={onResetPassword}
          />
        </>
      );
    }
    return (
      <>
        <Icon
          containerStyle={styles.icon}
          type="material-community"
          name={name}
          color={color}
          size={100}
          tvParallaxProperties
        />
        <Text style={styles.actionResultTitle}>{message}</Text>
        <View style={styles.switchContainer}>
          <TouchableOpacity style={styles.switchItem} onPress={() => navigation.pop()}>
            <Text style={styles.switchText}>Go to home screen</Text>
          </TouchableOpacity>
        </View>
      </>
    );
  };

  const verifyEmailScreen = () => {
    let name = 'email-sync-outline';
    let color = colors.grey3;
    if (actionState === 'success') {
      name = 'email-check-outline';
      color = colors.primary;
    } else if (actionState === 'fail') {
      name = 'email-alert-outline';
      color = 'orange';
    }
    return (
      <>
        <Icon
          containerStyle={styles.icon}
          type="material-community"
          name={name}
          color={color}
          size={100}
          tvParallaxProperties
        />
        <Text style={styles.actionResultTitle}>{message}</Text>
        <View style={styles.switchContainer}>
          <TouchableOpacity style={styles.switchItem} onPress={() => navigation.pop()}>
            <Text style={styles.switchText}>Go to home screen</Text>
          </TouchableOpacity>
        </View>
      </>
    );
  };

  const sentEmailScreen = () => (
    <>
      <Icon
        containerStyle={styles.icon}
        type="material-community"
        name="email-send-outline"
        color={colors.primary}
        size={100}
        tvParallaxProperties
      />
      <Text style={styles.actionResultTitle}>
        A verification email has been sent to
      </Text>
      <Text style={styles.actionResultSubTitle}>{code}</Text>
      <View style={styles.switchContainer}>
        <TouchableOpacity style={styles.switchItem} onPress={() => navigation.pop()}>
          <Text style={styles.switchText}>Go to home screen</Text>
        </TouchableOpacity>
      </View>
    </>
  );

  const screen = () => {
    if (mode === 'verifyEmail') {
      return verifyEmailScreen();
    } if (mode === 'resetPassword') {
      return resetPasswordScreen();
    } if (mode === 'sentEmail') {
      return sentEmailScreen();
    }
    return (<View />);
  };

  return (
    <SafeAreaView style={styles.container}>
      {screen()}
      <LoadingOverlay visible={isLoading} />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scroll: {
    flex: 1,
    width: '100%',
  },
  input: {
    width: '100%',
  },
  button: {
    width: '100%',
    paddingHorizontal: 10,
    paddingTop: 20,
  },
  switchContainer: {
    paddingTop: 30,
    paddingHorizontal: 10,
  },
  switchItem: {
    paddingBottom: 20,
  },
  switchText: {
    color: colors.primary,
    textDecorationLine: 'underline',
  },
  icon: {
    paddingBottom: 40,
  },
  actionResultTitle: {
    fontSize: 20,
    padding: 10,
  },
  actionResultSubTitle: {
    fontSize: 15,
    padding: 10,
  },
});
