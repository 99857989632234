import {
  Alert, AlertButton, AlertOptions, Platform, Linking,
} from 'react-native';

function alertPolyfill(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title: string, description: string, options?: AlertButton[], extra?: AlertOptions,
) {
  // eslint-disable-next-line no-alert
  const result = window.confirm([title, description].filter(Boolean).join('\n'));

  if (result) {
    const confirmOption = options?.find(({ style }) => style !== 'cancel');
    if (confirmOption && confirmOption.onPress) {
      confirmOption.onPress();
    }
  } else {
    const cancelOption = options?.find(({ style }) => style === 'cancel');
    if (cancelOption && cancelOption.onPress) {
      cancelOption.onPress();
    }
  }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

function openURL(url: string) {
  if (Platform.OS === 'web') {
    window.open(url, '_blank');
  } else {
    Linking.openURL(url);
  }
}

export { alert, openURL };
