import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';

function auth() {
  return firebase.auth();
}

export interface User {
  user: firebase.User | null;
}

export interface UserContextType {
  user: firebase.User | null;
  reloadUser: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextType>(
  { user: null, reloadUser: async () => {} },
);

export const UserProvider: React.FC = (props: any) => {
  const { children } = props;
  const [user, setUser] = React.useState<User>({ user: null });
  const value = {
    user: user.user,
    reloadUser: async () => {
      const { currentUser } = auth();
      console.log(`Reloading user, id: ${currentUser?.uid}, anonymous: ${currentUser?.isAnonymous}`);
      return currentUser?.reload()
        .then(() => {
          const user = auth().currentUser;
          console.log('Reloaded user: ', user);
          setUser({ user });
        });
    },
  };

  React.useEffect(() => auth().onAuthStateChanged((user) => {
    console.log(`User auth state chagned, id: ${user?.uid}, anonymous: ${user?.isAnonymous}`);
    setUser({ user });
  }), []);

  console.log('Render user provider.');
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => React.useContext(UserContext);
