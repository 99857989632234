import * as React from 'react';
import {
  StyleSheet, ActivityIndicator,
} from 'react-native';
import {
  Button, ListItem, colors, Text, Icon,
} from 'react-native-elements';
import { observer } from 'mobx-react-lite';
import { showMessage } from 'react-native-flash-message';
import { useQueryClient } from 'react-query';

import {
  View, SafeAreaView, Avatar, LoadingOverlay, ScrollView, SignIn,
} from '@topicfeed/appcommon/components';
import { useUser } from '@topicfeed/appcommon/context/UserContext';
import * as auth from '@topicfeed/appcommon/utils/auth';
import { alert } from '@topicfeed/appcommon/utils/polyfill';
import { useStores } from '@topicfeed/appcommon/models';

import { RootTabScreenProps } from '../types';

const ProfileScreen = observer(({ navigation }: RootTabScreenProps<'Profile'>) => {
  const [isRefreshingUser, setIsRefreshingUser] = React.useState(false);
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] = React.useState(false);
  const [isSigningOut, setIsSigningOut] = React.useState(false);
  const { user, reloadUser } = useUser();
  const queryClient = useQueryClient();
  const store = useStores();

  const onChangePassword = () => {
    navigation.navigate('Auth', { mode: 'changepassword' });
  };

  const onRefreshUser = () => {
    setIsRefreshingUser(true);
    reloadUser()
      .then(() => {
        setIsRefreshingUser(false);
        if (auth.isEmailVerified()) {
          showMessage({
            message: 'Your email is verified.',
            type: 'success',
          });
        } else {
          showMessage({
            message: 'Your email is not yet verified.',
            type: 'warning',
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setIsRefreshingUser(false);
        showMessage({
          message: 'Can not refresh email status. Please try again later.',
          type: 'danger',
        });
      });
  };

  const onSendVerificationEmail = () => {
    setIsSendingVerificationEmail(true);
    auth.sendEmailVerification()
      .then(() => {
        setIsSendingVerificationEmail(false);
        showMessage({
          message: `Verification email sent to ${user?.email}`,
          type: 'success',
        });
        console.log('Sent verification email.');
      })
      .catch((error) => {
        setIsSendingVerificationEmail(false);
        showMessage({
          message: error.message,
          type: 'danger',
        });
        console.error(error);
      });
  };

  const onSignUp = () => {
    navigation.navigate('Auth', { mode: 'signup' });
  };

  const onSignIn = () => {
    navigation.navigate('Auth', { mode: 'signin' });
  };

  const onSignOut = () => {
    alert(
      'Sign Out',
      'Do you want to sign out?',
      [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'SIGN OUT',
          onPress: signOut,
        },
      ],
      { cancelable: true },
    );
  };

  const signOut = () => {
    setIsSigningOut(true);
    auth.signOut()
      .then(() => {
        setIsSigningOut(false);
        showMessage({
          message: 'You have signed out.',
          type: 'success',
        });
        queryClient.invalidateQueries('topics');
        store.resetScreenStates();
        console.log('User signed out!');
      })
      .catch((error) => {
        setIsSigningOut(false);
        showMessage({
          message: error.message,
          type: 'danger',
        });
        console.error(error);
      });
  };

  const email = user?.email || '';
  const emailVerified = user?.emailVerified;
  const hasPassword = auth.hasPassword(user);

  const signedInScreen = () => (
    <>
      <ListItem key="1">
        <Avatar
          imageSrc={user?.photoURL || undefined}
          name={email}
          size={50}
          rounded
        />
        <ListItem.Content>
          <ListItem.Title style={styles.username} numberOfLines={1}>{`${user?.displayName || email.split('@')[0]}`}</ListItem.Title>
        </ListItem.Content>
      </ListItem>
      <ListItem key="2" onPress={onRefreshUser} disabled={emailVerified} bottomDivider>
        <Icon type="material-community" name="email" color={colors.grey3} tvParallaxProperties />
        <ListItem.Content>
          <ListItem.Title>
            <Text>
              Email
            </Text>
            <Text style={styles.dot}> • </Text>
            <Text style={emailVerified ? styles.verify : styles.unverify}>
              {emailVerified ? 'Verified' : 'Unverified'}
            </Text>
          </ListItem.Title>
          <ListItem.Subtitle numberOfLines={1}>{email}</ListItem.Subtitle>
        </ListItem.Content>
        {(!emailVerified && !isRefreshingUser)
        && <ListItem.Chevron size={25} type="material-community" name="refresh" tvParallaxProperties />}
        {(!emailVerified && isRefreshingUser)
        && <ActivityIndicator size="small" color={colors.grey3} />}
      </ListItem>
      {hasPassword && (
        <ListItem key="3" onPress={onChangePassword} bottomDivider>
          <Icon type="material-community" name="lock" color={colors.grey3} tvParallaxProperties />
          <ListItem.Content>
            <ListItem.Title>Change password</ListItem.Title>
          </ListItem.Content>
          <ListItem.Chevron tvParallaxProperties />
        </ListItem>
      )}
      { !emailVerified && (
        <ListItem key="4" onPress={onSendVerificationEmail} bottomDivider>
          <Icon type="material-community" name="email" color={colors.grey3} tvParallaxProperties />
          <ListItem.Content>
            <ListItem.Title>Resend verification email</ListItem.Title>
          </ListItem.Content>
          {isSendingVerificationEmail ? <ActivityIndicator size="small" color={colors.grey3} /> : <ListItem.Chevron tvParallaxProperties />}
        </ListItem>
      )}
      <ListItem key="6" onPress={() => navigation.navigate('SavedPosts')} bottomDivider>
        <Icon type="ionicon" name="bookmark" color={colors.grey3} tvParallaxProperties />
        <ListItem.Content>
          <ListItem.Title>Saved Posts</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron tvParallaxProperties />
      </ListItem>
      <ListItem key="7" onPress={() => navigation.navigate('ViewedPosts')} bottomDivider>
        <Icon type="ionicon" name="time" color={colors.grey3} tvParallaxProperties />
        <ListItem.Content>
          <ListItem.Title>View History</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron tvParallaxProperties />
      </ListItem>
      <ListItem key="8" onPress={() => navigation.navigate('BlockedUsers')} bottomDivider>
        <Icon type="ionicon" name="person-remove" color={colors.grey3} tvParallaxProperties />
        <ListItem.Content>
          <ListItem.Title>Blocked Users</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron tvParallaxProperties />
      </ListItem>
      <ListItem key="9" onPress={() => navigation.navigate('BlockedSources')} bottomDivider>
        <Icon type="ionicon" name="funnel" color={colors.grey3} tvParallaxProperties />
        <ListItem.Content>
          <ListItem.Title>Post Filters</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron tvParallaxProperties />
      </ListItem>
      <ListItem key="10" onPress={() => navigation.navigate('Settings')}>
        <Icon type="ionicon" name="settings" color={colors.grey3} tvParallaxProperties />
        <ListItem.Content>
          <ListItem.Title>Settings</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron tvParallaxProperties />
      </ListItem>
      <Button
        containerStyle={styles.button}
        buttonStyle={styles.buttonOutline}
        title="Sign Out"
        onPress={onSignOut}
        type="outline"
      />
    </>
  );

  const signedOutScreen = () => (
    <>
      <View style={styles.signedOutHeader}>
        <Icon
          type="ionicon"
          name="settings-outline"
          size={30}
          color={colors.grey3}
          onPress={() => navigation.navigate('Settings')}
          tvParallaxProperties
        />
      </View>
      <SignIn onSignIn={onSignIn} onSignUp={onSignUp} />
    </>
  );

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.innerContainer} bounces={false}>
        { auth.isSignedIn(user) ? signedInScreen() : signedOutScreen() }
      </ScrollView>
      <LoadingOverlay visible={isSigningOut} />
    </SafeAreaView>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 30,
  },
  button: {
    marginTop: 50,
    marginHorizontal: 25,
  },
  buttonOutline: {
    borderWidth: 2,
  },
  footer: {
    width: '100%',
    paddingBottom: 20,
  },
  username: {
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 24,
  },
  email: {
    alignItems: 'center',
  },
  emailIcon: {
    paddingRight: 5,
  },
  emailText: {
    flexDirection: 'row',
  },
  dot: {
    color: colors.grey3,
  },
  verify: {
    color: 'green',
    fontWeight: 'bold',
    fontSize: 12,
  },
  unverify: {
    color: 'orange',
    fontWeight: 'bold',
    fontSize: 12,
  },
  innerContainer: {
    height: '100%',
    width: '100%',
  },
  signedOutHeader: {
    alignItems: 'flex-end',
    padding: 30,
  },
});

export default ProfileScreen;
