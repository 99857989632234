import * as React from 'react';
import { StyleSheet } from 'react-native';

import { WebPageView } from '@topicfeed/appcommon/components';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RootStackScreenProps } from '../types';

export default function PostItemScreen({ route }: RootStackScreenProps<'PostItem'>) {
  const { url, js } = route.params;

  return (
    <SafeAreaView style={styles.container}>
      <WebPageView url={url} js={js}/>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
