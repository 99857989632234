import * as React from 'react';
import {
  StyleSheet, FlatList, Platform,
} from 'react-native';
import {
  ListItem, Divider, Button,
} from 'react-native-elements';
import { observer } from 'mobx-react-lite';

import { TopicSource } from '@topicfeed/common/types';
import { View, SearchBar } from '@topicfeed/appcommon/components';
import { useBlockTopicSourceMutation } from '@topicfeed/appcommon/hooks/mutations';
import { useBlockedTopicSourcesQuery, useSourceQuery, useTopicSourceQuery } from '@topicfeed/appcommon/hooks/queries';
import { alert } from '@topicfeed/appcommon/utils/polyfill';

type ItemProps = {
  topicSourceData: TopicSource;
};

const Item = ({ topicSourceData }: ItemProps) => {
  const topicId = topicSourceData.topic?.id || '';
  const blockTopicSourceMutation = useBlockTopicSourceMutation();
  const topicSourceOrSource = topicId
    ? useTopicSourceQuery(
      topicId, topicSourceData.source.name, topicSourceData,
    ).data : useSourceQuery(
      topicSourceData.source.name, { name: topicSourceData.source.name, blocked: true },
    ).data;

  if (!topicSourceOrSource || !topicSourceOrSource.blocked) {
    return null;
  }
  return (
    <ListItem
      key={topicId + topicSourceData.source.name}
      bottomDivider
    >
      <ListItem.Content>
        <ListItem.Title style={styles.itemTitle} numberOfLines={1}>
          {topicSourceData.source.name}
        </ListItem.Title>
        <ListItem.Subtitle>{topicId ? `Posts on ${topicSourceData.topic?.name}` : 'All posts'}</ListItem.Subtitle>
      </ListItem.Content>
      <Button
        title="Unfilter"
        buttonStyle={styles.followButton}
        titleStyle={styles.followButtonText}
        onPress={() => {
          alert(
            `Do you want to unfilter ${topicId
              ? `posts on ${topicSourceData.topic?.name}` : 'all posts'} from ${topicSourceData.source.name}?`,
            '',
            [
              { text: 'Cancel', style: 'cancel' },
              {
                text: 'OK',
                onPress: () => {
                  blockTopicSourceMutation.mutate(
                    { topicId, sourceName: topicSourceData.source.name, block: false },
                  );
                },
              },
            ],
            { cancelable: true },
          );
        }}
        loading={blockTopicSourceMutation.isLoading}
      />
    </ListItem>
  );
};

const BlockedSourcesScreen = observer(() => {
  const [searchText, setSearchText] = React.useState('');
  const { data, isLoading, refetch } = useBlockedTopicSourcesQuery();

  const onClear = () => {
    setSearchText('');
  };

  const topicSources = data
    ? data.filter((t: TopicSource) => !searchText || t.source.name.includes(searchText))
    : [];

  return (
    <View style={styles.container}>
      <SearchBar
        containerStyle={styles.searchBar}
        platform={Platform.OS === 'android' ? 'android' : 'ios'}
        placeholder="Search"
        value={searchText}
        onChangeText={(text: string) => setSearchText(text)}
        onClear={onClear}
        returnKeyType="search"
      />
      <Divider style={styles.divider} />
      <FlatList<TopicSource>
        style={styles.list}
        data={topicSources}
        keyExtractor={(item) => (item.topic?.id || '') + item.source.name}
        renderItem={({ item }) => (
          <Item topicSourceData={item} />
        )}
        refreshing={isLoading}
        onRefresh={() => refetch()}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  searchBar: {
    width: '100%',
    paddingHorizontal: 5,
  },
  list: {
    flex: 1,
    width: '100%',
  },
  divider: {
    height: 2,
  },
  followButton: {
    paddingVertical: 0,
    height: 32,
    width: 100,
    borderWidth: 2,
    borderRadius: 15,
  },
  followButtonText: {
    fontSize: 14,
  },
  itemTitle: {
    fontSize: 18,
  },
});

export default BlockedSourcesScreen;
