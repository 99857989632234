import * as BackgroundFetch from 'expo-background-fetch';
import * as TaskManager from 'expo-task-manager';

import { setupRootStore } from '@topicfeed/appcommon/models';
import { getNotifications, getFeed, FeedType } from '@topicfeed/appcommon/utils/api';
import { currentUserAwait } from '@topicfeed/appcommon/utils/auth';

export async function fetchNotification() {
  console.log('Trigger fetch notification task.');
  try {
    const user = await currentUserAwait();
    if (!user || user.isAnonymous) {
      console.log('User is not signed in, skip fetching notifications.');
      return BackgroundFetch.BackgroundFetchResult.NoData;
    }
    const response = await getNotifications(undefined, 0);
    const store = await setupRootStore();
    if (response.items.length > 0) {
      const timeLastNotification = response.items[0].timeCreated;
      if (!store.timeNotificationRead || timeLastNotification > store.timeNotificationRead) {
        console.log(`Found new notification at ${timeLastNotification}, last read at ${store.timeNotificationRead}.`);
        store.setHasNewNotification(true);
        return BackgroundFetch.BackgroundFetchResult.NewData;
      }
      console.log(`No new notification latest at ${timeLastNotification}, last read at ${store.timeNotificationRead}.`);
      return BackgroundFetch.BackgroundFetchResult.NoData;
    }
    console.log('No notification found.');
    return BackgroundFetch.BackgroundFetchResult.NoData;
  } catch (error: any) {
    console.log('Failed to fetch notification: ', error.message);
    return BackgroundFetch.BackgroundFetchResult.Failed;
  }
}

export async function fetchHomeFeed() {
  console.log('Trigger fetch feed task.');
  try {
    let newDataFound = false;
    const store = await setupRootStore();
    const responsePersonal = await getFeed(FeedType.Personal, undefined, 0);
    if (responsePersonal.items.length > 0) {
      const timeLastPersonalFeed = responsePersonal.items[0].timeCreated;
      if (!store.timePersonalFeedRead || timeLastPersonalFeed > store.timePersonalFeedRead) {
        console.log(`Found new personal feed at ${timeLastPersonalFeed}, last read at ${store.timePersonalFeedRead}.`);
        store.setHasNewPersonalFeed(true);
        newDataFound = true;
      }
      console.log(`No new home feed latest at ${timeLastPersonalFeed}, last read at ${store.timePersonalFeedRead}.`);
    }
    const responseHot = await getFeed(FeedType.Hot, undefined, 0);
    if (responseHot.items.length > 0) {
      const timeLastHotFeed = responseHot.items[0].timeCreated;
      if (!store.timeHotFeedRead || timeLastHotFeed > store.timeHotFeedRead) {
        console.log(`Found new hot feed at ${timeLastHotFeed}, last read at ${store.timeHotFeedRead}.`);
        store.setHasNewHotFeed(true);
        newDataFound = true;
      }
      console.log(`No new hot feed latest at ${timeLastHotFeed}, last read at ${store.timeHotFeedRead}.`);
    }
    if (newDataFound) {
      return BackgroundFetch.BackgroundFetchResult.NewData;
    }
    console.log('No home feed found.');
    return BackgroundFetch.BackgroundFetchResult.NoData;
  } catch (error: any) {
    console.log('Failed to fetch home feed: ', error.message);
    return BackgroundFetch.BackgroundFetchResult.Failed;
  }
}

const TASK_NAME_FETCH_NOTIFICATION = 'fetchNotification';
const TASK_NAME_FETCH_HOME_FEED = 'fetchHomeFeed';

export async function registerTasks() {
  TaskManager.defineTask(TASK_NAME_FETCH_NOTIFICATION, fetchNotification);
  TaskManager.defineTask(TASK_NAME_FETCH_HOME_FEED, fetchHomeFeed);
  try {
    await BackgroundFetch.registerTaskAsync(
      TASK_NAME_FETCH_NOTIFICATION, { minimumInterval: 60 },
    );
    console.log(`Task ${TASK_NAME_FETCH_NOTIFICATION} registered.`);
  } catch (err) {
    console.log(`Task ${TASK_NAME_FETCH_NOTIFICATION} register failed:`, err);
  }
  try {
    await BackgroundFetch.registerTaskAsync(
      TASK_NAME_FETCH_HOME_FEED, { minimumInterval: 60 },
    );
    console.log(`Task ${TASK_NAME_FETCH_HOME_FEED} registered.`);
  } catch (err) {
    console.log(`Task ${TASK_NAME_FETCH_HOME_FEED} register failed:`, err);
  }
}
