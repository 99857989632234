const psl = require('psl');

export function getMainDomainFromUrl(url: string): string {
  const parsed = psl.parse(getDomainFromUrl(url));
  return parsed.domain;
}

export function getDomainFromUrl(url: string): string {
  try {
    return new URL(url).hostname;
  } catch (error: any) {
    return '';
  }
}

export function getBaseUrlFromUrl(url: string): string {
  try {
    const { host, protocol } = new URL(url);
    return `${protocol}//${host}`;
  } catch (error: any) {
    return '';
  }
}

export function getProtocolFromUrl(url: string): string {
  try {
    const { protocol } = new URL(url);
    return protocol;
  } catch (error: any) {
    return 'http';
  }
}

export function injectJS(): string {
  // const domain = parseDomain(url);
  return `
    var oldXHROpen = window.XMLHttpRequest.prototype.open;
    window.XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
      var hostname = '';
      try {
        hostname = new URL(url).hostname;
      } catch (error: any) {
        console.log(error);
      }
      if (
        hostname.endsWith('doubleclick.net') ||
        hostname.endsWith('outbrainimg.com') ||
        hostname.endsWith('google-analytics.com') ||
        hostname.endsWith('googlesyndication.com') ||
        hostname.endsWith('amazon-adsystem.com') ||
        hostname.endsWith('openx.net') ||
        hostname.endsWith('criteo.com') ||
        hostname.endsWith('casalemedia.com') ||
        hostname.endsWith('adnxs.com') ||
        hostname.endsWith('fundingchoicesmessages') ||
        hostname.endsWith('pubmatic.com') ||
        hostname.endsWith('media.net') ||
        hostname.endsWith('getblueshift.com') ||
        hostname.endsWith('sharethrough.com')
      ) {
        // ReactNativeWebView.postMessage('Block request: ' + hostname);
        return null;
      }
      // ReactNativeWebView.postMessage('Send request: ' + url);
      return oldXHROpen.apply(this, arguments);
    }
    true;
  `;
}

export function verifyEmailInput(email: string): string {
  if (email.trim().length === 0) {
    return 'Email address can not be empty.';
  }
  if (email.split('@').length !== 2) {
    return 'Email address is invalid.';
  }
  return '';
}

export function verifyPasswordInput(
  password: string, confirmPassword?: string, ruleCheck?: boolean,
): string {
  if (password.length === 0) {
    return 'Password can not be empty.';
  }
  if (confirmPassword !== undefined && password.length === 0) {
    return 'Confirm Password can not be empty.';
  }
  if (confirmPassword !== undefined && password !== confirmPassword) {
    return 'Password and confirm password do not match.';
  }
  if (ruleCheck) {
    if (password.length < 8) {
      return 'New Password must be at least 8 characters long.';
    }
  }
  return '';
}
