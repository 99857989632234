/* eslint-disable no-param-reassign */
import 'react-native-url-polyfill/auto';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import * as auth from '../utils/auth';
import * as firestore from '../utils/firestore';
import { getSiteIcon, verifyUrlConnection } from '../utils/fetcher';

const SEARCH_HISTORY_ITEM_LIMIT = 100;

export enum SearchEngine {
  Google = 'Google',
  Bing = 'Bing',
  DuckDuckGo = 'DuckDuckGo',
}

export enum BackgroundColor {
  Light = 'Light',
  Dark = 'Dark',
  System = 'Use system setting',
}

export enum DataSyncStatus {
  Success = 'Success',
  Pending = 'Pending',
  Fail = 'Fail',
}

export enum HomeScreenLayout {
  List = 'List',
  Grid = 'Grid',
}

export enum PostPreviewStyle {
  Rich = 'Rich',
  Simple = 'Simple',
}

export const SiteLastViewUrl = types.model('SiteLastViewUrl')
  .props({
    id: types.identifier,
    url: types.string,
    timeUpdated: types.number,
  });

export const ProfileModel = types.model('Profile')
  .props({
    uid: types.string,
    email: '',
    emailVerified: false,
    creationTime: types.maybeNull(types.number),
    dataSyncStatus: types.optional(
      types.enumeration<DataSyncStatus>('DataSyncStatus', Object.values(DataSyncStatus)), DataSyncStatus.Pending,
    ),
    photoUrl: '',
    displayName: '',
    hasPassword: false,
  });

export const SettingsModel = types.model('Settings')
  .props({
    searchEngine: types.optional(
      types.enumeration<SearchEngine>('SearchEngine', Object.values(SearchEngine)), SearchEngine.Google,
    ),
    backgroundColor: types.optional(
      types.enumeration<BackgroundColor>('BackgroundColor', Object.values(BackgroundColor)), BackgroundColor.System,
    ),
    homeScreenLayout: types.optional(
      types.enumeration<HomeScreenLayout>('HomeScreenLayout', Object.values(HomeScreenLayout)), HomeScreenLayout.List,
    ),
    postPreviewStyle: types.optional(
      types.enumeration<PostPreviewStyle>('PostPreviewStyle', Object.values(PostPreviewStyle)), PostPreviewStyle.Rich,
    ),
    usePostReaderView: true,
    showHome: true,
  })
  .actions((self) => ({
    setSearchEngine(searchEngine: SearchEngine) {
      self.searchEngine = searchEngine;
    },
    setBackgroundColor(backgroundColor: BackgroundColor) {
      self.backgroundColor = backgroundColor;
    },
    setHomeScreenLayout(homeScreenLayout: HomeScreenLayout) {
      self.homeScreenLayout = homeScreenLayout;
    },
    setPostPreviewStyle(postPreviewStyle: PostPreviewStyle) {
      self.postPreviewStyle = postPreviewStyle;
    },
    setUsePostReaderView(usePostReaderView: boolean) {
      self.usePostReaderView = usePostReaderView;
    },
    setShowHome(showHome: boolean) {
      self.showHome = showHome;
    },
  }));

export const SearchItemModel = types.model('SearchItem')
  .props({
    text: types.string,
  });

export const PageModel = types.model('Page')
  .props({
    id: types.identifier,
    url: types.string,
    title: types.string,
    timeSaved: types.number,
    thumbnail: '',
    siteId: '',
    tags: types.array(types.string),
  })
  .actions((self) => ({
    removeTag(tag: string) {
      self.tags.remove(tag);
    },
    updateTags(tags: string[]) {
      self.tags.replace(tags);
      self.tags.sort();
    },
  }));

export const SiteModel = types.model('Site')
  .props({
    id: types.identifier,
    name: types.string,
    url: types.string,
    icon: '',
    thirdPartyCookiesBlocked: true,
    thirdPartyRequestsBlocked: false,
    incognitoMode: false,
    fullScreen: false,
    tags: types.array(types.string),
  })
  .actions((self) => ({
    removeTag(tag: string) {
      self.tags.remove(tag);
    },
    updateTags(tags: string[]) {
      self.tags.replace(tags);
      self.tags.sort();
    },
  }));

export const PostViewPosition = types.model('PostViewPosition')
  .props({
    id: types.identifier,
    url: types.optional(types.string, ''),
    mUrl: types.optional(types.string, ''),
  });

export const RootStoreModel = types.model('RootStore')
  .props({
    appName: 'topicfeed',
    profile: types.maybeNull(ProfileModel),
    sites: types.map(SiteModel),
    savedPages: types.map(PageModel),
    searchItems: types.array(SearchItemModel),
    settings: types.optional(SettingsModel, {}),
    siteLastViewUrls: types.map(SiteLastViewUrl),
    tags: types.array(types.string),
    refreshHome: false,
    refreshTopics: false,
    refreshNotifications: false,
    hasNewNotification: false,
    hasNewHomeFeed: false,
    hasNewPersonalFeed: false,
    hasNewHotFeed: false,
    timeHomeFeedRead: types.maybe(types.number),
    timePersonalFeedRead: types.maybe(types.number),
    timeHotFeedRead: types.maybe(types.number),
    timeNotificationRead: types.maybe(types.number),
    signInPrompted: false,
    postViewPositions: types.map(PostViewPosition),
    hiddenPosts: types.map(types.string),
    blockedUsers: types.map(types.string),
    reportedPosts: types.map(types.string),
    favoriteBoards: types.map(types.string),
    forums: types.map(types.string),
    isReleased: false,
    onboardVersion: 0,
    developerMode: false,
  })
  .actions((self) => ({
    setDeveloperMode(developerMode: boolean) {
      self.developerMode = developerMode;
    },
    setOnboardVersion(version: number) {
      self.onboardVersion = version;
    },
    setIsReleased(isReleased: boolean) {
      self.isReleased = isReleased;
    },
    addForum(name: string) {
      self.forums.set(name, name);
    },
    removeForum(name: string) {
      self.forums.delete(name);
    },
    getPostViewPosition(id: string) {
      return self.postViewPositions.get(id);
    },
    savePostViewPosition(id: string, url?: string, mUrl?: string) {
      self.postViewPositions.set(id, { id, url, mUrl });
    },
    hidePost(id: string, hide: boolean) {
      if (hide) {
        self.hiddenPosts.set(id, id);
      } else {
        self.hiddenPosts.delete(id);
      }
      return hide;
    },
    reportPost(id: string) {
      self.reportedPosts.set(id, id);
      return true;
    },
    blockUser(id: string, block: boolean) {
      if (block) {
        self.blockedUsers.set(id, id);
      } else {
        self.blockedUsers.delete(id);
      }
      return block;
    },
    favoriateBoard(id: string, name: string, favorite: boolean) {
      if (favorite) {
        self.favoriteBoards.set(id, name);
      } else {
        self.favoriteBoards.delete(id);
      }
      return favorite;
    },
  }))
  .actions((self) => ({
    setSignInPrompted(signInPrompted: boolean) {
      self.signInPrompted = signInPrompted;
    },
    setRefreshHome(refreshHome: boolean) {
      self.refreshHome = refreshHome;
    },
    setRefreshTopics(refreshTopics: boolean) {
      self.refreshTopics = refreshTopics;
    },
    setRefreshNotifications(refreshNotifications: boolean) {
      self.refreshNotifications = refreshNotifications;
    },
    setTimePersonalRead(timestamp: number) {
      self.timePersonalFeedRead = timestamp;
    },
    setTimeHotRead(timestamp: number) {
      self.timeHotFeedRead = timestamp;
    },
    setTimeNotificationRead(timestamp: number) {
      self.timeNotificationRead = timestamp;
    },
    setHasNewNotification(hasNewNotification: boolean) {
      self.hasNewNotification = hasNewNotification;
    },
    setHasNewPersonalFeed(hasNewPersonalFeed: boolean) {
      self.hasNewPersonalFeed = hasNewPersonalFeed;
    },
    setHasNewHotFeed(hasNewHotFeed: boolean) {
      self.hasNewHotFeed = hasNewHotFeed;
    },
    setSite(id: string, site: any) {
      console.log('Set site to local: ', site);
      self.sites.set(id, site);
    },
    setPage(id: string, page: any) {
      console.log('Set page to local: ', page);
      self.savedPages.set(id, page);
    },
    async addTag(tag: string) {
      if (tag.length === 0) {
        return Promise.resolve();
      }
      if (self.profile) {
        console.log('Add tag to firestore: ', tag);
        const userRef = firestore.db().collection('users').doc(self.profile.uid);
        const user = await userRef.get();
        if (user.exists) {
          const existingTags = new Set(user.data()?.tags || []);
          const numExistingTags = existingTags.size;
          existingTags.add(tag);
          if (existingTags.size === numExistingTags) {
            return Promise.resolve();
          }
          const tagsSorted = Array.from(existingTags);
          tagsSorted.sort();
          return userRef.set({ tags: tagsSorted }, { merge: true });
        }
        return Promise.reject(new Error('User does not exist.'));
      }
      console.log('Add tags to local: ', tag);
      self.tags.push(tag);
      self.tags.sort();
      return Promise.resolve();
    },
    async deleteTag(tag: string) {
      if (self.profile) {
        const { uid } = self.profile;
        console.log('Remove tag to firestore: ', tag);
        const userRef = firestore.db().collection('users').doc(uid);
        const user = await userRef.get();
        if (user.exists) {
          const existingTags = user.data()?.tags || [];
          // Delete tag from sites.
          const sitesQuery = firestore.db()
            .collection(`users/${uid}/sites`)
            .where('tags', 'array-contains', tag);
          const sites = await sitesQuery.get();
          // TODO: batch tag remove.
          const sitePromises: Promise<void>[] = [];
          sites.forEach((site) => {
            sitePromises.push(firestore.db()
              .collection(`users/${uid}/sites`).doc(site.id)
              .set({ tags: site.data().tags.filter((t: string) => t !== tag) }, { merge: true }));
          });
          await Promise.all(sitePromises);

          // Delete tag from pages.
          const pagesQuery = firestore.db()
            .collection(`users/${uid}/pages`)
            .where('tags', 'array-contains', tag);
          const pages = await pagesQuery.get();
          // TODO: batch tag remove.
          const pagePromises: Promise<void>[] = [];
          pages.forEach((page) => {
            pagePromises.push(firestore.db()
              .collection(`users/${uid}/pages`).doc(page.id)
              .set({ tags: page.data().tags.filter((t: string) => t !== tag) }, { merge: true }));
          });
          await Promise.all(pagePromises);

          // Remote tag itself.
          return userRef.set(
            { tags: existingTags.filter((t: string) => t !== tag) }, { merge: true },
          );
        }
        return Promise.reject(new Error('User does not exist.'));
      }
      console.log('Remove tag to local: ', tag);
      self.sites.forEach((site) => {
        if (site.tags.includes(tag)) {
          site.removeTag(tag);
        }
      });
      self.savedPages.forEach((page) => {
        if (page.tags.includes(tag)) {
          page.removeTag(tag);
        }
      });
      self.tags.remove(tag);
      return Promise.resolve();
    },
  }))
  .actions((self) => ({
    resetScreenStates() {
      self.setRefreshHome(true);
      self.setRefreshTopics(true);
      self.setRefreshNotifications(true);
      self.setHasNewPersonalFeed(false);
      self.setHasNewNotification(false);
    },
  }))
  .actions((self) => ({
    async updateSiteTags(siteId: string, tags: string[]) {
      if (self.profile) {
        tags.sort();
        console.log('Update site tags to firestore: ', siteId, tags);
        return firestore.db().collection(`users/${self.profile.uid}/sites`).doc(siteId)
          .set({ tags }, { merge: true });
      }
      console.log('Update site tags to local: ', siteId, tags);
      const site = self.sites.get(siteId);
      if (site) {
        site.updateTags(tags);
      }
      return Promise.resolve();
    },
    async updatePageTags(pageId: string, tags: string[]) {
      if (self.profile) {
        tags.sort();
        console.log('Update page tags to firestore: ', pageId, tags);
        return firestore.db().collection(`users/${self.profile.uid}/pages`).doc(pageId)
          .set({ tags }, { merge: true });
      }
      console.log('Update page tags to local: ', pageId, tags);
      const page = self.savedPages.get(pageId);
      if (page) {
        page.updateTags(tags);
      }
      return Promise.resolve();
    },
  }))
  .actions((self) => ({
    setDataSyncStatus(dataSyncStatus: DataSyncStatus) {
      if (self.profile) {
        self.profile.dataSyncStatus = dataSyncStatus;
      }
    },
    setProfile(user: auth.User | null) {
      console.log('Set profile to: ', user);
      if (user) {
        const prevStatus = self.profile?.dataSyncStatus || DataSyncStatus.Pending;
        const prevUid = self.profile?.uid;
        const hasPassword = user.providerData.findIndex((d) => d?.providerId === 'password') !== -1;
        self.profile = {
          uid: user.uid,
          email: user.email || '',
          emailVerified: user.emailVerified,
          creationTime: Date.parse(user.metadata.creationTime || '') || null,
          dataSyncStatus: prevUid === user.uid ? prevStatus : DataSyncStatus.Pending,
          photoUrl: user.photoURL || '',
          displayName: user.displayName || '',
          hasPassword,
        };
      } else {
        self.profile = null;
      }
    },
    updateSiteLastViewUrl(siteId: string, url: string) {
      self.siteLastViewUrls.set(siteId, { id: siteId, url, timeUpdated: new Date().getTime() });
    },
    async updateSite(
      id: string,
      url: string, name?: string, icon = '',
      thirdPartyCookiesBlocked = true,
      thirdPartyRequestsBlocked = false,
      incognitoMode = false,
      fullScreen = false,
      tags = [],
    ) {
      let fullUrl = url;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        const secureUrl = `https://${url}`;
        const isSecure = await verifyUrlConnection(secureUrl);
        if (isSecure) {
          fullUrl = secureUrl;
        } else {
          fullUrl = `http://${url}`;
        }
      }
      const iconResolved = icon || await getSiteIcon(fullUrl);
      const { hostname } = new URL(fullUrl);
      const site = {
        id,
        url: fullUrl,
        name: name || hostname,
        icon: iconResolved,
        thirdPartyCookiesBlocked,
        thirdPartyRequestsBlocked,
        incognitoMode,
        fullScreen,
      };
      if (self.profile) {
        const siteWithTag = { ...site, tags };
        console.log('Set site to firestore: ', siteWithTag);
        return firestore.db().collection(`users/${self.profile.uid}/sites`).doc(id).set(siteWithTag);
      }
      self.setSite(id, site);
      self.updateSiteTags(id, tags);
      return Promise.resolve();
    },
  }))
  .actions((self) => ({
    addSite(
      url: string, name?: string, icon = '',
      thirdPartyCookiesBlocked = true,
      thirdPartyRequestsBlocked = false,
      incognitoMode = false,
      fullScreen = false,
      tags = [],
    ) {
      const id = (new Date().getTime()).toString();
      return self.updateSite(
        id, url, name, icon,
        thirdPartyCookiesBlocked, thirdPartyRequestsBlocked, incognitoMode, fullScreen, tags,
      );
    },
    deleteSite(id: string) {
      if (self.profile) {
        console.log('Delete site from firestore: ', id);
        return firestore.db().collection(`users/${self.profile.uid}/sites`).doc(id).delete();
      }
      console.log('Delete site locally: ', id);
      self.sites.delete(id);
      return Promise.resolve();
    },
    addPage(url: string, title: string, thumbnail = '', siteId?: string, tags?: string[]) {
      const timeSaved = new Date().getTime();
      const id = timeSaved.toString();
      const page = {
        id, url, title: title || url, timeSaved, thumbnail, siteId: siteId || '',
      };
      if (self.profile) {
        const pageWithTag = { ...page, tags: tags || [] };
        console.log('Add page to firestore: ', pageWithTag);
        return firestore.db().collection(`users/${self.profile.uid}/pages`).doc(id).set(pageWithTag);
      }
      self.setPage(id, page);
      self.updatePageTags(id, tags || []);
      return Promise.resolve();
    },
    deletePage(id: string) {
      if (self.profile) {
        console.log('Delete page from firestore: ', id);
        return firestore.db().collection(`users/${self.profile.uid}/pages`).doc(id).delete();
      }
      console.log('Delete page locally: ', id);
      self.savedPages.delete(id);
      return Promise.resolve();
    },
    addSearchItem(text: string) {
      self.searchItems.replace(self.searchItems.filter((item) => item.text !== text));
      self.searchItems.unshift({ text });
      if (self.searchItems.length > SEARCH_HISTORY_ITEM_LIMIT) {
        self.searchItems.pop();
      }
    },
    deleteSearchItem(text: string) {
      self.searchItems.replace(self.searchItems.filter((item) => item.text !== text));
    },
    clearSearchItem() {
      self.searchItems.replace([]);
    },
  }))
  .views((self) => ({
    getSites() {
      return [...Array.from(self.sites.values())].sort((a, b) => a.name.localeCompare(b.name));
    },
    getSavedPages() {
      return [...Array.from(self.savedPages.values())].sort((a, b) => b.timeSaved - a.timeSaved);
    },
    getSiteLastViewUrl(siteId: string) {
      return self.siteLastViewUrls.get(siteId)?.url;
    },
  }))
  .actions((self) => ({
    async syncData() {
      if (!self.profile || !self.profile.emailVerified) {
        return false;
      }
      const userId = self.profile.uid;
      const userRef = firestore.db().collection('users').doc(userId);
      const userDoc = await userRef.get();
      if (!userDoc.exists) {
        try {
          console.log(`User ${userId} does not exist, start uploading.`);
          await firestore.batchUpload(userId, 'sites', self.getSites());
          await firestore.batchUpload(userId, 'pages', self.getSavedPages());
          console.log(`Set profile for user ${userId}.`);
          await userRef.set({
            tags: self.tags,
          });
          console.log('Finished uploading.');
        } catch (error: any) {
          if (self.profile.dataSyncStatus !== DataSyncStatus.Fail) {
            self.setDataSyncStatus(DataSyncStatus.Fail);
          }
          throw error;
        }
      }
      console.log(`Sync user profile for ${userId}, existing profile: `, userDoc.data());
      await userRef.set({
        ...self.profile,
        dataSyncStatus: DataSyncStatus.Success,
      }, { merge: true });
      if (self.profile.dataSyncStatus !== DataSyncStatus.Success) {
        self.setDataSyncStatus(DataSyncStatus.Success);
      }
      return false;
    },
  }));

export type Profile = Instance<typeof ProfileModel>;

export type Settings = Instance<typeof SettingsModel>;

export type SearchItem = Instance<typeof SearchItemModel>;

export type Site = Instance<typeof SiteModel>;

export type Page = Instance<typeof PageModel>;

export type RootStore = Instance<typeof RootStoreModel>;

export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;

export function subscibeToRemotePages(
  userId: string | undefined, onSetData: (pages: Page[]) => void, onDone: () => void,
) {
  if (userId) {
    return firestore.db()
      .collection(`users/${userId}/pages`)
      .orderBy('timeSaved', 'desc')
      .onSnapshot((querySnapshot) => {
        const pages: Page[] = [];
        querySnapshot.forEach((documentSnapshot) => {
          pages.push(documentSnapshot.data() as Page);
        });
        onSetData(pages);
        onDone();
      });
  }
  onDone();
  return () => { };
}

export function subscibeToRemoteSites(
  userId: string | undefined, onSetData: (sites: Site[]) => void, onDone: () => void,
) {
  if (userId) {
    return firestore.db()
      .collection(`users/${userId}/sites`)
      .orderBy('name', 'asc')
      .onSnapshot((querySnapshot) => {
        const sites: Site[] = [];
        querySnapshot.forEach((documentSnapshot) => {
          sites.push(documentSnapshot.data() as Site);
        });
        onSetData(sites);
        onDone();
      });
  }
  onDone();
  return () => { };
}

export function subscibeToRemoteTags(
  userId: string | undefined, onSetData: (tags: string[]) => void, onDone: () => void,
) {
  if (userId) {
    return firestore.db()
      .collection('users')
      .doc(userId)
      .onSnapshot((user) => {
        onSetData(user.data()?.tags || []);
        onDone();
      });
  }
  onDone();
  return () => { };
}

export async function getRemoteSite(userId: string, siteId: string): Promise<Site | null> {
  const doc = await firestore.db().collection(`users/${userId}/sites`).doc(siteId).get();
  if (doc.exists) {
    return doc.data() as Site;
  }
  return null;
}
