import * as React from 'react';
import {
  StyleSheet, FlatList, Platform, ActivityIndicator, RefreshControl, Modal,
} from 'react-native';
import { Text, colors } from 'react-native-elements';
import { useTheme } from '@react-navigation/native';
import ImageViewer from 'react-native-image-zoom-viewer';
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';

import { Post, PostItem, Image } from '@topicfeed/common/types';
import { openURL } from 'expo-linking';
import { View } from './Themed';
import { Icon } from './Icon';
import { PostItemView } from './PostItemView';
import { useDimension } from '../context';
import { alert } from '../utils/polyfill';

export type PostViewRef = {
  scrollTo: (i: number) => void;
};

type Props = {
  post: Post,
  postItems: PostItem[],
  width: number,
  errorMessage: string,
  onEndReach: () => void;
  onRefresh: () => void;
  isLoadingPrev: boolean;
  isLoadingNext: boolean;
  isLoadingItems: boolean;
  hasPrevious: boolean;
  hasNext: boolean;
  onCommentPost: (postId: string, postItemId?: string, page?: number) => void;
  onUpVotePost: (postId: string, postItemId?: string, page?: number) => void;
  showSourceTitle?: boolean;
  showTopic?: boolean;
  newPostItemId?: string;
  shortUrl?: string;
};

const IMAGE_VIEW_ICON_SIZE = 25;
const IMAGE_VIEW_ICON_COLOR = 'white';

const PostViewImpl = React.forwardRef((props: Props, ref) => {
  const {
    post, postItems, width, errorMessage, onEndReach, onRefresh, hasPrevious, hasNext,
    isLoadingPrev, isLoadingNext, isLoadingItems, onCommentPost, onUpVotePost, showSourceTitle,
    showTopic, newPostItemId, shortUrl,
  } = props;
  const { colors: { border } } = useTheme();
  const { height } = useDimension();
  const listRef = React.useRef<FlatList>(null);
  React.useImperativeHandle(ref, () => ({ scrollTo }));

  const [imageIndex, setimageIndex] = React.useState<number>(0);
  const [images, setImages] = React.useState<any[]>([]);

  function scrollTo(i: number, animated = false) {
    listRef.current?.scrollToIndex({ animated, index: i });
  }

  const Separator = () => <View style={[styles.separator, { backgroundColor: border }]} />;

  const EmptyComponent = () => {
    if (errorMessage) {
      return (
        <View style={[styles.empty, { paddingTop: height / 3 }]}>
          <Text style={styles.emptyTitle}>{errorMessage}</Text>
        </View>
      );
    }
    return null;
  };

  const Header = () => {
    if (hasPrevious && !isLoadingPrev) {
      return (
        <>
          <Icon type="ionicon" name="arrow-down-circle-outline" color={colors.grey3} />
          <Text style={styles.headerText}>Pull to load previous page</Text>
        </>
      );
    }
    return null;
  };

  const Footer = () => {
    if (isLoadingNext) {
      return (
        <ActivityIndicator color={colors.grey3} size="large" />
      );
    }
    if (postItems.length > 0 && !hasNext) {
      return (
        <Icon type="material-community" name="sunglasses" size={30} color={colors.grey3} />
      );
    }
    return (
      <View />
    );
  };

  const shareImage = async (currentIndex: number) => {
    const { url } = images[currentIndex || 0];
    console.log(`Share image ${url}.`);
    try {
      const fileLocation = `${FileSystem.documentDirectory}tf_${new Date().getTime()}.png`;
      await FileSystem.downloadAsync(url, fileLocation, { headers: { Referer: post.mUrl } });
      await Sharing.shareAsync(fileLocation);
    } catch (error: any) {
      console.error(`Fail to share image (${url})`, error);
      alert(
        '',
        'Fail to share image.',
        [{ text: 'OK', style: 'cancel', onPress: () => { } }],
      );
    }
  };

  const saveImage = async (currentIndex: number) => {
    const { url } = images[currentIndex || 0];
    console.log(`Save image ${url} to media library.`);
    try {
      if (Platform.OS === 'android') {
        let permissions = await MediaLibrary.getPermissionsAsync();
        if (permissions.status !== 'granted') {
          permissions = await MediaLibrary.requestPermissionsAsync();
          if (permissions.status !== 'granted') {
            alert(
              '',
              'Please grant permssion save image to the library.',
              [{ text: 'OK', style: 'cancel', onPress: () => { } }],
            );
            return;
          }
        }
      }
      const fileLocation = `${FileSystem.documentDirectory}tf_${new Date().getTime()}.png`;
      await FileSystem.downloadAsync(url, fileLocation, { headers: { Referer: post.mUrl } });
      await MediaLibrary.saveToLibraryAsync(fileLocation);
      alert(
        '',
        'Saved image to the library.',
        [{ text: 'OK', style: 'cancel', onPress: () => { } }],
      );
    } catch (error: any) {
      console.error(`Fail to save image (${url}) to library`, error);
      alert(
        '',
        'Fail to save image to the library.',
        [{ text: 'OK', style: 'cancel', onPress: () => { } }],
      );
    }
  };

  const setImageAndIndex = React.useCallback(
    (images: Image[], index: number) => {
      setimageIndex(index);
      setImages(
        images.map(
          (img) => ({
            url: img.url,
            props: { headers: { Referer: post.mUrl } },
          }),
        ),
      );
    },
    [setimageIndex, setImages],
  );

  const subTitle = `${showTopic ? post.topic?.name : post.board?.name} • ${showSourceTitle ? post.source0.title : post.source0.name}`;
  return (
    <View style={styles.empty}>{ isLoadingItems
      ? (
          <ActivityIndicator size="large" color={colors.grey3} />
      ) : (
        <>
          <View style={[
            styles.titleContainer,
            {
              borderColor: border,
              borderBottomWidth: 1,
            }]}
          >
            <TouchableWithoutFeedback onPress={() => scrollTo(0, true) }>
              <Text style={styles.title}>{post.title}</Text>
            </TouchableWithoutFeedback>
            { shortUrl
              ? <Text style={styles.subTitle} onPress={() => openURL(shortUrl)}>
                  {`🔗 ${shortUrl.replace('https://', '')}`}
                </Text>
              : <Text style={styles.subTitle}>
                  {subTitle}
                </Text> }
          </View>
          <FlatList<PostItem>
            ref={listRef}
            style={styles.list}
            data={postItems}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <PostItemView
                postItem={item}
                width={width}
                isPostAuthor={item.user.id === post.user?.id && !!item.user.id.split('-')[1]}
                postId={post.id}
                postUrl={post.mUrl}
                setShowImageAndIndex={setImageAndIndex}
                onCommentPost={onCommentPost}
                onUpVotePost={onUpVotePost}
                isNewItem={newPostItemId === item.id}
              />
            )}
            ListEmptyComponent={EmptyComponent}
            ItemSeparatorComponent={Separator}
            ListHeaderComponent={Header}
            ListHeaderComponentStyle={styles.header}
            ListFooterComponent={Footer}
            ListFooterComponentStyle={styles.footer}
            onEndReached={onEndReach}
            onRefresh={onRefresh}
            refreshing={isLoadingPrev}
            onEndReachedThreshold={0.95}
            refreshControl={
              <RefreshControl
                refreshing={isLoadingPrev}
                onRefresh={onRefresh}
                tintColor={colors.grey3}
              />
            }
          />
        </>)}
      <Modal
        animationType="slide"
        visible={images.length > 0}
        onRequestClose={() => setImages([])}
      >
        <ImageViewer
          imageUrls={images}
          index={imageIndex}
          enableSwipeDown={true}
          saveToLocalByLongPress={false}
          swipeDownThreshold={10}
          onCancel={() => {
            setImages([]);
          }}
          useNativeDriver={true}
          renderFooter={(currentIndex?: number) => (
            <>
              <Icon
                type="ionicons"
                name="ios-share"
                color={IMAGE_VIEW_ICON_COLOR}
                size={IMAGE_VIEW_ICON_SIZE}
                onPress={() => shareImage(currentIndex || 0)}
                containerStyle={styles.imageViewIconStyle}
              />
              <Icon
                type="ionicons"
                name="save"
                color={IMAGE_VIEW_ICON_COLOR}
                size={IMAGE_VIEW_ICON_SIZE}
                onPress={() => saveImage(currentIndex || 0)}
                containerStyle={styles.imageViewIconStyle}
              />
            </>
          )}
          footerContainerStyle={styles.imageViewFooter}
        />
      </Modal>
    </View>
  );
});

export const PostView = React.memo(PostViewImpl);

const styles = StyleSheet.create({
  container: {
    height: '100%',
    justifyContent: 'center',
    opacity: 0.6,
  },
  title: {
    fontSize: 18,
    lineHeight: 20,
    fontWeight: 'bold',
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 15,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingBottom: 10,
    paddingHorizontal: 15,
    color: 'grey',
  },
  titleContainer: {
    width: '100%',
    elevation: 2,
  },
  list: {
    height: '100%',
    width: '100%',
  },
  separator: {
    height: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  headerText: {
    color: colors.grey3,
    fontSize: 15,
    textAlign: 'center',
    paddingVertical: 15,
    paddingHorizontal: 5,
  },
  footer: {
    padding: 20,
    alignContent: 'center',
    justifyContent: 'center',
  },
  empty: {
    justifyContent: 'center',
    height: '100%',
  },
  emptyTitle: {
    fontSize: 18,
    color: colors.grey3,
    paddingVertical: 20,
    textAlign: 'center',
  },
  imageViewFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    paddingBottom: 50,
    paddingHorizontal: 30,
    paddingTop: 20,
    backgroundColor: 'transparent',
  },
  imageViewIconStyle: {
    marginHorizontal: 10,
    backgroundColor: 'black',
    padding: 7,
    borderRadius: 20,
  },
});
