import { useQuery } from 'react-query';
import { showMessage } from 'react-native-flash-message';

import {
  Post, User, Topic, Source, TopicSource, PostViewPosition,
} from '@topicfeed/common/types';
import {
  getTopics, getTopic, getUser, getPostReportReasons,
  getBlockedUsers, getSource, getTopicSource, getBlockedTopicSources,
  getPostShortUrl,
} from '../utils/api';
import { getBoards } from '../utils/api-source';
import * as apiSource from '../utils/api-source';
import * as apiServer from '../utils/api';

let USE_LOCAL = false;
export function setUseLocalQuery(useLocal: boolean) {
  USE_LOCAL = useLocal;
}
function getApi() {
  return USE_LOCAL ? apiSource : apiServer;
}
function getStaleTime() {
  return USE_LOCAL ? 1000 * 3600 * 24 * 365 : 1000 * 3600;
}

export function useUserQuery(id: string, enabled = true, initialData?: User) {
  return useQuery(
    ['user', id], () => getUser(id), { initialData, staleTime: getStaleTime(), enabled },
  );
}

export function usePostQuery(id: string, initialData?: Post) {
  return useQuery(
    ['post', id], async () => {
      try {
        const post = await getApi().getPost(id);
        return post;
      } catch (error: any) {
        return apiSource.getPost(id);
      }
    }, { initialData, staleTime: getStaleTime() },
  );
}

export function usePostViewPositionQuery(id: string, initialData?: PostViewPosition | null) {
  return useQuery(
    ['postViewPosition', id], () => getApi().getPostViewPosition(id), { initialData, staleTime: 10 },
  );
}

export function usePostShortUrlQuery(id: string, appName: string, enabled: boolean) {
  return useQuery(
    ['postShortUrl', id], () => getPostShortUrl(id, appName), {
      enabled,
      staleTime: 1000 * 600,
      onError: () => {
        showMessage({
          message: 'Can not get post short url, please try again later.',
          type: 'danger',
        });
      },
    },
  );
}

export function useTopicQuery(id: string, initialData?: Topic) {
  return useQuery(
    ['topic', id], () => getTopic(id), { initialData, staleTime: 1000 * 600 },
  );
}

export function useSourceQuery(
  name: string, initialData?: Source,
) {
  return useQuery(
    ['source', name], () => getSource(name), { initialData, staleTime: 1000 * 600 },
  );
}

export function useTopicSourceQuery(
  topicId: string, sourceName: string, initialData?: TopicSource,
) {
  return useQuery(
    ['topicSource', topicId, sourceName], () => getTopicSource(topicId, sourceName), { initialData, staleTime: 1000 * 600 },
  );
}

export function useBlockedTopicSourcesQuery() {
  return useQuery('blockedTopicSources', getBlockedTopicSources, {
    onError: () => {
      showMessage({
        message: 'Can not get post filters, please try again later.',
        type: 'danger',
      });
    },
  });
}

export function useTopicsQuery() {
  return useQuery('topics', getTopics, {
    onError: () => {
      showMessage({
        message: 'Can not get topics, please try again later.',
        type: 'danger',
      });
    },
  });
}

export function usePostReportReasonsQuery() {
  return useQuery('postReportReasons', getPostReportReasons, {
    onError: () => {
      showMessage({
        message: 'Can not get post report reasons, please try again later.',
        type: 'danger',
      });
    },
  });
}

export function useBlockedUsersQuery() {
  return useQuery('blockedUsers', getBlockedUsers, {
    onError: () => {
      showMessage({
        message: 'Can not get blocked users, please try again later.',
        type: 'danger',
      });
    },
  });
}

export function useBoardsQuery(source: string) {
  return useQuery(['boards', source], () => getBoards(source), {
    onError: () => {
      showMessage({
        message: 'Can not get boards, please try again later.',
        type: 'danger',
      });
    },
  });
}

export function useReleaseVersionQuery(name: string, initialData?: string) {
  return useQuery(['release-version'], async () => {
    try {
      const response = await fetch(`https://topicfeed.app/.well-known/${name}-version`);
      const version = await response.text();
      return version;
    } catch (error: any) {
      console.error('Failed to get release version, return 1.0');
      return '1.0';
    }
  }, { initialData });
}
