/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, User, IndexPost, Topics, Video, Board, Post, Image, PagedPostFeed,
} from '../types';
import { ParserBase, parseVideoUrl } from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['395', Topics.Deal],
  ['402', Topics.Shopping],
  ['327', Topics.Selfie],
  ['398', Topics.Gossip],
  ['303', Topics.Emotion],
  ['397', Topics.Sex],
  ['369', Topics.Ghost],
  ['382', Topics.Returnee],
  ['311', Topics.MovieTV],
  ['358', Topics.Celebrity],
  ['336', Topics.Investment],
  ['337', Topics.Home],
  ['410', Topics.Entrepreneur],
  ['302', Topics.Car],
  ['222', Topics.Food],
  ['333', Topics.Parenting],
  ['386', Topics.Parenting],
  ['387', Topics.Senior],
  ['215', Topics.Immigration],
  ['332', Topics.Pet],
  ['355', Topics.Sell],
  ['364', Topics.Craft],
  ['373', Topics.Career],
  ['341', Topics.Fitness],
  ['406', Topics.Gun],
  ['328', Topics.Travel],
  ['225', Topics.Fashion],
  ['378', Topics.Selfie],
  ['343', Topics.Manga],
  ['347', Topics.MakeUp],
  ['360', Topics.Wedding],
  ['372', Topics.MakeUp],
  ['374', Topics.Game],
  ['404', Topics.Christian],
  ['399', Topics.History],
  ['391', Topics.Book],
  ['389', Topics.Music],
  ['390', Topics.Fortune],
  ['385', Topics.AppleProduct],
  ['231', Topics.Art],
  ['339', Topics.Photography],
  ['344', Topics.Health],
  ['384', Topics.Politics],
]);

class ParserHuaren extends ParserBase {
  SOURCE = 'huaren.us';

  ID_PREFIX = 'huaren-';

  BASE_URL_MOBILE = 'https://m.huaren.us';

  BASE_URL = 'https://huaren.us';

  HOME_FEED_SECTION_HOT = '🔥热贴';

  HOME_FEED_SECTION_NEW = '⚡新帖';

  HOME_FEED_SECTIONS = [this.HOME_FEED_SECTION_HOT, this.HOME_FEED_SECTION_NEW];

  HOME_FEED_SECTIONS_HOT = [this.HOME_FEED_SECTION_HOT];

  TITLE = '华人论坛';

  DOMAIN = 'huaren.us';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostItemUrl(postId: string, postItemId: string, isMobile: boolean, page: number = 1):
  string {
    const postItemIdStripped = ParserBase.stripIdPrefix(postItemId);
    const postIdStripped = ParserBase.stripIdPrefix(postId);
    if (isMobile) {
      return `${this.BASE_URL_MOBILE}/showtopic.html?topicid=${postIdStripped}&postid=${postItemIdStripped}#${postItemIdStripped}`;
    }
    return `${this.BASE_URL}/showtopic.html?topicid=${postIdStripped}&postid=${postItemIdStripped}#${postItemIdStripped}`;
  }

  getCommentJs(postId: string, postItemId?: string): string {
    if (postItemId) {
      const postItemIdStripped = ParserBase.stripIdPrefix(postItemId);
      return `document.querySelector('[id="${postItemIdStripped}"] .more-action.reply').click(); true;`;
    }
    return '';
  }

  getUpVoteJs(postId: string, postItemId: string): string {
    const postItemIdStripped = ParserBase.stripIdPrefix(postItemId);
    return `document.querySelector('[id="${postItemIdStripped}"] .praise').click(); true;`;
  }

  getProfileUrlAndJs(): [string, string] {
    return [`${this.BASE_URL_MOBILE}/index.html`, 'document.querySelector(\'.tabbar-icon-user\').click()'];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHomeUrl(section: string, page: number): string {
    return this.BASE_URL;
  }

  getBoardUrl(id: string, page: number = 1): string {
    return `${this.BASE_URL}/showforum.html?forumid=${ParserBase.stripIdPrefix(id)}&page=${page}`;
  }

  getBoardIndexUrl(): string {
    return `${this.BASE_URL}/index.html`;
  }

  parseHomePage(html: string, url: string, section: string): PagedPostFeed {
    const $ = this.getCheerio(html, url);
    const postItemsList = [
      '#hotImgList ul.thumbnails a',
      '#hot_topic .article_title_bg a',
      '#new_topic .article_title_bg a',
    ]
      .map((s) => $(s)
        .filter((i: number, el: any) => !$(el).hasClass('more_article'))
        .map((i: number, el: any) => {
          const url = $(el).attr('href');
          let images: Image[] = [];
          let title = '';
          if ($('img', el)[0]) {
            title = $('img', el).attr('title')?.trim() || '';
            images = [{ url: $('img', el).attr('src') || '', width: 100, height: 100 }];
          } else {
            title = $(el).text().trim().substr(1);
          }
          const id = this.getPostIdAndPage(url)[0];
          const post: Post = {
            id: this.getPostIdAndPage(url)[0],
            title,
            text: '',
            mUrl: this.getPostUrl(id, 1, true),
            source0: { name: this.SOURCE, blocked: false, title: this.TITLE },
            images,
          };
          return post;
        })
        .get()
        .filter((p: { id: string, title: string }) => !!p.id && p.title));
    const hotPosts = postItemsList[0].concat(postItemsList[1]);
    hotPosts.sort((p0: Post, p1: Post) => ((p0.id > p1.id) ? -1 : 1));
    if (section === this.HOME_FEED_SECTION_HOT) {
      return { posts: hotPosts, hasMore: false };
    } if (section === this.HOME_FEED_SECTION_NEW) {
      return { posts: postItemsList[2], hasMore: false };
    }
    throw new Error(`No ${section} section found in home page ${url}.`);
  }

  parseBoardPage(html: string, url: string): PagedPostFeed {
    const $ = this.getCheerio(html, url);
    const boardName: string = $('.caption h1 a').text().trim();
    const board: Board = {
      id: this.getBoardId(url),
      name: boardName,
      label: boardName.replace(/[a-zA-Z&*\-']/g, '').trim(),
      section: '',
    };
    const posts = $(':not(.top).topic-list .hr-topic')
      .map((i: number, el: any) => {
        const url = $('.topic-title .title', el).attr('href');
        const title = $('.topic-title .title', el).text().trim();
        const timePublishedString = $('.topic-info .by span', el).text().trim();
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-MM-DD HH:mm', 'America/New_York');
        const timeLastCommentString = $('.topic-info .last-post .last-post-time', el).text().trim();
        const timeLastComment = ParserBase.parseTime(timeLastCommentString, 'YYYY-MM-DD HH:mm', 'America/New_York');
        const username = $('.topic-info .by a', el).attr('title').trim();
        const userUrl = $('.topic-info .by a', el).attr('href');
        const { searchParams } = new URL(userUrl);
        const user: User = {
          id: this.ID_PREFIX + searchParams.get('userid') || '',
          username,
          profileImage: '',
          sourceName: this.SOURCE,
          blocked: false,
        };

        const comments = parseInt($('.topic-info .num .replies', el).text().trim(), 10) || 0;
        const id = this.getPostIdAndPage(url)[0];
        const post: Post = {
          id,
          title,
          text: '',
          comments,
          timePublished,
          timeLastComment,
          user,
          mUrl: this.getPostUrl(id, 1, true),
          source0: { name: this.SOURCE, blocked: false, title: this.TITLE },
          board,
        };
        return post;
      })
      .get()
      .filter((p: { id: string }) => !!p.id);
    const hasMore = Boolean($('.hr-pagination .pg-next')[0]);
    return { posts, hasMore };
  }

  parseBoardIndexPage(html: string, url: string): Map<string, Board[]> {
    const $ = this.getCheerio(html, url);
    const sections = $('.expanded.arrow')
      .map((i: number, el: any) => {
        const section = $('.title-bar .title a', el).text().trim();
        const boards = $('.subforum-list .subforum', el)
          .map((ii: number, ee: any) => {
            const name = $('h2 a', ee).text().trim();
            const label = $('h2 a', ee).text().replace(/[a-zA-Z&*\-']/g, '').trim();
            const url = $('h2 a', ee).attr('href');
            const id = this.getBoardId(url);
            return {
              id, name, section, label,
            };
          })
          .get();
        if (section.includes('Leisure')) {
          boards.push({
            id: `${this.ID_PREFIX}411`,
            name: 'Chats&&华人闲话２',
            section,
            label: '华人闲话２',
          }, {
            id: `${this.ID_PREFIX}331`,
            name: 'Chats&&闲话版旧贴',
            section,
            label: '闲话版旧贴',
          }, {
            id: `${this.ID_PREFIX}407`,
            name: '伊甸园２*Eden',
            section,
            label: '伊甸园２',
          });
        } else if (section.includes('Life')) {
          boards.push({
            id: `${this.ID_PREFIX}380`,
            name: 'Parenting**共同成长',
            section,
            label: '共同成长',
          }, {
            id: `${this.ID_PREFIX}401`,
            name: 'Parenting**家小信息版',
            section,
            label: '家小信息版',
          }, {
            id: `${this.ID_PREFIX}405`,
            name: 'Parenting**家小版旧贴',
            section,
            label: '家小版旧贴',
          }, {
            id: `${this.ID_PREFIX}377`,
            name: 'Exchange**换版信息反馈区',
            section,
            label: '换版信息反馈区',
          });
        } else if (section.includes('Recreation')) {
          boards.push({
            id: `${this.ID_PREFIX}363`,
            name: 'Beauty Review产品评价区',
            section,
            label: 'Beauty Review产品评价区',
          }, {
            id: `${this.ID_PREFIX}383`,
            name: 'Beauty Swatches彩妆试色',
            section,
            label: 'Beauty Swatches彩妆试色',
          });
        } else if (section.includes('Science')) {
          boards.push({
            id: `${this.ID_PREFIX}408`,
            name: 'Politics - 时事政治2',
            section,
            label: '时事政治2',
          });
        }
        return { section, boards };
      })
      .get();
    return new Map(sections.map((t:any) => [t.section, t.boards]));
  }

  getBoardId(url: string): string {
    const { searchParams } = new URL(url);
    const forumid = searchParams.get('forumid');
    return forumid ? this.ID_PREFIX + forumid : '';
  }

  getIndexPageUrls(): string[] {
    return [this.BASE_URL];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const selectors = [
      '#hot_topic .article_img_after li',
      '#hot_topic_tabs .tab-pane ul li',
      '#recommend_title_tabs .tab-pane:not(#recommend_topic_tab_04) ul li',
    ];
    const postItemList = selectors.map((s) => $(s)
      .filter((i: number, el: any) => !$('a', el).hasClass('more_article'))
      .map((i: number, el: any) => {
        const url = $('a', el).attr('href');
        return {
          id: this.getPostIdAndPage(url)[0],
        };
      })
      .get()
      .filter((p: { id: string }) => !!p.id));
    const postItems = Array.prototype.concat.apply([], postItemList);
    return postItems;
  }

  extractText(content: string) {
    const $ = this.getCheerio(content, '');
    const texts = $('p')
      .map((i: number, e: any) => $(e).text().trim()).get()
      .filter((p: any) => p.length > 0);
    let text = texts.join('\n');
    if (text.length === 0) {
      $('.video-erro-tip').remove();
      text = $.text().trim();
    }
    return text;
  }

  extractVideo(html: string): Video[] {
    const $ = this.getCheerio(html, '');
    const videos = $('iframe')
      .map((i: number, e: any) => parseVideoUrl(e.attribs.src))
      .get();
    return videos;
  }

  processPostFetchError(id: string, error: any) {
    if (error.response?.status === 400) {
      console.log(`[ERROR] fetch post ${id}: Status 400`);
    } else {
      super.processPostFetchError(id, error);
    }
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('.no-result')[0]) {
      return $('.no-result p').text().trim();
    }
    const currentPage = this.getPostIdAndPage(url)[1];
    const stateJsString = $('#preloaded-state').html();
    const stateStart = stateJsString.search('=') + 1;
    const stateEnd = stateJsString.search(';window.topicControlApi');
    const stateString = stateJsString.substring(stateStart, stateEnd);
    const state = JSON.parse(stateString);
    const totalPage = state.showTopicData.pageCount;

    $('[style="color: Olive"]').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });
    $('span').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });
    $('blockquote img').each((i: number, el: any) => {
      const element = el;
      if (element.attribs.src && !element.attribs.src.startsWith('https://emojis.huaren.us')) {
        element.attribs.width = '300';
      }
    });
    $('blockquote iframe').each((i: number, el: any) => {
      const element = el;
      element.attribs.width = '300';
    });
    $('.pollpanel .title').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'b';
    });
    $('.pollpanel label').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('.pollpanel .content .result').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'b';
    });
    $('<br>').insertAfter('.pollpanel .content .poll-option');

    const items: PostItem[] = $('.post-list .post-item')
      .filter((i: number, el: any) => $('.hot-floor-flag', el).length === 0)
      .map((i: number, el: any) => {
        const id = $('.clip-floor', el).text().split('#')[0].trim();
        const itemId = this.ID_PREFIX + $(el).attr('id').trim();
        const poll = $('.pollpanel', el)[0] ? `<br><div>${$('.pollpanel', el).html()}</div>` : '';
        const content = `<div>${$('.post-content .wrap', el).html()}</div>${poll}`;
        const timePublishedString = $('.post-time', el).text().trim();
        const timePublished = ParserBase.parseTime(timePublishedString, 'YY-MM-DD HH:mm', 'America/New_York');
        const username = $($('.user-info .name-wrap', el)[0]).text().trim();
        const userUrl = $('a.more-action', el).attr('href');
        const { searchParams } = new URL(userUrl);
        const user: User = {
          id: this.ID_PREFIX + searchParams.get('posterid') || '',
          username,
          profileImage: $('.avatar-wrap img', el).attr('data-src'),
          sourceName: this.SOURCE,
          blocked: false,
        };
        const upVotes = parseInt($($('.praise .num', el)[0]).text(), 10) || 0;

        const postItem: PostItem = {
          id,
          content,
          user,
          upVotes,
          downVotes: 0,
          timePublished,
          images: [],
          videos: [],
          page: currentPage,
          itemId,
        };
        return postItem;
      })
      .get();

    const title = $('.post-list-header .post-item-row h3').text().trim();

    // Parse post stats.
    const postInfo = $('.poster-info span');
    const comments = parseInt(postInfo[2]?.children[2]?.data, 10) || 0;

    // Parse board and assign topics.
    const navBar = $('.bread-crumb-nav .item');
    const boardItem = navBar[navBar.length - 2];
    const boardUrl = boardItem.attribs.href;
    const boardName = $(boardItem).text().trim();
    const boardId = this.getBoardId(boardUrl);
    const board: Board = {
      id: boardId,
      name: boardName,
      label: boardName,
      section: '',
    };
    const topicId = BOARD_TO_TOPICS_MAP.get(ParserBase.stripIdPrefix(boardId)) || Topics.Other;
    return {
      currentPage, totalPage, items, title, comments, topicId, board,
    };
  }

  filterImage(url: string) {
    return (
      !url.includes('imgs.huaren.us/0x0/emojis')
      && !url.includes('emojis.huaren.us/static/')
    );
  }

  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    if (isMobile) {
      return `${this.BASE_URL_MOBILE}/showtopic.html?topicid=${ParserBase.stripIdPrefix(id)}&page=${page}`;
    }
    return `${this.BASE_URL}/showtopic.html?topicid=${ParserBase.stripIdPrefix(id)}&page=${page}`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname, searchParams } = new URL(url);
    if (hostname === 'm.huaren.us' || hostname === 'forums.huaren.us' || hostname === 'huaren.us') {
      if (pathname === '/showtopic.html' && searchParams.get('topicid')) {
        return [this.ID_PREFIX + searchParams.get('topicid'), parseInt(searchParams.get('page') || '1', 10) || 1];
      }
    }
    return ['', 0];
  }
}

export const parser = new ParserHuaren();
