/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, User, IndexPost, Topics,
} from '../types';
import {
  ParserBase, parseFreeTimeString,
} from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['46', Topics.Sell],
  ['43', Topics.Shopping],
  ['74', Topics.Shopping],
  ['68', Topics.Sell],
  ['44', Topics.EBusiness],
  ['69', Topics.Car],
  ['40', Topics.Sell],
  ['65', Topics.Banking],
  ['152', Topics.RealEstate],
  ['52', Topics.Gossip],
  ['48', Topics.RealEstate],
  ['57', Topics.Job],
  ['55', Topics.Education],
  ['54', Topics.Immigration],
  ['71', Topics.Tax],
  ['64', Topics.BlindDate],
  ['56', Topics.Parenting],
  ['159', Topics.Driving],
  ['41', Topics.Ask],
  ['155', Topics.Celebrity],
  ['203', Topics.Investment],
  ['58', Topics.MakeUp],
  ['76', Topics.Fashion],
  ['45', Topics.Food],
  ['61', Topics.Pet],
  ['94', Topics.Play],
  ['47', Topics.Travel],
  ['70', Topics.Photography],
  ['53', Topics.Play],
  ['153', Topics.Emotion],
  ['88', Topics.Bless],
]);

class ParserMoonbbs extends ParserBase {
  SOURCE = 'moonbbs.com';

  ID_PREFIX = 'moonbbs-';

  BASE_URL = 'https://www.moonbbs.com';

  getIndexPageUrls(): string[] {
    return [
      this.BASE_URL,
    ];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const postItems = $('.postlist-new-content li')
      .filter((i: number, el: any) => !!$('a', el).attr('href') && parseInt($('.postlist-new-post-replay', el).text(), 10) > 10)
      .map((i: number, el: any) => {
        const url = $('a', el).attr('href');
        const id = this.getPostIdAndPage(url)[0];
        return {
          id,
        };
      })
      .get();
    return postItems;
  }

  extractText(content: string) {
    const $ = this.getCheerio(content, '');
    $('.pstatus').remove();
    return $.text().trim();
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('#loginform')[0]) {
      return '请先登录';
    }
    if ($('.jump_c')[0]) {
      $('.jump_c p a').remove();
      return $('.jump_c p').text().trim();
    }
    const currentPage = this.getPostIdAndPage(url)[1];
    const totalPage = $('.pg label span')[0]
      ? parseInt($('.pg label span').attr('title').split(' ')[1], 10) || currentPage
      : currentPage;

    $('.message a').each((i: number, el: any) => {
      const element = el;
      if (element.attribs.href?.startsWith('https://app.adjust.com/k3l6fru')) {
        element.tagName = 'span';
      }
    });
    $('a').each((i: number, el: any) => {
      const element = el;
      if (element.attribs.href) {
        const imageUrl = element.attribs.href;
        const { searchParams } = new URL(imageUrl);
        if (searchParams.get('from') === 'album') {
          element.tagName = 'div';
        }
      }
    });
    $('.postlist h2 a.blue').remove();

    const items: PostItem[] = $('.plc.cl')
      .filter((i: number, el: any) => !!el.attribs.id)
      .map((i: number, el: any) => {
        const id = parseInt($('.authi li em', el).text().split('#')[0].trim(), 10) || i + 1;
        let attachement = '';
        if (id === 1) {
          attachement = $('.img_list').html() || $('.img_one').html() || '';
        }
        const content = `<div>${$('.message', el).html()}${attachement}</div>`;
        const timePublishedString = $($('.authi .rela', el)[0]).text().replace('收藏', '').trim();
        let timePublished = 0;
        try {
          timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-M-D HH:mm:ss', 'America/Los_Angeles');
        } catch {
          timePublished = parseFreeTimeString(timePublishedString);
        }
        if (!timePublished) {
          throw new Error(`Can not parse time string: ${timePublishedString}, result ${timePublished}`);
        }
        const username = $($('.authi li b', el)[0]).text().split(' ')[0].trim();
        const userUrl = `${this.BASE_URL}/${$('.authi li b a', el).attr('href')}`;
        const { searchParams } = new URL(userUrl);
        const user: User = {
          id: this.ID_PREFIX + (searchParams.get('uid') || ''),
          username,
          profileImage: $('.avatar img', el).attr('src'),
          sourceName: this.SOURCE,
          blocked: false,
        };

        const postItem: PostItem = {
          id: id.toString(),
          content,
          user,
          upVotes: 0,
          downVotes: 0,
          timePublished,
          images: [],
          videos: [],
        };
        return postItem;
      })
      .get();

    const title = $('.postlist h2').text().trim().replace(/(\r\n|\n|\r)/gm, '');
    const { searchParams } = new URL($('.nav .z').attr('href'));
    const boardId = searchParams.get('fid') || '';
    const topicId = BOARD_TO_TOPICS_MAP.get(boardId) || Topics.Other;

    return {
      currentPage, totalPage, items, title, topicId,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  filterImage(url: string) {
    return (
      !url.startsWith(`${this.BASE_URL}/static/image/`)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    // return `${this.BASE_URL}/forum.php?
    // mod=viewthread&tid=${ParserBase.stripIdPrefix(id)}
    // &extra=page%3D1&mobile=2${page > 1 ? `&page=${page}` : ''}`;
    return `${this.BASE_URL}/thread-${ParserBase.stripIdPrefix(id)}-${page}-1.html`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname, searchParams } = new URL(url);
    if (hostname === 'www.moonbbs.com' || hostname === 'moonbbs.com') {
      if (pathname.startsWith('/thread-') && pathname.endsWith('.html')) {
        const tokens = pathname.split('-');
        if (tokens.length === 4) {
          const id = tokens[1];
          const page = parseInt(tokens[2], 10) || 1;
          return [this.ID_PREFIX + id, page];
        }
      }
      if (pathname === '/forum.php') {
        if (searchParams.get('mod') === 'viewthread' && searchParams.get('tid')) {
          const id = searchParams.get('tid');
          const page = parseInt(searchParams.get('page') || '', 10) || 1;
          return [this.ID_PREFIX + id, page];
        }
      }
    }
    return ['', 0];
  }
}

export const parser = new ParserMoonbbs();
