import * as React from 'react';
import { StyleSheet } from 'react-native';

import { PostWebView, SafeAreaView } from '@topicfeed/appcommon/components';

import { RootStackScreenProps } from '../types';

export default function PostScreen({ navigation, route }: RootStackScreenProps<'Post'>) {
  const { id, page } = route.params;

  return (
    <SafeAreaView style={styles.container}>
      <PostWebView
        id={id}
        page={page}
        readerView={true}
        navigateBack={() => navigation.goBack()}
        onReportPost={(id: string) => navigation.navigate('ReportPost', { id })}
        checkAndRedirectToSignIn={() => true}
        navigateToPostItem={(url: string, js: string, title: string) => navigation.navigate('PostItem', { url, js, title })}
        showNotification
        showSavePost
        showTopicSourceFilter
        showTopic
        appName="topicfeed"
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});
