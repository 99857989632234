import * as React from 'react';
import {
  TouchableOpacity, StyleSheet, ViewStyle, TextStyle,
} from 'react-native';
import {
  colors, Image, Text, Icon,
} from 'react-native-elements';

const googleLogo = require('../assets/images/google_logo.png');
const appleLogo = require('../assets/images/apple_logo.png');

type SocialButtonProp = {
  type: 'apple' | 'google' | 'email';
  onPress?: () => void;
  title?: string;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
};

export function SocialButton(props: SocialButtonProp) {
  const {
    type, title, onPress, buttonStyle, textStyle,
    textColor, backgroundColor, borderColor,
  } = props;
  let iconSource;
  let defaultTitle = '';
  if (type === 'apple') {
    iconSource = appleLogo;
    defaultTitle = 'Sign in with Apple';
  } else if (type === 'google') {
    iconSource = googleLogo;
    defaultTitle = 'Sign in with Google';
  } else if (type === 'email') {
    defaultTitle = 'Sign in with Email';
  }
  const icon = type === 'email' ? (
    <Icon
      name="email"
      color={textColor}
      size={20}
      containerStyle={styles.iconStyleDefault}
      tvParallaxProperties
    />
  ) : (
    <Image
      source={iconSource}
      style={styles.iconImageStyleDefault}
      width= {20}
      height={20}
    />
  );

  return (
    <TouchableOpacity
      style={[
        styles.buttonStyleDefault, buttonStyle, { backgroundColor, borderColor },
      ]}
      onPress={onPress}
    >
      {icon}
      <Text style={[styles.textStyleDefault, textStyle, { color: textColor }]}>
        {title || defaultTitle}
      </Text>
    </TouchableOpacity>
  );
}

SocialButton.defaultProps = {
  title: '',
  onPress: undefined,
  buttonStyle: {},
  textStyle: {},
  textColor: colors.black,
  backgroundColor: colors.white,
  borderColor: colors.black,
};

const styles = StyleSheet.create({
  buttonStyleDefault: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderWidth: 2,
    height: 40,
    width: 220,
    borderRadius: 5,
    margin: 5,
  },
  iconStyleDefault: {
    padding: 0,
    marginLeft: 20,
  },
  iconImageStyleDefault: {
    padding: 10,
    marginLeft: 20,
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
  textStyleDefault: {
    flex: 1,
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: 15,
    marginRight: 20,
  },
});
