import * as React from 'react';
import {
  StyleSheet, TouchableOpacity, Image, Platform,
} from 'react-native';
import {
  colors, Text,
} from 'react-native-elements';
import { showMessage } from 'react-native-flash-message';
import * as Google from 'expo-auth-session/providers/google';
import * as WebBrowser from 'expo-web-browser';
import Constants from 'expo-constants';
import * as GoogleSignIn from 'expo-google-app-auth';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as Crypto from 'expo-crypto';

import { useStores } from '../models';
import { View } from './Themed';
import { LoadingOverlay } from './LoadingOverlay';
import * as auth from '../utils/auth';
import { SocialButton } from './SocialButton';

const logo = require('../assets/images/icon_logo.png');

WebBrowser.maybeCompleteAuthSession();

const signInWithCredential = (
  credential: auth.AuthCredential, setIsLoading: (isLoading: boolean) => void,
) => {
  setIsLoading(true);
  return auth
    .linkWithCredential(credential)
    .then((res) => {
      console.log('User account signed in with credential: ', res);
      setIsLoading(false);
      showMessage({
        message: `You have signed in with ${res?.user?.email}`,
        type: 'success',
      });
    })
    .catch((error) => {
      console.error(error);
      setIsLoading(false);
      showMessage({
        message: error.message,
        type: 'danger',
      });
    });
};

type GoogleAuthProps = {
  setIsLoading: (isLoading: boolean) => void;
};

const GoogleAuth = (props: GoogleAuthProps) => {
  const { setIsLoading } = props;
  const { resetScreenStates } = useStores();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [request, response, promptAsync] = Google.useAuthRequest({
    expoClientId: '219364402152-9brtfhuoo9q1cdascva94j2vniktbv3n.apps.googleusercontent.com',
    androidClientId: '219364402152-e1p4keoej5rnfv7tilkaceparcar5aq4.apps.googleusercontent.com',
    iosClientId: '219364402152-ruobum2svk24s05jgathi72ikg0b361g.apps.googleusercontent.com',
    webClientId: '219364402152-sck402g354mln119ef2kgeu6dmptejgq.apps.googleusercontent.com',
    scopes: ['profile', 'email'],
  });

  React.useEffect(() => {
    if (response?.type === 'success' && response.authentication) {
      const { idToken, accessToken } = response.authentication;
      const credential = auth.getGoogleAuthCredential(
        idToken || null, accessToken || null,
      );
      signInWithCredential(credential, setIsLoading).then(() => resetScreenStates());
    } else if (response?.type === 'error' && response.error?.message) {
      showMessage({
        message: response.error?.message,
        type: 'danger',
      });
    }
  }, [response]);

  const onSignInWithGoogle = async () => {
    if (Platform.OS === 'android' && Constants.appOwnership !== 'expo') {
      // Android standalone.
      setIsLoading(true);
      const config = {
        androidStandaloneAppClientId: '219364402152-e1p4keoej5rnfv7tilkaceparcar5aq4.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
      };
      const result = await GoogleSignIn.logInAsync(config);
      if (result.type === 'success') {
        const { idToken, accessToken } = result;
        const credential = auth.getGoogleAuthCredential(
          idToken || null, accessToken || null,
        );
        signInWithCredential(credential, setIsLoading).then(() => resetScreenStates());
      } else {
        setIsLoading(false);
      }
    } else {
      promptAsync();
    }
  };

  return (
    <SocialButton
      buttonStyle={styles.socialButton}
      textStyle={styles.buttonText}
      type="google"
      onPress={onSignInWithGoogle}
    />
  );
};

type AppleAuthProps = {
  setIsLoading: (isLoading: boolean) => void;
};

const AppleAuth = (props: AppleAuthProps) => {
  const { setIsLoading } = props;
  const { resetScreenStates } = useStores();

  const signInWithApple = async () => {
    try {
      const csrf = Math.random().toString(36).substring(2, 15);
      const nonce = Math.random().toString(36).substring(2, 10);
      const hashedNonce = await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256, nonce,
      );
      const appleCredential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
        state: csrf,
        nonce: hashedNonce,
      });
      const { identityToken } = appleCredential;
      const provider = auth.getOAuthCredential('apple.com');
      const credential = provider.credential({
        idToken: identityToken || undefined,
        rawNonce: nonce,
      });
      signInWithCredential(credential, setIsLoading).then(() => resetScreenStates());
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        showMessage({
          message: error.message,
          type: 'danger',
        });
      }
    }
  };

  return (
    <SocialButton
      buttonStyle={styles.socialButton}
      textStyle={styles.buttonText}
      type="apple"
      onPress={signInWithApple}
    />
  );
};

export interface SignInProps {
  onSignUp: () => void;
  onSignIn: () => void;
}

export function SignIn(props: SignInProps) {
  const { onSignUp, onSignIn } = props;
  const [isAppleSignInAvailable, setIsAppleSignInAvailable] = React.useState(Platform.OS === 'ios' && parseInt(Platform.Version, 10) >= 13);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    AppleAuthentication.isAvailableAsync()
      .then((isAvailable) => setIsAppleSignInAvailable(isAvailable))
      .catch(() => setIsAppleSignInAvailable(false))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <View style={styles.container}>
        <Image source={logo} style={styles.logo} />
        <Text style={styles.title}>海外中文话题</Text>
        <Text style={styles.subtitle}>海外中文兴趣八卦话题全聚合</Text>
        <SocialButton
          buttonStyle={styles.socialButton}
          textStyle={styles.buttonText}
          type="email"
          textColor={colors.white}
          backgroundColor={colors.primary}
          borderColor={colors.primary}
          onPress={onSignIn}
        />
        <TouchableOpacity onPress={onSignUp}>
          <Text style={styles.signUpText}>Do not have an account? Sign Up</Text>
        </TouchableOpacity>
        <GoogleAuth setIsLoading={setIsLoading} />
        { isAppleSignInAvailable
            && <AppleAuth setIsLoading={setIsLoading} />}
      </View>
      <LoadingOverlay visible={isLoading} />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  logo: {
    width: 120,
    height: 120,
    marginBottom: 30,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 30,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 30,
  },
  button: {
    width: '100%',
  },
  buttonText: {
    fontSize: 16,
  },
  signUpText: {
    color: colors.primary,
    textDecorationLine: 'underline',
    marginTop: 0,
    marginBottom: 30,
  },
  socialButton: {
    width: '100%',
    marginBottom: 15,
  },
});
