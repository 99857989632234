/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { ViewStyle } from 'react-native';
import { Icon, colors } from 'react-native-elements';
import {
  Menu, MenuOptions, MenuOption, MenuTrigger, renderers,
} from 'react-native-popup-menu';
import { useTheme } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { View, Text } from './Themed';
import { MenuItem, getTitleStyle } from './MenuItem';

type Props = {
  itemList: Array<MenuItem>;
};

export type BottomMenuRef = {
  show: () => void;
  hide: () => void;
};

const BottomMenu = React.forwardRef((props: Props, ref) => {
  const { itemList } = props;
  const menuRef = React.useRef<Menu>(null);
  const { colors: { background } } = useTheme();
  const insets = useSafeAreaInsets();

  React.useImperativeHandle(ref, () => ({ show, hide }));

  function show() {
    menuRef.current?.open();
  }

  function hide() {
    menuRef.current?.close();
  }

  const list = itemList;

  const CONTAINER_STYLE: ViewStyle = {
    paddingHorizontal: 0,
    paddingTop: 20,
    paddingBottom: 20 + insets.bottom,
    backgroundColor: background,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  };

  const ITEM_STYLE: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: background,
  };

  const ICON_STYLE: ViewStyle = {
    paddingRight: 15,
  };

  return (
    // @ts-ignore: children property.
    <Menu ref={menuRef} renderer={renderers.SlideInMenu}>
      <MenuTrigger />
      {/* @ts-ignore: children property. */}
      <MenuOptions
        customStyles={{ optionsContainer: CONTAINER_STYLE }}
      >
        { list.map((l, i) => (
          // @ts-ignore: children property.
          <MenuOption
            key={i}
            onSelect={() => {
              // iOS 14 has problem of share sheet/alert auto-close during menu dismiss.
              setTimeout(() => { if (l.onPress) { l.onPress(); } }, 700);
            }}
            disabled={l.disabled}
            customStyles={{ optionWrapper: ITEM_STYLE }}
          >
            {(l.iconType && l.iconName) && (
            <Icon
              type={l.iconType}
              name={l.iconName}
              color={l.iconColor || colors.grey3}
              containerStyle={ICON_STYLE}
              tvParallaxProperties
            />
            )}
            { typeof l.title === 'string'
              ? (
                <Text
                  numberOfLines={2}
                  style={getTitleStyle(l.titleColor, l.titleBold, l.disabled)}
                >
                  {l.title}
                </Text>
              ) : (
                <View>
                 {l.title}
                </View>
              )
            }
          </MenuOption>
        ))}
      </MenuOptions>
    </Menu>
  );
});

export { BottomMenu };
