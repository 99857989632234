import * as React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { WebView, WebViewNavigation } from 'react-native-webview';

import { Text, View } from './Themed';
import { FooterBar } from './FooterBar';
import { MenuItem } from './MenuItem';
import { useDimension } from '../context';
import { openURL } from '../utils/polyfill';
import { getRedirectUrl } from '../utils/api';

type Props = {
  url: string;
  js: string;
};

function WebPageView(props: Props) {
  const { url, js } = props;
  const webviewRef = React.useRef<WebView>(null);
  const { width } = useDimension();
  const [isJsRun, setIsJsRun] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [canGoBack, setCanGoBack] = React.useState(false);
  const [canGoForward, setCanGoForward] = React.useState(false);
  const [currentUrl, setCurrentUrl] = React.useState('');

  const footerBarItems: MenuItem[] = [
    {
      title: 'Go back',
      onPress: () => {
        webviewRef.current?.goBack();
      },
      iconType: 'ionicon',
      iconName: 'arrow-back',
      disabled: !canGoBack,
    },
    {
      title: isLoading ? 'Stop' : 'Refresh',
      onPress: isLoading
        ? () => webviewRef.current?.stopLoading()
        : () => {
          webviewRef.current?.injectJavaScript('window.location.reload(); true;');
        },
      iconType: 'ionicon',
      iconName: isLoading ? 'close' : 'refresh',
    },
    {
      title: 'Go forward',
      onPress: () => {
        webviewRef.current?.goBack();
      },
      iconType: 'ionicon',
      iconName: 'arrow-forward',
      disabled: !canGoForward,
    },
    {
      title: 'Home',
      onPress: () => webviewRef.current?.injectJavaScript(`window.location.replace('${url}'); true;`),
      iconType: 'ionicon',
      iconName: 'home',
    },
    {
      title: 'Open in browser',
      onPress: () => {
        openURL(getRedirectUrl(currentUrl, ''));
      },
      iconType: 'material-community',
      iconName: 'google-chrome',
    },
  ];

  return (
    <View style={styles.container} >
      <FooterBar appName="" itemList={footerBarItems} iconSize={20}/>
      <WebView
        ref={webviewRef}
        source={{ uri: url }}
        style={[styles.webview, { width }]}
        renderLoading={() => (
            <View style={styles.loading}>
            <ActivityIndicator size="large"></ActivityIndicator>
            </View>
        )}
        onFileDownload={({ nativeEvent }) => {
          const { downloadUrl } = nativeEvent;
          openURL(downloadUrl);
        }}
        renderError={() => (
            <View style={styles.error}>
            <Text style={styles.errorText}>Can not open the page 😞</Text>
            <Text>{' '}</Text>
            <Text>{url}</Text>
            </View>
        )}
        onContentProcessDidTerminate={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          console.warn('Content process terminated, reloading: ', nativeEvent);
          webviewRef.current?.reload();
        }}
        onLoadEnd={() => {
          if (!isJsRun) {
            webviewRef.current?.injectJavaScript(js);
            setIsJsRun(true);
          }
          setIsLoading(false);
        }}
        onLoadStart={() => setIsLoading(true)}
        onNavigationStateChange={(navState: WebViewNavigation) => {
          setCurrentUrl(navState.url);
          setCanGoBack(navState.canGoBack);
          setCanGoForward(navState.canGoForward);
        }}
        startInLoadingState
        sharedCookiesEnabled
        allowsFullscreenVideo
        domStorageEnabled
        mediaPlaybackRequiresUserAction
        allowsInlineMediaPlayback
        allowsLinkPreview
        setSupportMultipleWindows={false}
        geolocationEnabled
    // allowsBackForwardNavigationGestures
        androidHardwareAccelerationDisabled
        cacheEnabled
        originWhitelist={['*']}
      />
    </View>
  );
}

export { WebPageView };

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  webview: {
    flex: 1,
  },
  loading: {
    height: '100%',
    justifyContent: 'center',
  },
  error: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: 15,
  },
  errorText: {
    fontSize: 18,
  },
});
