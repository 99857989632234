import {
  PostPage, PostItem, User, IndexPost, Topics,
} from '../types';
import { ParserBase } from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['chat', Topics.Gossip],
  ['vent', Topics.Gossip],
  ['supernatural', Topics.Ghost],
  ['asterism', Topics.Fortune],
  ['girl', Topics.Women],
  ['friend', Topics.Play],
  ['picture', Topics.Picture],
  ['military', Topics.Military],
  ['news', Topics.Politics],
  ['joke', Topics.Joke],

  ['taxation', Topics.Tax],
  ['finance', Topics.Investment],
  ['stocks', Topics.Investment],
  ['law', Topics.Law],

  ['edu', Topics.Education],
  ['immi', Topics.Immigration],
  ['study', Topics.Education],
  ['english', Topics.English],

  ['eat', Topics.Ask],
  ['travel', Topics.Travel],
  ['feeling', Topics.Emotion],
  ['health', Topics.Health],

  ['wedding', Topics.Wedding],
  ['beauty', Topics.MakeUp],
  ['parenting', Topics.Parenting],
  ['fashion', Topics.Fashion],

  ['music', Topics.Music],
  ['movie', Topics.MovieTV],
  ['computer', Topics.Computer],
  ['iphone', Topics.AppleProduct],
  ['digital', Topics.Electronics],
  ['game', Topics.Game],
  ['online', Topics.Game],

  ['photography', Topics.Photography],
  ['pets', Topics.Pet],
  ['gardening', Topics.Home],

  ['car', Topics.Car],
  ['private', Topics.Car],
  ['accessory', Topics.Car],
  ['bmw', Topics.Car],
  ['dealer', Topics.Car],
  ['lease', Topics.Car],
  ['diy', Topics.Car],

  ['property', Topics.RealEstate],
]);

class ParserYorkbbs extends ParserBase {
  SOURCE = 'yorkbbs.ca';

  ID_PREFIX = 'yorkbbs-';

  BASE_URL_MOBILE = 'https://m.yorkbbs.ca';

  BASE_URL = 'https://forum.yorkbbs.ca';

  getIndexPageUrls(): string[] {
    return [
      this.BASE_URL,
    ];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const selectors = ['#con_a_1 li', '#con_a_2 li'];
    const postItemList = selectors.map((s) => $(s)
      .map((i: number, el: any) => {
        const url = $('a', el).attr('href');
        return {
          id: this.getPostIdAndPage(url)[0],
        };
      })
      .get()
      .filter((p: { id: string }) => !!p.id));
    const postItems = Array.prototype.concat.apply([], postItemList);
    return postItems;
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('.forumerror')[0]) {
      $('.forumtrue_errorback').remove();
      return $('.forumerror').text().trim();
    }
    const currentPage = this.getPostIdAndPage(url)[1];
    const totalPage = parseInt($('.page_number input').attr('placeholder')?.split('/')[1], 10) || currentPage;

    $('.msgborder').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'blockquote';
      element.attribs.style = '';
    });
    $('a').each((i: number, el: any) => {
      const element = el;
      if (element.attribs.href && element.attribs.href.startsWith('http://i.ybbs.ca/media/')) {
        element.tagName = 'div';
      }
    });

    let mainItem: PostItem | null = null;
    if ($('.newsdetails_main')[0]) {
      const mainUserHeader = $('.newsdetails .frumdetails_floor')[0];
      const mainTimePublishedString = $('.floor_user dl dd span script', mainUserHeader).html().trim().split('\'')[1];
      const mainTimePublished = ParserBase.parseTime(mainTimePublishedString, 'YYYY/M/D h:mm:ss', 'America/New_York');
      const mainUserName = $('.floor_user dl dt', mainUserHeader).text().trim();
      const mainUser: User = {
        id: this.ID_PREFIX + mainUserName,
        username: mainUserName,
        profileImage: $('.floor_pic img', mainUserHeader).attr('src'),
        sourceName: this.SOURCE,
        blocked: false,
      };
      mainItem = {
        id: '1',
        content: `<div>${$('.newsdetails_main').html()}</div>`,
        user: mainUser,
        upVotes: 0,
        downVotes: 0,
        timePublished: mainTimePublished,
        images: [],
        videos: [],
      };
    }

    const items: PostItem[] = $('.frumdetails_surname')
      .map((i: number, el: any) => {
        const id = $('.floor_user dl dd', el).text().split('楼')[0].trim();
        const content = `<div>${$('.floor_main', el).html()}</div>`;
        const timePublishedString = $('.floor_user dl dd span script', el).html().trim().split('\'')[1];
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY/M/D h:mm:ss', 'America/New_York');
        const username = $('.floor_user dl dt', el).text().trim();
        const user: User = {
          id: this.ID_PREFIX + username,
          username,
          profileImage: $('.floor_pic img', el).attr('src'),
          sourceName: this.SOURCE,
          blocked: false,
        };
        const postItem: PostItem = {
          id,
          content,
          user,
          upVotes: 0,
          downVotes: 0,
          timePublished,
          images: [],
          videos: [],
        };
        return postItem;
      })
      .get();

    const title = $('.newsdetails h3').text().trim();
    const boardUrl = $('.pagetop_l a').attr('href');
    const { pathname } = new URL(boardUrl);
    const boardId = pathname.substr(7).split('/')[0];
    const topicId = BOARD_TO_TOPICS_MAP.get(boardId) || Topics.Other;
    return {
      currentPage, totalPage, items: mainItem ? [mainItem].concat(items) : items, title, topicId,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  filterImage(url: string) {
    return (
      !url.includes('editor/images/smilies/')
    );
  }

  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    const [boardId, postId] = ParserBase.stripIdPrefix(id).split('_');
    if (isMobile) {
      return `${this.BASE_URL_MOBILE}/forum/${boardId}/${postId}.aspx?page=${page}`;
    }
    return `${this.BASE_URL}/${boardId}/${postId}.aspx?page=${page}`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname, searchParams } = new URL(url);
    const page = parseInt(searchParams.get('page') || '', 10) || 1;
    if (hostname === 'm.yorkbbs.ca') {
      if (pathname.startsWith('/forum/') && pathname.endsWith('.aspx')) {
        const tokens = pathname.split('.aspx')[0].split('/');
        if (tokens.length === 4) {
          return [`${this.ID_PREFIX + tokens[2]}_${tokens[3]}`, page];
        }
      }
    }
    if (hostname === 'forum.yorkbbs.ca') {
      if (pathname.endsWith('.aspx')) {
        const tokens = pathname.split('.aspx')[0].split('/');
        if (tokens.length === 3) {
          return [`${this.ID_PREFIX + tokens[1]}_${tokens[2]}`, page];
        }
      }
    }
    return ['', 0];
  }
}

export const parser = new ParserYorkbbs();
