/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, User, IndexPost, Topics,
} from '../types';
import { ParserBase } from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['70', Topics.Gun],
  ['69', Topics.Gun],
  ['77', Topics.Gun],
  ['60', Topics.Gun],
  ['45', Topics.Gun],
  ['59', Topics.Gun],
  ['81', Topics.Gun],
  ['47', Topics.Gun],
  ['66', Topics.Outdoors],
  ['49', Topics.Gun],
  ['78', Topics.Gun],
  ['52', Topics.Gun],
  ['53', Topics.Car],
]);

class ParserQiangyou extends ParserBase {
  SOURCE = 'qiangyou.org';

  ID_PREFIX = 'qiangyou-';

  BASE_URL = 'http://qiangyou.org';

  getIndexPageUrls(): string[] {
    return ['http://qiangyou.org/bbs/forum.php'];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const postItems = $('.category_newlist li')
      .map((i: number, el: any) => {
        const url = $('a', el).attr('href');
        const id = this.getPostIdAndPage(url)[0];
        return { id };
      })
      .get();
    return postItems;
  }

  extractText(content: string) {
    const $ = this.getCheerio(content, '');
    $('.pstatus').remove();
    return $('.t_f').text().trim();
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);

    if ($('.alert_error')[0]) {
      $('.alert_error .alert_btnleft').remove();
      return $('.alert_error p').text().trim();
    }
    if ($('.alert_info')[0]) {
      return $('.alert_info p').text().trim();
    }

    $('table').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('tr').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('td').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('dl').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('dt').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('dd').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('ignore_js_op').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('form').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('label').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'span';
    });
    $('.pcb img').each((i: number, el: any) => {
      const element = el;
      const url = element.attribs.file;
      if (url) {
        if (!url.startsWith('http')) {
          element.attribs.src = `${this.BASE_URL}/bbs/${element.attribs.file}`;
        } else {
          element.attribs.src = url;
        }
      }
    });
    $('input').remove();

    const currentPage = this.getPostIdAndPage(url)[1];
    let totalPage = 1;
    const totalPageElement = $('.pgt .pg label span')[0];
    if (totalPageElement) {
      totalPage = parseInt(totalPageElement.attribs.title.split(' ')[1], 10);
    }

    const items: PostItem[] = $('#postlist .plhin')
      .map((i: number, el: any) => {
        const id = $('.plc .pi strong a em', el).text() || (i + 1).toString();
        const upVotes = parseInt($('.rate .ratl a span.xi1', el).text(), 10) || 0;
        const timePublishedString = $('.authi em', el).text().trim().substr(4);
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-M-D HH:mm', 'America/Los_Angeles');
        const username = $('.pls.favatar .pi .authi a', el).text().trim();
        const userUrl = $('.pls.favatar .pi .authi a', el).attr('href');
        const { searchParams } = new URL(userUrl);
        const user: User = {
          id: this.ID_PREFIX + searchParams.get('uid') || '',
          username,
          profileImage: $('.pls.favatar .avatar img', el).attr('src'),
          sourceName: this.SOURCE,
          blocked: false,
        };

        $('.psth', el).remove();
        $('.rate', el).remove();
        const content = `<div>${$('.pcbs', el).html() || $('.pcb', el).html()}</div>`;
        const postItem: PostItem = {
          id,
          content,
          user,
          upVotes,
          downVotes: 0,
          timePublished,
          images: [],
          videos: [],
        };
        return postItem;
      })
      .get();

    const title = $('h1.ts').text().trim().replace(/(\r\n|\n|\r)/gm, '');

    // Parse post stats.
    const postStatsElement = $('.hm.ptn .xi1')[1];
    let comments = 0;
    if (postStatsElement) {
      comments = parseInt($(postStatsElement).text(), 10) || 0;
    }

    // Parse board and assign topics.
    const navBar = $('#pt a');
    const boardItem = navBar[navBar.length - 2];
    const { searchParams } = new URL(boardItem.attribs.href);
    const boardId = searchParams.get('fid') || '';
    const topicId = BOARD_TO_TOPICS_MAP.get(boardId) || Topics.Other;

    return {
      currentPage, totalPage, items, title, comments, topicId,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  filterImage(url: string) {
    return (
      !url.startsWith(`${this.BASE_URL}/bbs/static/image/`)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    return `${this.BASE_URL}/bbs/forum.php?mod=viewthread&tid=${ParserBase.stripIdPrefix(id)}&page=${page}&extra=`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname, searchParams } = new URL(url);
    if (hostname === 'qiangyou.org') {
      if (pathname === '/bbs/forum.php' && searchParams.get('tid')) {
        return [this.ID_PREFIX + searchParams.get('tid'), parseInt(searchParams.get('page') || '1', 10) || 1];
      }
    }
    return ['', 0];
  }
}

export const parser = new ParserQiangyou();
