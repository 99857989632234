/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import {
  ListItem, Icon, Text, colors,
} from 'react-native-elements';
import DrawerLayout from 'react-native-gesture-handler/DrawerLayout';

import { View } from './Themed';
import { MenuItem, getTitleStyle } from './MenuItem';
import { Avatar } from './Avatar';

type Props = {
  itemList: Array<MenuItem>;
  drawerLayout: DrawerLayout | null;
  image: string;
  imageTitle: string;
  title: string;
  iconName?: string;
  iconType?: string;
  iconColor?: string;
};

const DrawerMenu = (props: Props) => {
  const {
    itemList, drawerLayout, image, title, imageTitle, iconName, iconType, iconColor,
  } = props;
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        { (iconName && iconType)
          ? (<Icon
                name={iconName} type={iconType} size={75} color={iconColor} tvParallaxProperties />)
          : (<Avatar imageSrc={image} size={50} name={imageTitle} rounded />)}
        <Text style={styles.title} numberOfLines={2}>{title}</Text>
      </View>
      <View style={styles.footer}>
        <ScrollView>
          {itemList
            .filter((l) => !l.hide)
            .map((l, i) => (
              <ListItem
                containerStyle={styles.item}
                key={i}
                onPress={() => {
                  drawerLayout?.closeDrawer();
                  // iOS 14 has problem of share sheet/alert auto-close during menu dismiss.
                  setTimeout(() => {
                    if (l.onPress) { l.onPress(); }
                  }, 700);
                }}
                disabled={l.disabled}
                tvParallaxProperties
                hasTVPreferredFocus
              >
                {(l.iconType && l.iconType.length > 0 && l.iconName && l.iconName.length > 0)
            && <Icon
                  type={l.iconType} name={l.iconName} color={l.iconColor || colors.grey3}
                  tvParallaxProperties/>}
                <ListItem.Content>
                  { typeof l.title === 'string'
                    ? (
                      <ListItem.Title
                        numberOfLines={2}
                        style={getTitleStyle(l.titleColor, l.titleBold, l.disabled)}
                      >
                        {l.title}
                      </ListItem.Title>
                    )
                    : <ListItem.Title>{l.title}</ListItem.Title>}
                </ListItem.Content>
              </ListItem>
            ))}
        </ScrollView>
      </View>
    </View>
  );
};

DrawerMenu.defaultProps = {
  iconName: undefined,
  iconType: undefined,
  iconColor: undefined,
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    padding: 20,
    justifyContent: 'space-between',
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  item: {
    padding: 10,
  },
  title: {
    fontSize: 18,
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
});

export { DrawerMenu };
