import * as React from 'react';
import {
  StyleSheet, Platform, Share, TouchableWithoutFeedback,
} from 'react-native';
import { observer } from 'mobx-react-lite';
import {
  ListItem, colors, Text,
} from 'react-native-elements';

import {
  View, BottomMenu, BottomMenuRef, MenuItem,
} from '@topicfeed/appcommon/components';
import {
  useStores, SearchEngine, BackgroundColor, PostPreviewStyle,
} from '@topicfeed/appcommon/models';
import { useUser } from '@topicfeed/appcommon/context';
import { openURL } from '@topicfeed/appcommon/utils/polyfill';

import { RootStackScreenProps } from '../types';

const URL_PRIVACY = 'https://www.topicfeed.app/privacy.html';
const URL_TERMS = 'https://www.topicfeed.app/terms.html';
let URL_APP_STORE = '';
if (Platform.OS === 'ios') {
  URL_APP_STORE = 'https://apps.apple.com/app/apple-store/id1571825969?ct=app&mt=8';
} else if (Platform.OS === 'android') {
  URL_APP_STORE = 'https://play.google.com/store/apps/details?id=app.topicfeed&referrer=utm_source%3Dapp';
} else {
  URL_APP_STORE = 'https://www.topicfeed.app';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SettingsScreen = observer(({ route, navigation }: RootStackScreenProps<'Settings'>) => {
  const { version } = route.params;
  const { settings, setDeveloperMode, developerMode } = useStores();
  const searchEngineMenu = React.useRef<BottomMenuRef>(null);
  const backgroundColorMenu = React.useRef<BottomMenuRef>(null);
  const postPreviewStyleMenu = React.useRef<BottomMenuRef>(null);
  const [versionClickCount, setVersionClickCount] = React.useState(0);
  const user = useUser();

  const onSetSearchEngine = (searchEngine: SearchEngine) => {
    settings.setSearchEngine(searchEngine);
  };

  const searchEngineItems: MenuItem[] = [
    {
      title: 'DuckDuckGo',
      titleBold: settings.searchEngine === SearchEngine.DuckDuckGo,
      onPress: () => { onSetSearchEngine(SearchEngine.DuckDuckGo); },
      iconType: 'material-community',
      iconName: 'duck',
      iconColor: settings.searchEngine === SearchEngine.DuckDuckGo ? colors.primary : undefined,
    },
    {
      title: 'Google',
      titleBold: settings.searchEngine === SearchEngine.Google,
      onPress: () => { onSetSearchEngine(SearchEngine.Google); },
      iconType: 'material-community',
      iconName: 'google',
      iconColor: settings.searchEngine === SearchEngine.Google ? colors.primary : undefined,
    },
    {
      title: 'Bing',
      titleBold: settings.searchEngine === SearchEngine.Bing,
      onPress: () => { onSetSearchEngine(SearchEngine.Bing); },
      iconType: 'material-community',
      iconName: 'microsoft-bing',
      iconColor: settings.searchEngine === SearchEngine.Bing ? colors.primary : undefined,
    },
  ];

  const onSetBackgroundColor = (backgroundColor: BackgroundColor) => {
    settings.setBackgroundColor(backgroundColor);
  };

  const backgroundColorItems: MenuItem[] = [
    {
      title: BackgroundColor.Light,
      titleBold: settings.backgroundColor === BackgroundColor.Light,
      onPress: () => { onSetBackgroundColor(BackgroundColor.Light); },
      iconType: 'ionicon',
      iconName: 'sunny',
      iconColor: settings.backgroundColor === BackgroundColor.Light ? colors.primary : undefined,
    },
    {
      title: BackgroundColor.Dark,
      titleBold: settings.backgroundColor === BackgroundColor.Dark,
      onPress: () => { onSetBackgroundColor(BackgroundColor.Dark); },
      iconType: 'ionicon',
      iconName: 'moon',
      iconColor: settings.backgroundColor === BackgroundColor.Dark ? colors.primary : undefined,
    },
    {
      title: BackgroundColor.System,
      titleBold: settings.backgroundColor === BackgroundColor.System,
      onPress: () => { onSetBackgroundColor(BackgroundColor.System); },
      iconType: 'ionicon',
      iconName: 'settings',
      iconColor: settings.backgroundColor === BackgroundColor.System ? colors.primary : undefined,
    },
  ];

  const onSetPostPreviewStyle = (postPreviewStyle: PostPreviewStyle) => {
    settings.setPostPreviewStyle(postPreviewStyle);
  };

  const postPreviewStyleItems: MenuItem[] = [
    {
      title: PostPreviewStyle.Rich,
      titleBold: settings.postPreviewStyle === PostPreviewStyle.Rich,
      onPress: () => { onSetPostPreviewStyle(PostPreviewStyle.Rich); },
      iconType: 'ionicon',
      iconName: 'images',
      iconColor: settings.postPreviewStyle === PostPreviewStyle.Rich ? colors.primary : undefined,
    },
    {
      title: PostPreviewStyle.Simple,
      titleBold: settings.postPreviewStyle === PostPreviewStyle.Simple,
      onPress: () => { onSetPostPreviewStyle(PostPreviewStyle.Simple); },
      iconType: 'ionicon',
      iconName: 'document-text',
      iconColor: settings.postPreviewStyle === PostPreviewStyle.Simple ? colors.primary : undefined,
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <ListItem
          key="1"
          onPress={() => settings.setUsePostReaderView(!settings.usePostReaderView)}
          hasTVPreferredFocus={false}
        >
          <ListItem.Content>
            <ListItem.Title>Reader view</ListItem.Title>
            <ListItem.Subtitle>Open post with reader view</ListItem.Subtitle>
          </ListItem.Content>
          <ListItem.CheckBox
            checked={settings.usePostReaderView}
            onPress={() => settings.setUsePostReaderView(!settings.usePostReaderView)}
          />
        </ListItem>
        <ListItem
          key="2"
          onPress={() => backgroundColorMenu.current?.show()}
          containerStyle={styles.item}
          bottomDivider
        >
          <ListItem.Content>
            <ListItem.Title>Background color</ListItem.Title>
            <ListItem.Subtitle>{settings.backgroundColor}</ListItem.Subtitle>
          </ListItem.Content>
          <ListItem.Chevron tvParallaxProperties />
        </ListItem>
        <ListItem
          key="3"
          onPress={() => postPreviewStyleMenu.current?.show()}
          containerStyle={styles.item}
          bottomDivider
        >
          <ListItem.Content>
            <ListItem.Title>Post preview style</ListItem.Title>
            <ListItem.Subtitle>{settings.postPreviewStyle}</ListItem.Subtitle>
          </ListItem.Content>
          <ListItem.Chevron tvParallaxProperties />
        </ListItem>
        { Platform.OS !== 'web' && (
        <ListItem
          key="96"
          onPress={() => openURL(URL_APP_STORE)}
          containerStyle={styles.item}
          bottomDivider
        >
          <ListItem.Content>
            <ListItem.Title>Rate this app</ListItem.Title>
          </ListItem.Content>
          <ListItem.Chevron tvParallaxProperties />
        </ListItem>
        )}
        <ListItem
          key="97"
          onPress={() => Share.share(
            { message: `海外中文话题: ${URL_APP_STORE}` },
          )}
          containerStyle={styles.item}
          bottomDivider
        >
          <ListItem.Content>
            <ListItem.Title>Share with friends</ListItem.Title>
          </ListItem.Content>
          <ListItem.Chevron tvParallaxProperties />
        </ListItem>
        <ListItem
          key="98"
          onPress={() => openURL(URL_PRIVACY)}
          containerStyle={styles.item}
          bottomDivider
        >
          <ListItem.Content>
            <ListItem.Title>Privacy policy</ListItem.Title>
          </ListItem.Content>
          <ListItem.Chevron tvParallaxProperties />
        </ListItem>
        <ListItem
          key="99"
          onPress={() => openURL(URL_TERMS)}
          containerStyle={styles.item}
        >
          <ListItem.Content>
            <ListItem.Title>Terms of service</ListItem.Title>
          </ListItem.Content>
          <ListItem.Chevron tvParallaxProperties />
        </ListItem>
        <TouchableWithoutFeedback
          onPress={() => {
            if (versionClickCount > 9) {
              setDeveloperMode(true);
            } else {
              setVersionClickCount(versionClickCount + 1);
            }
          }}
        >
          <Text style={styles.version}>
            version
            {' '}
            {version}
          </Text>
        </TouchableWithoutFeedback>
        { developerMode && <Text style={styles.version}>{'uid: '}{user.user?.uid}</Text> }
        <BottomMenu
          ref={searchEngineMenu}
          itemList={searchEngineItems}
        />
        <BottomMenu
          ref={backgroundColorMenu}
          itemList={backgroundColorItems}
        />
        <BottomMenu
          ref={postPreviewStyleMenu}
          itemList={postPreviewStyleItems}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  innerContainer: {
    width: '100%',
  },
  item: {
    width: '100%',
  },
  version: {
    textAlign: 'center',
    padding: 20,
    color: colors.grey3,
  },
});

export default SettingsScreen;
