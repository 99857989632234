import 'intl';
import 'intl/locale-data/jsonp/en';
import 'date-time-format-timezone';
import 'react-native-url-polyfill/auto';

import { StatusBar } from 'expo-status-bar';
import React from 'react';
import {
  LogBox, Platform, StyleSheet, View,
} from 'react-native';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';
import { ThemeProvider, colors } from 'react-native-elements';
import FlashMessage from 'react-native-flash-message';
import { observer } from 'mobx-react-lite';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  NavigationContainer, DefaultTheme, DarkTheme,
} from '@react-navigation/native';

import { MenuProvider } from 'react-native-popup-menu';
import {
  RootStoreProvider, useStores, BackgroundColor,
} from './models';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import { useReleaseVersionQuery } from './hooks/queries';
import { UserProvider, DimensionProvider, useDimension } from './context';

const matchAll = require('string.prototype.matchall');

matchAll.shim();

// https://github.com/facebook/react-native/issues/12981
if (Platform.OS !== 'web') {
  LogBox.ignoreLogs([
    'Setting a timer',
    'The global "__expo"',
    'Calling `getNode()` on the ref of',
    'AsyncStorage has been extracted from',
  ]);
}

type Props = {
  colors: any;
  linkingConfiguration: any;
  rootNavigator: JSX.Element;
  name: string;
  version: string;
};

const queryClient = new QueryClient();

export default function AppTemplate(props: Props) {
  const { isLoadingComplete, rootStore } = useCachedResources();
  const {
    colors, rootNavigator, linkingConfiguration, name, version,
  } = props;

  if (!isLoadingComplete || !rootStore) {
    return null;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <RootStoreProvider value={rootStore}>
        {/* @ts-ignore: children property. */}
        <DimensionProvider>
          {/* @ts-ignore: children property. */}
          <UserProvider>
            <SafeAreaProvider>
              <AppImpl
                colors={colors}
                rootNavigator={rootNavigator}
                linkingConfiguration={linkingConfiguration}
                name={name}
                version={version}
              />
            </SafeAreaProvider>
          </UserProvider>
        </DimensionProvider>
      </RootStoreProvider>
    </QueryClientProvider>
  );
}

const AppImpl = observer((props: Props) => {
  const {
    colors, rootNavigator, linkingConfiguration, name, version,
  } = props;

  const store = useStores();
  const { width, height } = useDimension();

  const { data: releaseVersion } = useReleaseVersionQuery(
    name, store.isReleased ? version : undefined,
  );

  if (!store.isReleased) {
    // If current app version is greater than release version, the app is NOT released.
    const [majorRelease, minorRelease] = (releaseVersion || '0.0').split('.').map((v) => parseInt(v, 10) || 0);
    const [major, minor] = version.split('.').map((v) => parseInt(v, 10) || 0);
    if ((major < majorRelease) || (major === majorRelease && minor <= minorRelease)) {
      store.setIsReleased(true);
    }
  }

  // Setup color scheme and theme.
  let colorScheme = useColorScheme();
  if (store.settings.backgroundColor === BackgroundColor.Light) {
    colorScheme = 'light';
  } else if (store.settings.backgroundColor === BackgroundColor.Dark) {
    colorScheme = 'dark';
  }
  const navTheme = colorScheme === 'dark' ? DarkTheme : DefaultTheme;
  const themeOverrideNav = {
    ...navTheme,
    colors: {
      ...navTheme.colors,
      ...colors[colorScheme || 'light'],
    },
  };
  themeOverrideNav.colors.primary = colors[colorScheme || 'light'].primary;
  const themeOverrideElement = {
    colors: {
      divider: colors[colorScheme || 'light'].border,
      primary: colors[colorScheme || 'light'].primary,
    },
    ListItemSubtitle: {
      style: {
        color: colors.grey3,
      },
    },
    Icon: {
      disabledStyle: {
        backgroundColor: colors[colorScheme || 'light'].background,
      },
    },
  };

  // Flash message padding adjustment.
  const { top } = useSafeAreaInsets();
  let flashMessagePadding = 15;
  if (Platform.OS === 'android') {
    flashMessagePadding = top;
  } else if (Platform.OS === 'web') {
    flashMessagePadding = Math.max(top, 15);
  }

  if (!releaseVersion) {
    return null;
  }

  return (
    // @ts-ignore: children property.
    <ThemeProvider theme={themeOverrideElement} useDark={colorScheme === 'dark'}>
      <NavigationContainer
        linking={linkingConfiguration}
        theme={themeOverrideNav}>
          {/* @ts-ignore: children property. */}
          <MenuProvider customStyles={{ backdrop: styles.backdrop }}>
            <View style={{
              height, width, alignSelf: 'center',
            }}>
            {rootNavigator}
            </View>
          </MenuProvider>
      </NavigationContainer>
      <StatusBar style={colorScheme === 'light' ? 'dark' : 'light'} />
      <FlashMessage position="top" style={{ paddingTop: flashMessagePadding }} />
    </ThemeProvider>
  );
});

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: colors.grey3,
    opacity: 0.2,
  },
});
