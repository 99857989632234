import * as React from 'react';
import {
  View, FlatList, ActivityIndicator, NativeSyntheticEvent,
  NativeScrollEvent, StyleSheet, Platform, RefreshControl,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { colors, Icon, Text } from 'react-native-elements';
import { useTheme } from '@react-navigation/native';
import { useDimension } from '../context';

const refreshControl = require('react-native-web-refresh-control');

const MAX_ITEMS_TO_RENDER = 50;

export interface TimelineFeedResult<T> {
  items: T[];
  hasMore: boolean;
  after: number;
  before: number;
}

export interface TimelineFeedProps<T> {
  inputRef?: React.MutableRefObject<any>;
  keyExtractor: (item: T) => string;
  renderItem: (item: T) => React.ReactElement | null;
  fetchLatestFun: (after: number) => Promise<TimelineFeedResult<T>>;
  fetchPreviousFun: (before: number) => Promise<TimelineFeedResult<T>>;
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

export type TimelineFeedRef = {
  reload: () => void;
  scrollToIndex: (i: number) => void;
};

/**
 * TimelineFeed component renders an infinite list of items can be added from both ends,
 * e.g. latest and previous.
 */
function TimelineFeed<T>(props: TimelineFeedProps<T>) {
  const {
    keyExtractor, renderItem, fetchLatestFun, fetchPreviousFun, onScroll, inputRef,
  } = props;
  const [loaded, setIsLoaded] = React.useState(false);
  const [isLoadingLatest, setIsLoadingLatest] = React.useState(false);
  const [isLoadingPrevious, setIsLoadingPrevious] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [before, setBefore] = React.useState(0);
  const [after, setAfter] = React.useState(0);
  const [items, setItems] = React.useState<Array<T>>([]);
  const [itemIds, setItemIds] = React.useState<Set<string>>(new Set());
  const listRef = React.useRef<FlatList>(null);
  const { height } = useDimension();
  const { colors: { border } } = useTheme();

  console.log(`Render timeline feed from ${after} to ${before}`);

  const fetchLatestFeed = async (after: number) => {
    setIsLoadingLatest(true);
    return fetchLatestFun(after)
      .then((result) => {
        if (result.hasMore || after === 0) {
          // Replece all items to avoid gap betwee latest and previous items.
          setItems(result.items);
          setItemIds(new Set(result.items.map((i) => keyExtractor(i))));
          setHasMore(result.hasMore);
          setBefore(result.before);
        } else {
          const itemDedup = result.items.filter((i) => !itemIds.has(keyExtractor(i)));
          setItems(itemDedup.concat(items));
          itemDedup.forEach((i) => itemIds.add(keyExtractor(i)));
        }
        setAfter(result.after);
      })
      .catch((error) => {
        showMessage({
          message: error.message,
          type: 'danger',
        });
      })
      .finally(() => {
        setIsLoadingLatest(false);
      });
  };

  const fetchPreviousFeed = async (before: number) => {
    setIsLoadingPrevious(true);
    return fetchPreviousFun(before)
      .then((result) => {
        const itemDedup = result.items.filter((i) => !itemIds.has(keyExtractor(i)));
        setItems(items.concat(itemDedup));
        itemDedup.forEach((i) => itemIds.add(keyExtractor(i)));
        setBefore(result.before);
        setHasMore(result.hasMore);
      })
      .catch((error) => {
        showMessage({
          message: error.message,
          type: 'danger',
        });
      })
      .finally(() => setIsLoadingPrevious(false));
  };

  const loadInitial = () => {
    if (!isLoadingLatest && !isLoadingPrevious) {
      fetchLatestFeed(0).then(() => setIsLoaded(true));
    }
  };

  const loadLatest = () => {
    if (!isLoadingLatest && !isLoadingPrevious) {
      if (items.length > MAX_ITEMS_TO_RENDER) {
        fetchLatestFeed(0);
      } else {
        fetchLatestFeed(after);
      }
    }
  };

  const loadPrevious = () => {
    if (!isLoadingLatest && !isLoadingPrevious && hasMore) {
      fetchPreviousFeed(before);
    }
  };

  React.useEffect(() => {
    loadInitial();
  }, []);

  React.useImperativeHandle(inputRef, () => ({
    reload() {
      loadInitial();
    },
    scrollToIndex(i: number) {
      if (items.length > 0) { listRef.current?.scrollToIndex({ animated: true, index: i }); }
    },
  }));

  const Footer = () => {
    if (isLoadingPrevious) {
      return (
        <ActivityIndicator size="large" color={colors.grey3} />
      );
    }
    if (!hasMore && items.length !== 0) {
      return (
        <Icon type="material-community" name="sunglasses" size={30} color={colors.grey3} tvParallaxProperties />
      );
    }
    return (
      <View />
    );
  };

  const EmptyComponent = () => (
    <View style={[styles.empty, { paddingTop: height / 3 }]}>
      <Text style={styles.emptyTitle}>No content found 🤔</Text>
    </View>
  );

  const Separator = () => <View style={[styles.separator, { backgroundColor: border }]} />;

  if (loaded) {
    return (
      <FlatList<T>
        ref={listRef}
        style={styles.list}
        data={items}
        refreshing={isLoadingLatest}
        onScroll={onScroll}
        keyExtractor={keyExtractor}
        renderItem={({ item }) => renderItem(item)}
        onRefresh={loadLatest}
        onEndReached={loadPrevious}
        ListFooterComponent={Footer}
        ListFooterComponentStyle={styles.footer}
        ListEmptyComponent={EmptyComponent}
        ItemSeparatorComponent={Separator}
        windowSize={3}
        refreshControl={
        Platform.OS === 'web'
          ? (
            <refreshControl.RefreshControl
              refreshing={isLoadingLatest}
              onRefresh={loadLatest}
            />
          ) : (
            <RefreshControl
              refreshing={isLoadingLatest}
              onRefresh={loadLatest}
              tintColor={colors.grey3}
            />
          )
      }
      />
    );
  }
  return (
    <View style={styles.loading}>
      <ActivityIndicator size="large" color={colors.grey3} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    justifyContent: 'center',
    opacity: 0.6,
  },
  list: {
    height: '100%',
    width: '100%',
  },
  separator: {
    height: 1,
  },
  footer: {
    padding: 20,
    alignContent: 'center',
    justifyContent: 'center',
  },
  empty: {
    justifyContent: 'center',
  },
  emptyTitle: {
    fontSize: 18,
    color: colors.grey3,
    paddingVertical: 20,
    textAlign: 'center',
  },
  loading: {
    height: '100%',
    justifyContent: 'center',
  },
});

export { TimelineFeed };
