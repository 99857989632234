import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/'), 'https://topicfeed.app/'],
  config: {
    initialRouteName: 'BottomTab',
    screens: {
      AuthAction: '__/auth/action',
      Post: {
        path: 'posts/:id',
        parse: {
          page: (page: string) => parseInt(page, 10) || 1,
        },
        stringify: {
          page: (page: number) => page.toString(),
        },
      },
      BottomTab: '*',
    },
  },
};
