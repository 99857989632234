import { useMutation, useQueryClient } from 'react-query';
import { showMessage } from 'react-native-flash-message';
import { Topic } from '@topicfeed/common/types';
import {
  followPost, followTopic, savePost, blockTopicSource,
} from '../utils/api';
import { favoriteBoard } from '../utils/api-source';
import * as apiSource from '../utils/api-source';
import * as apiServer from '../utils/api';

let USE_LOCAL = false;
export function setUseLocalMutation(useLocal: boolean) {
  USE_LOCAL = useLocal;
}
function getApi() {
  return USE_LOCAL ? apiSource : apiServer;
}

export function useSavePostViewPostionMutation() {
  return useMutation(
    (input: { id: string, url?: string, mUrl?:string,
      comments?: number }) => getApi().savePostViewPosition(
      input.id, input.url, input.mUrl, input.comments,
    ),
  );
}

export function useFollowPostMutation(id: string, onFinish?: ()=> void) {
  const queryClient = useQueryClient();
  return useMutation(
    (input: { id: string, follow: boolean }) => followPost(input.id, input.follow),
    {
      onSuccess: (data: boolean) => {
        queryClient.setQueryData(['post', id], (postData: any) => {
          const post = postData;
          post.following = data;
          return post;
        });
        showMessage({
          message: data ? 'Noticfication is on.' : 'Noticfication is off.',
          type: 'success',
        });
        if (onFinish) { onFinish(); }
      },
      onError: (error, { follow }) => {
        console.error(error);
        showMessage({
          message: `Can not turn ${follow ? 'on' : 'off'} notification, please try again later.`,
          type: 'danger',
        });
        if (onFinish) { onFinish(); }
      },
    },
  );
}

export function useFollowTopicMutation(topic: Topic) {
  const queryClient = useQueryClient();
  return useMutation(
    (input: { id: string, follow: boolean }) => followTopic(input.id, input.follow),
    {
      onSuccess: (data: boolean) => {
        // queryClient.setQueryData(['topic', topic.id], (topicData: any) => {
        //   const topic = topicData;
        //   topic.following = data;
        //   return topic;
        // });
        queryClient.invalidateQueries(['topics']);
        showMessage({
          message: data ? `Followed topic ${topic.name}.` : `Unfollowed topic  ${topic.name}.`,
          type: 'success',
        });
      },
      onError: (error, { follow }) => {
        console.error(error);
        showMessage({
          message: `Can not ${follow ? 'follow' : 'unfollow'} topic ${topic.name}, please try again later.`,
          type: 'danger',
        });
      },
    },
  );
}

export function useFavoriteBoardMutation(id: string, name: string) {
  const queryClient = useQueryClient();
  return useMutation(
    (input: {
      id: string, name: string, favorite: boolean
    }) => favoriteBoard(input.id, input.name, input.favorite),
    {
      onSuccess: (data: boolean) => {
        // queryClient.setQueryData(['topic', topic.id], (topicData: any) => {
        //   const topic = topicData;
        //   topic.following = data;
        //   return topic;
        // });
        queryClient.invalidateQueries(['boards']);
        showMessage({
          message: data ? `Add ${name} to favorite.` : `Remove ${name} from favorite.`,
          type: 'success',
        });
      },
      onError: (error) => {
        console.error(error);
        showMessage({
          message: 'Something unexpected happened, please try again later.',
          type: 'danger',
        });
      },
    },
  );
}

export function useBlockTopicSourceMutation(
  onFinish?: ()=> void,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (input: { topicId: string, sourceName: string, block: boolean }) => blockTopicSource(
      input.topicId, input.sourceName, input.block,
    ), {
      onSuccess: (data: boolean, { topicId, sourceName }) => {
        if (topicId) {
          queryClient.setQueryData(['topicSource', topicId, sourceName], (topicSourceData: any) => {
            const topicSource = topicSourceData;
            if (topicSourceData) {
              topicSource.blocked = data;
            }
            return topicSource;
          });
        } else {
          queryClient.setQueryData(['source', sourceName], (sourceData: any) => {
            const source = sourceData;
            source.blocked = data;
            return source;
          });
        }
        showMessage({
          message: data
            ? `Posts from ${sourceName} are filtered${topicId ? ' on the topic' : ''}.`
            : `Posts from ${sourceName} are unfiltered${topicId ? ' on the topic' : ''}.`,
          type: 'success',
        });
        if (onFinish) { onFinish(); }
      },
      onError: (error, { topicId, sourceName, block }) => {
        console.error(error);
        showMessage({
          message: `Can not ${block ? 'filter' : 'unfilter'} posts from ${sourceName}${topicId ? ' on the topic' : ''}, please try again later.`,
          type: 'danger',
        });
        if (onFinish) { onFinish(); }
      },
    },
  );
}

export function useSavePostMutation(id: string, onFinish?: ()=> void) {
  const queryClient = useQueryClient();
  return useMutation(
    (input: { id: string, save: boolean }) => savePost(input.id, input.save),
    {
      onSuccess: (data: boolean) => {
        queryClient.setQueryData(['post', id], (postData: any) => {
          const post = postData;
          post.saved = data;
          return post;
        });
        showMessage({
          message: data ? 'Post is saved.' : 'Post is unsaved.',
          type: 'success',
        });
        if (onFinish) { onFinish(); }
      },
      onError: (error, { save }) => {
        console.error(error);
        showMessage({
          message: `Can not turn ${save ? 'on' : 'off'} notification, please try again later.`,
          type: 'danger',
        });
        if (onFinish) { onFinish(); }
      },
    },
  );
}

export function useHidePostMutation(id: string, onFinish?: ()=> void) {
  const queryClient = useQueryClient();
  return useMutation(
    (input: { id: string, hide: boolean }) => getApi().hidePost(input.id, input.hide),
    {
      onSuccess: (data: boolean) => {
        queryClient.setQueryData(['post', id], (postData: any) => {
          const post = postData;
          post.hidden = data;
          return post;
        });
        showMessage({
          message: data ? 'Post is hidden.' : 'Post is unhidden.',
          type: 'success',
        });
        if (onFinish) { onFinish(); }
      },
      onError: (error, { hide }) => {
        console.error(error);
        showMessage({
          message: `Can not ${hide ? 'hide' : 'unhide'} post, please try again later.`,
          type: 'danger',
        });
        if (onFinish) { onFinish(); }
      },
    },
  );
}

export function useReportPostMutation(id: string, onFinish?: ()=> void) {
  const queryClient = useQueryClient();
  return useMutation(
    (input: { id: string, reasonCode: number, reason: string }) => getApi().reportPost(
      input.id, input.reasonCode, input.reason,
    ),
    {
      onSuccess: () => {
        queryClient.setQueryData(['post', id], (postData: any) => {
          const post = postData;
          post.reported = true;
          return post;
        });
        showMessage({
          message: 'Post is reported.',
          type: 'success',
        });
        if (onFinish) { onFinish(); }
      },
      onError: (error) => {
        console.error(error);
        showMessage({
          message: 'Can not report post, please try again later.',
          type: 'danger',
        });
        if (onFinish) { onFinish(); }
      },
    },
  );
}

export function useBlockUserMutation(id: string, postId: string, onFinish?: ()=> void) {
  const queryClient = useQueryClient();
  return useMutation(
    (input: { id: string, postId: string, block: boolean }) => {
      if (id) {
        return getApi().blockUser(input.id, input.block);
      }
      return getApi().hidePost(input.postId, input.block);
    }, {
      onSuccess: (data: boolean) => {
        if (id) {
          queryClient.setQueryData(['user', id], (userData: any) => {
            const user = userData;
            user.blocked = data;
            return user;
          });
        } else {
          queryClient.setQueryData(['post', postId], (postData: any) => {
            const post = postData;
            post.hidden = data;
            return post;
          });
        }
        showMessage({
          message: data ? 'User is blocked.' : 'User is unblocked.',
          type: 'success',
        });
        if (onFinish) { onFinish(); }
      },
      onError: (error, { block }) => {
        console.error(error);
        showMessage({
          message: `Can not ${block ? 'block' : 'unblock'} user, please try again later.`,
          type: 'danger',
        });
        if (onFinish) { onFinish(); }
      },
    },
  );
}
