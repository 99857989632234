export enum Topics {
  Deal = '0',
  Gossip = '1',
  Sex = '2',
  MovieTV = '3',
  Celebrity = '4',
  Investment = '5',
  Home = '6',
  Food = '7',
  Car = '8',
  Parenting = '9',
  Immigration = '10',
  Pet = '11',
  Sell = '12',
  Job = '13',
  Fitness = '14',
  Gun = '15',
  Travel = '16',
  Fashion = '17',
  Manga = '18',
  MakeUp = '19',
  Wedding = '20',
  Game = '21',
  Christian = '22',
  History = '23',
  Music = '24',
  AppleProduct = '25',
  Art = '26',
  Photography = '27',
  Health = '28',
  Politics = '29',
  Other = '30',
  Shopping = '31',
  Entrepreneur = '32',
  Banking = '33',
  RealEstate = '34',
  Tax = '35',
  Education = '36',
  BlindDate = '37',
  Ask = '38',
  Driving = '39',
  Play = '40',
  Emotion = '41',
  Bless = '42',
  Selfie = '43',
  Ghost = '44',
  Returnee = '45',
  Senior = '46',
  Craft = '47',
  Book = '48',
  USNews = '49',
  ResearchTeaching = '50',
  CellPhonePlan = '51',
  EBusiness = '52',
  Family = '53',
  Visa = '55',
  Career = '56',
  Joke = '57',
  Basketball = '58',
  Soccer = '59',
  Outdoors = '60',
  Running = '61',
  Ski = '62',
  Programming = '63',
  TechNews = '64',
  BusinessNews = '65',
  Electronics = '66',
  ChinaNews = '67',
  WorldNews = '68',
  EnglishTest = '69',
  Love = '70',
  ProgrammingInterview = '71',
  English = '72',
  DataScience = '73',
  MachineLearning = '74',
  ProductManager = '75',
  UX = '76',
  Military = '77',
  Law = '78',
  Computer = '79',
  Marriage = '80',
  Sports = '81',
  Fortune = '82',
  Women = '83',
  Picture = '84',
  Hunting = '85',
  CryptoCurrency = '86',
  Covid19 = '87',
  EveryDayLife = '88',
  Safety = '89',
}

export const DEFAULT_TOPIC_ID = Topics.Other;
export const DEFAULT_TOPIC_NAME = '🎃其他';

export const TOPIC_NAME_MAP = new Map([
  [Topics.Deal, '🛒打折'],
  [Topics.Gossip, '🍉八卦闲聊'],
  [Topics.Sex, '♂️两性话题♀️'],
  [Topics.MovieTV, '📺电影电视'],
  [Topics.Celebrity, '🎥明星'],
  [Topics.Investment, '💸投资理财'],
  [Topics.Home, '🏡家居'],
  [Topics.Food, '🍲美食'],
  [Topics.Car, '🚗汽车'],
  [Topics.Parenting, '👶育儿'],
  [Topics.Immigration, '🛃移民'],
  [Topics.Pet, '🐶宠物'],
  [Topics.Sell, '🏷️二手市场'],
  [Topics.Job, '👔找工作'],
  [Topics.Fitness, '🏋️健身'],
  [Topics.Gun, '🔫枪械'],
  [Topics.Travel, '🧭旅游'],
  [Topics.Fashion, '👜时尚'],
  [Topics.Manga, '🦡动漫'],
  [Topics.MakeUp, '💄美妆'],
  [Topics.Wedding, '👰婚礼'],
  [Topics.Game, '🎮游戏'],
  [Topics.Christian, '✝️基督教'],
  [Topics.History, '📜历史'],
  [Topics.Music, '🎵音乐'],
  [Topics.AppleProduct, '🍎苹果产品'],
  [Topics.Art, '🎨艺术'],
  [Topics.Photography, '📷摄影'],
  [Topics.Health, '❤️医疗健康'],
  [Topics.Politics, '🗳️政治'],
  [Topics.Other, '🎃其他'],
  [Topics.Shopping, '🛒购物'],
  [Topics.Entrepreneur, '💼创业'],
  [Topics.Banking, '💳银行信用卡'],
  [Topics.RealEstate, '🏘️房地产'],
  [Topics.Tax, '🧾报税'],
  [Topics.Education, '🎓教育求学'],
  [Topics.BlindDate, '💓相亲征友'],
  [Topics.Ask, '❓求助提问'],
  [Topics.Driving, '🚦驾照驾驶'],
  [Topics.Play, '🍺吃喝玩乐'],
  [Topics.Emotion, '💌情感空间'],
  [Topics.Bless, '🎗️求祝福'],
  [Topics.Selfie, '📸自拍风采'],
  [Topics.Ghost, '👻鬼故事'],
  [Topics.Returnee, '⛵海归'],
  [Topics.Senior, '👵养老敬老'],
  [Topics.Craft, '🔨手工艺'],
  [Topics.Book, '📚读书'],
  [Topics.USNews, '🇺🇸美国新闻'],
  [Topics.ResearchTeaching, '🔬教学科研'],
  [Topics.CellPhonePlan, '📶手机计划'],
  [Topics.EBusiness, '🛒电子商务'],
  [Topics.Family, '👪家庭生活'],
  [Topics.Visa, '🇻签证'],
  [Topics.Career, '👔职业生涯'],
  [Topics.Joke, '😂笑话'],
  [Topics.Basketball, '🏀篮球'],
  [Topics.Soccer, '⚽足球'],
  [Topics.Outdoors, '🏕️户外活动'],
  [Topics.Running, '🏃‍♂️跑步'],
  [Topics.Ski, '⛷️滑雪'],
  [Topics.Programming, '💾编程'],
  [Topics.TechNews, '🇹科技新闻'],
  [Topics.BusinessNews, '🅱️财经新闻'],
  [Topics.Electronics, '📱电子产品'],
  [Topics.ChinaNews, '🇨🇳中国新闻'],
  [Topics.WorldNews, '🌐世界新闻'],
  [Topics.EnglishTest, '✏️英语考试'],
  [Topics.Love, '💌爱情'],
  [Topics.ProgrammingInterview, '🇱编程面试'],
  [Topics.English, '🇪英语学习'],
  [Topics.DataScience, '📊数据科学'],
  [Topics.MachineLearning, '🤖机器学习'],
  [Topics.ProductManager, '🇵产品经理🇲'],
  [Topics.UX, '🖥️用户界面'],
  [Topics.Military, '🎖️军事'],
  [Topics.Law, '⚖️法律'],
  [Topics.Computer, '🖥️电脑'],
  [Topics.Marriage, '👪婚姻'],
  [Topics.Sports, '🏟️体育'],
  [Topics.Fortune, '🔮占卜星象'],
  [Topics.WorldNews, '♀️女性频道'],
  [Topics.Picture, '🖼️贴图'],
  [Topics.Hunting, '🦌打猎'],
  [Topics.CryptoCurrency, '₿加密货币'],
  [Topics.Covid19, '😷新冠疫情'],
  [Topics.EveryDayLife, '☀️每日生活'],
  [Topics.Safety, '🔒安全防范'],
]);

export interface Image {
  url: string,
  width: number,
  height: number,
  link?: string,
  showPlaceHolder?: boolean,
}

export enum VideoType {
  YouTube,
  Twitter,
  Unknown,
  HTML5,
}

export interface Video {
  url: string,
  type: VideoType,
  id: string,
  content?: string,
}

export interface Board {
  id: string,
  name: string;
  favorite?: boolean;
  section: string;
  label: string;
}

export interface Topic {
  id: string,
  name: string;
  following?: boolean;
}

export interface Source {
  name: string,
  blocked?: boolean,
  title?: string,
}

export interface TopicSource {
  topic?: Topic,
  source: Source;
  blocked?: boolean;
}

export interface PostReportReason {
  reasonCode: number,
  reasonString: string,
}

export interface User {
  id: string,
  username: string,
  profileImage?: string,
  sourceName: string,
  blocked?: boolean,
}

export interface PostItem {
  id: string;
  user: User;
  content: string;
  timePublished?: number;
  timePublishedString?: string;
  upVotes?: number;
  downVotes?: number;
  level?: number;
  images: Image[];
  videos: Video[];
  page?: number;
  itemId?: string;
}

export interface PostPage {
  title: string;
  topicId: string;
  topic?: Topic;
  source?: Source;
  board?: Board;
  comments?: number;
  currentPage: number;
  totalPage: number;
  items: PostItem[];
  timeLastComment?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
}

export interface PagedPostFeed {
  posts: Post[];
  hasMore: boolean;
}

export interface SearchItem {
  id: string,
  url: string,
  title: string,
  content: string,
}

export interface PagedSearchItemFeed {
  items: SearchItem[];
  hasMore: boolean;
}

export enum PostStatus {
  Active = 'A',
  Deleted = 'D',
}

export interface UserBase {
  id: string;
  username: string;
  sourceName: string;
  profileImage?: string;
  timeCreated?: number;
  timeUpdated?: number;
}

// Used in function/crawler.
export interface PostBase {
  id: string;
  status?: PostStatus;
  title: string;
  content: string;
  text: string;
  topicId: string;
  sourceName: string;
  url: string;
  mUrl: string;
  views: number;
  comments: number;
  upVotes: number;
  downVotes: number;
  savedCount?: number;
  reportCount?: number;
  totalPage: number;
  timePublished: number;
  timeLastComment: number;
  images: Image[];
  videos: Video[];
  uid?: string;
  user?: UserBase;
  timeCreated?: number;
  timeUpdated?: number;
}

export interface IndexPost {
  id: string;
  extraData?: any;
}

// Used in app.
export interface Post {
  id: string;
  title: string;
  text: string;
  mUrl: string;
  user?: User;
  topic?: Topic;
  board?: Board;
  source0: Source;
  topicSource?: TopicSource;
  comments?: number;
  following?: boolean;
  saved?: boolean;
  hidden?: boolean;
  reported?: boolean;
  hideImage?: boolean;
  images?: Image[];
  videos?: Video[];
  timeCreated?: number;
  timePublished?: number;
  timeLastComment?: number;
  timeSaved?: number;
  viewPosition?: PostViewPosition | null;
}

export interface PostViewPosition {
  id: string;
  url?: string;
  mUrl?: string;
  comments?: number;
}

export interface Notification {
  id: string;
  type: NotificationType;
  timeCreated: number;
  read: boolean;
  detailJson: string;
}

export enum NotificationType {
  POST_COMMENT,
}
