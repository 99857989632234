// @ts-ignore
import fetch from 'react-native-fetch-polyfill';

import { getBaseUrlFromUrl, getProtocolFromUrl } from './parser';

const FETCH_TIMEOUT_MILLIS = 3 * 1000;

export async function verifyUrlConnection(url: string) {
  try {
    await fetch(url, { timeout: FETCH_TIMEOUT_MILLIS });
    return true;
  } catch (error: any) {
    return false;
  }
}

export async function verifyUrl(url: string) {
  try {
    const resp = await fetch(url, { timeout: FETCH_TIMEOUT_MILLIS });
    return resp.ok;
  } catch (error: any) {
    return false;
  }
}

type Icon = {
  url: string;
  size: number;
};

export async function getSiteIcon(url: string) {
  try {
    const baseUrl = getBaseUrlFromUrl(url);
    const protocol = getProtocolFromUrl(url);
    const response = await fetch(url, { timeout: FETCH_TIMEOUT_MILLIS });
    const html = await response.text();
    const icons: Icon[] = [];
    html.match(/<link .*?>/g)?.forEach((l: string) => {
      if (l.includes('rel="icon"') || l.includes('rel="shortcut icon"') || l.includes('rel="apple-touch-icon"')) {
        const m = l.match(/href="(.*?)"/);
        if (m) {
        // eslint-disable-next-line prefer-destructuring
          const href = m[1];
          if (!href.endsWith('.svg')) {
            // Get url.
            let url = href;
            console.log(url);
            if (href.startsWith('//')) {
              url = `${protocol}${href}`;
            } else if (href.startsWith('/')) {
              url = `${baseUrl}${href}`;
            }
            // Get size.
            let size = 16;
            const ms = l.match(/sizes="(.*?)"/);
            if (ms) {
              const sizesString = ms[1];
              const sizes = sizesString.split(' ').map((s: string) => {
                if (s === 'any') {
                  return 16;
                }
                const tokens = s.split('x');
                if (tokens.length > 1) {
                  return parseInt(tokens[0], 10) || 16;
                }
                return 16;
              });
              size = Math.max(...sizes);
            }
            icons.push({ url, size });
          }
        }
      }
    });

    console.log('Find icons: ', icons, url);
    // Return largest explicilty declared icon if exists.
    if (icons.length > 0) {
      const maxIcon = icons.reduce(
        (prev, current) => ((prev.size > current.size) ? prev : current),
      );
      console.log('Return icon: ', maxIcon);
      return maxIcon.url;
    }

    // Verify and return default favicon as fall back.
    const defaultFavicon = `${baseUrl}/favicon.ico`;
    const exists = await verifyUrl(defaultFavicon);
    if (exists) {
      console.log('Return default favicon.');
      return defaultFavicon;
    }

    // Can not find icon, return empty.
    return '';
  } catch (error: any) {
    return '';
  }
}
