import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon as DefaultIcon, IconProps as DefaultIconProps } from 'react-native-elements';

type IconPropsExtra = {
  hitSlopSize?: number;
};

export type IconProp = IconPropsExtra & DefaultIconProps;

export function Icon(props: IconProp) {
  const {
    hitSlopSize, onPress, disabled, ...otherProps
  } = props;

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      hitSlop={hitSlopSize ? {
        top: hitSlopSize, bottom: hitSlopSize, left: hitSlopSize, right: hitSlopSize,
      } : undefined}
    >
      <DefaultIcon {...otherProps} tvParallaxProperties/>
    </TouchableOpacity>
  );
}
