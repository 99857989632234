import { ParserBase } from './parserBase';
import { parser as huaren } from './parserHuaren';
import { parser as mitbbs } from './parserMitbbs';
import { parser as vanpeople } from './parserVanpeople';
import { parser as yorkbbs } from './parserYorkbbs';
import { parser as moonbbs } from './parserMoonbbs';
import { parser as onepoint3acres } from './parser1Point3Acres';
import { parser as wenxuecity } from './parserWenxueCity';
import { parser as sixpark } from './parser6Park';
import { parser as pincong } from './parserPinCong';
import { parser as qiangyou } from './parserQiangyou';
import { parser as powerapple } from './parserPowerApple';
import { parser as uscardforum } from './parserUsCardForum';

export { ParserBase } from './parserBase';

export const parsers: ParserBase[] = [
  huaren,
  mitbbs,
  vanpeople,
  yorkbbs,
  moonbbs,
  onepoint3acres,
  wenxuecity,
  sixpark,
  pincong,
  qiangyou,
  powerapple,
  uscardforum,
];

export const readerParsers: ParserBase[] = [
  huaren,
  mitbbs,
  wenxuecity,
  onepoint3acres,
];

export const parserMap: Map<string, ParserBase> = new Map(parsers.map((p) => [p.SOURCE, p]));

const idPrefixToParserMap: Map<string, ParserBase> = new Map(
  parsers.map((p) => [p.ID_PREFIX, p]),
);

export const sources = parsers.map((p) => p.SOURCE);

export function parsePostUrlToIdAndPage(url: string) {
  const postIdAndPages = parsers.map((f) => f.getPostIdAndPage(url));
  const postIdAndPage = postIdAndPages.find((pageAndId) => !!pageAndId[0]);
  return postIdAndPage || ['', 0];
}

export function getParser(source: string) {
  const parser = parserMap.get(source);
  if (!parser) {
    throw new Error(`Paser not found for ${source}`);
  }
  return parser;
}

export function getParserById(id: string) {
  const idPrefix = `${id.split('-')[0]}-`;
  const parser = idPrefixToParserMap.get(idPrefix);
  return parser;
}
