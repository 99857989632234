import * as React from 'react';
import { StyleSheet } from 'react-native';
import {
  ListItem, Text, Button,
} from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { PostReportReason } from '@topicfeed/common/types';
import {
  SafeAreaView, TextInput,
} from '@topicfeed/appcommon/components/Themed';
import { usePostReportReasonsQuery } from '@topicfeed/appcommon/hooks/queries';
import { useReportPostMutation } from '@topicfeed/appcommon/hooks/mutations';
import { LoadingOverlay } from '@topicfeed/appcommon/components';

import { RootStackScreenProps } from '../types';

type ItemProps = {
  reason: PostReportReason;
  selected: boolean;
  setReasonCodeFn: (reasonCode: number) => void;
};

const Item = ({ reason, selected, setReasonCodeFn }: ItemProps) => (
  <ListItem
    key={reason.reasonCode.toString()}
    onPress={() => setReasonCodeFn(reason.reasonCode)}
    bottomDivider
  >
    <ListItem.Content>
      <ListItem.Title numberOfLines={1}>{reason.reasonString}</ListItem.Title>
    </ListItem.Content>
    <ListItem.CheckBox
      checkedIcon="dot-circle-o"
      uncheckedIcon="circle-o"
      checked={selected}
      onPress={() => setReasonCodeFn(reason.reasonCode)}
    />
  </ListItem>
);

export default function ReportPostScreen({ route, navigation }: RootStackScreenProps<'ReportPost'>) {
  const { id } = route.params;
  const [reasonCode, setReasonCode] = React.useState(1);
  const [reason, setReason] = React.useState('');
  const { data, isLoading } = usePostReportReasonsQuery();
  const mutation = useReportPostMutation(id, () => navigation.goBack());

  return (
    <SafeAreaView style={styles.container} edges={['bottom']}>
      <KeyboardAwareScrollView
        style={styles.keyboardAvoiding}
        extraScrollHeight={120}
        enableOnAndroid
      >
        <Text style={styles.title}>What is the issue of the post?</Text>
        { data?.map((item: PostReportReason, i: number) => (
          <Item
            key={i.toString()}
            reason={item}
            selected={item.reasonCode === reasonCode}
            setReasonCodeFn={setReasonCode}
          />
        ))}
        <TextInput
          style={styles.input}
          placeholder="Additional comments"
          onChangeText={(text) => setReason(text)}
          multiline
        />
        <Button
          containerStyle={styles.button}
          title="Report"
          onPress={() => mutation.mutate({ id, reason, reasonCode })}
        />
      </KeyboardAwareScrollView>
      <LoadingOverlay visible={isLoading} />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
  },
  keyboardAvoiding: {
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    padding: 15,
  },
  input: {
    fontSize: 15,
    marginHorizontal: 15,
    marginTop: 15,
    marginBottom: 30,
    padding: 5,
    height: 60,
  },
  button: {
    margin: 15,
  },
});
