import * as React from 'react';
import {
  StyleSheet, FlatList, Platform,
} from 'react-native';
import {
  ListItem, Divider, Button,
} from 'react-native-elements';
import { observer } from 'mobx-react-lite';
import { useIsFocused } from '@react-navigation/native';

import { Topic } from '@topicfeed/common/types';
import { SafeAreaView, SearchBar } from '@topicfeed/appcommon/components';
import { useFollowTopicMutation } from '@topicfeed/appcommon/hooks/mutations';
import { useTopicsQuery } from '@topicfeed/appcommon/hooks/queries';
import { useStores } from '@topicfeed/appcommon/models';

import { RootTabScreenProps } from '../types';

type ItemProps = {
  topicData: Topic;
  onPress: () => void;
};

const Item = ({ topicData, onPress }: ItemProps) => {
  const toggleFollowingMutation = useFollowTopicMutation(topicData);
  const topic = topicData;
  return (
    <ListItem
      key={topic.id}
      onPress={onPress}
      bottomDivider
    >
      <ListItem.Content>
        <ListItem.Title style={styles.itemTitle} numberOfLines={1}>{topic.name}</ListItem.Title>
      </ListItem.Content>
      <Button
        title={topic.following ? 'Following' : 'Follow'}
        buttonStyle={styles.followButton}
        titleStyle={styles.followButtonText}
        type={topic.following ? 'solid' : 'outline'}
        onPress={() => toggleFollowingMutation.mutate({ id: topic.id, follow: !topic.following })}
        loading={toggleFollowingMutation.isLoading}
      />
    </ListItem>
  );
};

const TopicsScreen = observer(({ navigation } : RootTabScreenProps<'Topics'>) => {
  const [searchText, setSearchText] = React.useState('');
  const isFocused = useIsFocused();
  const { refreshTopics, setRefreshTopics } = useStores();
  const { data, isLoading, refetch } = useTopicsQuery();

  React.useEffect(() => {
    if (isFocused && refreshTopics) {
      refetch();
      setRefreshTopics(false);
    }
  }, [refreshTopics, isFocused]);

  const onClear = () => {
    setSearchText('');
  };

  const topics = data
    ? data.filter((t: Topic) => !searchText || t.name.includes(searchText))
    : [];

  const topicsSorted = topics
    .filter((t: Topic) => t.following)
    .concat(topics.filter((t: Topic) => !t.following));

  return (
    <SafeAreaView style={styles.container} edges={['top']}>
      <SearchBar
        containerStyle={styles.searchBar}
        platform={Platform.OS === 'android' ? 'android' : 'ios'}
        placeholder="Search Topics"
        value={searchText}
        onChangeText={(text) => setSearchText(text)}
        onClear={onClear}
        returnKeyType="search"
      />
      <Divider style={styles.divider} />
      <FlatList<Topic>
        style={styles.list}
        data={topicsSorted}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <Item topicData={item} onPress={() => navigation.navigate('TopicPosts', { topicId: item.id, title: item.name })} />
        )}
        refreshing={isLoading}
        onRefresh={() => refetch()}
      />
    </SafeAreaView>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  searchBar: {
    width: '100%',
    paddingHorizontal: 5,
  },
  list: {
    flex: 1,
    width: '100%',
  },
  divider: {
    height: 2,
  },
  followButton: {
    paddingVertical: 0,
    height: 32,
    width: 100,
    borderWidth: 2,
    borderRadius: 15,
  },
  followButtonText: {
    fontSize: 14,
  },
  itemTitle: {
    fontSize: 18,
  },
});

export default TopicsScreen;
