/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, PostBase, User, Image, IndexPost, Topics, PagedPostFeed, Board,
} from '../types';
import {
  ParserBase, parseFreeTimeString,
} from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['87', Topics.EnglishTest],
  ['125', Topics.EnglishTest],
  ['126', Topics.EnglishTest],
  ['298', Topics.EnglishTest],
  ['93', Topics.EnglishTest],
  ['27', Topics.Education],
  ['300', Topics.Education],
  ['79', Topics.Education],
  ['274', Topics.Education],
  ['71', Topics.Education],
  ['255', Topics.Education],
  ['173', Topics.Education],
  ['82', Topics.Education],
  ['177', Topics.Education],
  ['276', Topics.Education],
  ['361', Topics.Education],
  ['92', Topics.Education],
  ['144', Topics.Education],
  ['220', Topics.Education],

  ['291', Topics.Investment],
  ['354', Topics.Investment],
  ['282', Topics.RealEstate],
  ['399', Topics.RealEstate],
  ['398', Topics.RealEstate],
  ['411', Topics.RealEstate],
  ['127', Topics.Banking],
  ['401', Topics.Tax],
  ['413', Topics.Investment],

  ['29', Topics.Gossip],
  ['394', Topics.Shopping],
  ['417', Topics.Home],
  ['395', Topics.Deal],
  ['296', Topics.Health],
  ['388', Topics.Fitness],
  ['373', Topics.MakeUp],
  ['124', Topics.Car],
  ['89', Topics.Food],
  ['414', Topics.Safety],
  ['189', Topics.Play],
  ['396', Topics.Book],
  ['100', Topics.Travel],

  ['393', Topics.Gossip],
  ['314', Topics.Love],
  ['195', Topics.Selfie],
  ['80', Topics.BlindDate],
  ['348', Topics.Parenting],
  ['324', Topics.Senior],
  ['281', Topics.Pet],

  ['73', Topics.Gossip],
  ['384', Topics.Gossip],
  ['106', Topics.Gossip],
  ['315', Topics.Emotion],
  ['358', Topics.Emotion],
  ['385', Topics.Emotion],
  ['347', Topics.Politics],
  ['374', Topics.USNews],

  ['28', Topics.Job],
  ['332', Topics.Job],
  ['357', Topics.Job],
  ['237', Topics.Job],
  ['145', Topics.Job],
  ['335', Topics.Job],
  ['259', Topics.Job],
  ['198', Topics.Job],
  ['266', Topics.Job],

  ['390', Topics.Returnee],
  ['319', Topics.Returnee],
  ['289', Topics.Returnee],
  ['322', Topics.Returnee],

  ['98', Topics.Career],
  ['331', Topics.Career],
  ['287', Topics.Career],
  ['351', Topics.Career],
  ['320', Topics.Entrepreneur],
  ['194', Topics.Career],
  ['328', Topics.ResearchTeaching],

  ['84', Topics.ProgrammingInterview],
  ['323', Topics.ProgrammingInterview],
  ['233', Topics.ProgrammingInterview],
  ['91', Topics.ProgrammingInterview],
  ['128', Topics.ProgrammingInterview],
  ['200', Topics.ProgrammingInterview],
  ['204', Topics.ProgrammingInterview],
  ['205', Topics.ProgrammingInterview],
  ['206', Topics.ProgrammingInterview],
  ['209', Topics.ProgrammingInterview],
  ['311', Topics.Education],
  ['310', Topics.Education],
  ['76', Topics.English],

  ['302', Topics.MachineLearning],
  ['172', Topics.DataScience],
  ['336', Topics.ProductManager],
  ['342', Topics.UX],
  ['316', Topics.Programming],

  ['299', Topics.Immigration],
  ['99', Topics.Visa],
  ['390', Topics.Visa],
  ['301', Topics.Visa],
  ['47', Topics.Visa],
  ['338', Topics.Visa],
  ['340', Topics.Visa],
  ['273', Topics.Immigration],
  ['272', Topics.Immigration],
  ['307', Topics.Visa],
  ['412', Topics.Immigration],
  ['371', Topics.Immigration],
  ['288', Topics.Immigration],

  ['428', Topics.Bless],
  ['386', Topics.Bless],
  ['419', Topics.Women],

  ['225', Topics.Sell],
]);

class Parser1Point3Acres extends ParserBase {
  SOURCE = '1point3acres.com';

  ID_PREFIX = '1point3acres-';

  BASE_URL = 'https://www.1point3acres.com';

  // ENCODING = 'gbk';

  HOME_FEED_SECTION_HOT = '🔥热门';

  HOME_FEED_SECTION_TOP = '👍置顶';

  HOME_FEED_SECTION_NEW = '⚡最新';

  HOME_FEED_SECTIONS = [
    this.HOME_FEED_SECTION_HOT, this.HOME_FEED_SECTION_TOP, this.HOME_FEED_SECTION_NEW];

  HOME_FEED_SECTIONS_HOT = [this.HOME_FEED_SECTION_HOT];

  TITLE = '一亩三分地';

  DOMAIN = '1point3acres.com';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostItemUrl(postId: string, postItemId: string, isMobile: boolean, page: number = 1):
  string {
    return this.getPostUrl(postId, page, isMobile);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCommentJs(postId: string, postItemId?: string): string {
    if (postItemId) {
      const postItemIdStripped = ParserBase.stripIdPrefix(postItemId);
      return `document.querySelector('#replybtn_${postItemIdStripped} .button').click(); true;`;
    }
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUpVoteJs(postId: string, postItemId?: string): string {
    return '';
  }

  getProfileUrlAndJs(): [string, string] {
    return [`${this.BASE_URL}/bbs/`, ''];
  }

  getHomeUrl(section: string, page: number): string {
    if (section === this.HOME_FEED_SECTION_HOT) {
      return `${this.BASE_URL}/bbs/forum.php?mod=guide&view=hot&page=${page}`;
    } if (section === this.HOME_FEED_SECTION_TOP) {
      return `${this.BASE_URL}/bbs/forum.php?mod=guide&view=digest&page=${page}`;
    }
    return `${this.BASE_URL}/bbs/forum.php?mod=guide&view=newthread&page=${page}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getBoardUrl(id: string, page: number = 1): string {
    return `${this.BASE_URL}/bbs/forum.php?mod=forumdisplay&fid=${ParserBase.stripIdPrefix(id)}&forumdefstyle=yes&page=${page}`;
  }

  getBoardIndexUrl(): string {
    return `${this.BASE_URL}/bbs/`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseHomePage(html: string, url: string, secton: string, board?: Board): PagedPostFeed {
    const $ = this.getCheerio(html, url);
    const posts = $('table tbody')
      .filter((i: number, el: any) => el.attribs.id?.startsWith('normalthread'))
      .map((i: number, el: any) => {
        const url = $('th a.xst', el).attr('href');
        const title = $('th a.xst', el).text().trim();
        const id = this.getPostIdAndPage(url)[0];

        const userElements = $('.by', el);
        const userElement = userElements[userElements.length - 2];
        const timePublishedString = $('em span ', userElement).text().trim();
        let timePublished;
        try {
          timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-M-D', 'America/Los_Angeles');
        } catch {
          timePublished = parseFreeTimeString(timePublishedString);
        }
        const lastCommenterElement = userElements[userElements.length - 1];
        const timeLastCommentString = $('em span ', lastCommenterElement).text().trim();
        let timeLastComment;
        try {
          timeLastComment = ParserBase.parseTime(timeLastCommentString, 'YYYY-M-D', 'America/Los_Angeles');
        } catch {
          timeLastComment = parseFreeTimeString(timeLastCommentString);
        }
        const comments = parseInt($('.num a', el).text(), 10) || 0;

        const username = $('cite', userElement).text().trim();
        const userId = this.ID_PREFIX + username;
        const user: User = {
          id: userId,
          username,
          profileImage: '',
          sourceName: this.SOURCE,
        };

        const images = $('th div a img', el).map((ii: number, iel: any) => {
          const imageSrc = iel.attribs.src;
          const imageUrl = imageSrc;
          const imageUrlRegular = imageUrl.endsWith('!s') ? imageUrl.slice(0, imageUrl.length - 2) : imageUrl;
          return {
            url: imageUrlRegular,
            width: 100,
            height: 100,
          };
        }).get()
          .filter((img: Image) => !img.url.includes('static/image/common'));

        return {
          id,
          title,
          text: '',
          timePublished,
          timeLastComment,
          comments,
          user,
          source0: { name: this.SOURCE, blocked: false, title: this.TITLE },
          board,
          images,
        };
      })
      .get();
    const hasMore = Boolean($('.bm .pg .nxt')[0]);
    return { posts, hasMore };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseBoardPage(html: string, url: string): PagedPostFeed {
    const $ = this.getCheerio(html, url);
    const boardName: string = $('.bm_h h1 a').text().trim();
    const board: Board = {
      id: this.getBoardId(url),
      name: boardName,
      label: boardName,
      section: '',
    };
    return this.parseHomePage(html, url, '', board);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseBoardIndexPage(html: string, url: string): Map<string, Board[]> {
    const $ = this.getCheerio(html, url);
    const sections = $('.bmw')
      .filter((i: number, el: any) => i > 2 && !$('.bm_h h2 a', el).attr('href').includes('/home.php'))
      .map((i: number, el: any) => {
        const section = $('.bm_h h2', el).text().trim();
        const boards = $('.bm_c .fl_g dl dt a', el)
          .map((ii: number, ee: any) => {
            const name = $(ee).text().trim();
            const label = name;
            const url = $(ee).attr('href');
            const id = this.getBoardId(url);
            return {
              id, name, section, label,
            };
          })
          .get()
          .filter((b: Board) => !!b.id);
        return { section, boards };
      })
      .get();
    return new Map(sections.map((t:any) => [t.section, t.boards]));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getBoardId(url: string): string {
    const { pathname, searchParams } = new URL(url);
    if (pathname.startsWith('/bbs/forum-')) {
      return this.ID_PREFIX + pathname.split('-')[1];
    } if (pathname.startsWith('/bbs/forum.php') && searchParams.get('fid')) {
      return this.ID_PREFIX + searchParams.get('fid');
    }
    return '';
  }

  getIndexPageUrls(): string[] {
    return [
      `${this.BASE_URL}/bbs/forum.php?mod=guide&view=hot`,
      `${this.BASE_URL}/bbs/forum.php?mod=guide&view=digest`,
    ];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const postItems = $('.common')
      .map((i: number, el: any) => {
        const link = $('.xst', el)[0];
        const url = link.attribs.href;
        const id = this.getPostIdAndPage(url)[0];
        const images = $('div a img', el).map((ii: number, iel: any) => {
          const imageSrc = iel.attribs.src;
          const imageUrl = imageSrc;
          const imageUrlRegular = imageUrl.endsWith('!s') ? imageUrl.slice(0, imageUrl.length - 2) : imageUrl;
          return {
            url: imageUrlRegular,
            width: 0,
            height: 0,
          };
        }).get()
          .filter((img: Image) => !img.url.includes('static/image/common'));
        return {
          id,
          extraData: { images },
        };
      })
      .get();
    return postItems;
  }

  extractText(content: string) {
    const $ = this.getCheerio(content, '');
    $('.pstatus').remove();
    return $('span[itemprop="articleBody"]').text().trim();
  }

  filterPost(post: PostBase) {
    return post.comments > 3;
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('input#username')[0]) {
      return '请先登录';
    }
    if ($('.alert_error')[0]) {
      $('.alert_error .alert_btnleft').remve();
      return $('.alert_error p').text().trim();
    }
    if ($('.jump_c')[0]) {
      $('.jump_c p a').remove();
      return $('.jump_c p').text().trim();
    }
    const currentPage = this.getPostIdAndPage(url)[1];
    const totalPage = $('.pg label span')[0]
      ? parseInt($('.pg label span').attr('title').split(' ')[1], 10) || currentPage
      : currentPage;

    $('.jammer').remove();
    $('.locked .locked-blur').remove();
    $('.locked').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'blockquote';
    });
    $('a').each((i: number, el: any) => {
      const element = el;
      if (element.attribs.href) {
        const imageUrl = element.attribs.href;
        try {
          const { searchParams } = new URL(imageUrl);
          if (searchParams.get('from') === 'album') {
            element.tagName = 'div';
          }
        } catch {
          console.log('Invalid url: ', imageUrl);
        }
      }
    });
    $('th').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });
    $('td').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });

    const items: PostItem[] = $('.plc.cl')
      .filter((i: number, el: any) => !$('form', el)[0])
      .map((i: number, el: any) => {
        const id = (currentPage - 1) * 15 + i + 1;
        const attachement = $('.img_list', el).html() || $('.img_one', el).html() || '';
        const content = `<div>${$('.message', el).html()}${attachement}</div>`;
        const timePublishedString = $('.authi .rela span', el).text().replace('收藏', '').trim();
        let timePublished = 0;
        try {
          timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-M-D HH:mm:ss', 'America/Los_Angeles');
        } catch {
          timePublished = parseFreeTimeString(timePublishedString);
        }
        if (!timePublished) {
          throw new Error(`Can not parse time string: ${timePublishedString}, result ${timePublished}`);
        }

        const username = $('.authi li[itemprop="author"]', el).text().trim();
        // const userUrl = `${this.BASE_URL}/${$('.authi li a[itemprop="url"]', el)
        // .attr('href') || ''}`;
        // const { searchParams } = new URL(userUrl);
        const userId = this.ID_PREFIX + username;
        const user: User = {
          id: userId,
          username,
          profileImage: $('.avatar img', el).attr('src') || '',
          sourceName: this.SOURCE,
          blocked: false,
        };
        const itemId = this.ID_PREFIX + $('div.pi', el).attr('itemid').substr(3).trim();

        const postItem: PostItem = {
          id: id.toString().trim(),
          content,
          user,
          timePublishedString,
          timePublished,
          images: [],
          videos: [],
          page: currentPage,
          itemId,
        };
        return postItem;
      })
      .get();

    const title = $('.postlist h2').text().trim().replace(/(\r\n|\n|\r)/gm, '');

    // Parse board and assign topics.
    const { searchParams } = new URL($('.nav .z').attr('href'));
    const boardId = searchParams.get('fid') || '';
    const boardName = $('.nav .name').text().trim();
    const board: Board = {
      id: boardId,
      name: boardName,
      label: boardName,
      section: '',
    };
    const topicId = BOARD_TO_TOPICS_MAP.get(ParserBase.stripIdPrefix(boardId)) || Topics.Other;

    return {
      currentPage, totalPage, items, title, topicId, board,
    };
  }

  extractImage(html: string): Image[] {
    const $ = this.getCheerio(html, '');
    const images = $('img')
      .filter((i: number, ee: any) => $(ee).attr('id') && $(ee).attr('id').startsWith('aimg_'))
      .map((i: number, ee: any) => {
        const url = $(ee).attr('src');
        const link = $(ee.parent).attr('href');
        return {
          url,
          width: 0,
          height: 0,
          link,
        };
      })
      .get();
    return images;
  }

  // eslint-disable-next-line class-methods-use-this
  filterImage(url: string) {
    return (
      !url.startsWith(`${this.BASE_URL}/static/image/`)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    return `${this.BASE_URL}/bbs/thread-${ParserBase.stripIdPrefix(id)}-${page}-1.html`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname } = new URL(url);
    if (hostname === 'www.1point3acres.com' || hostname === '1point3acres.com') {
      if (pathname.startsWith('/bbs/thread-') && pathname.endsWith('.html')) {
        const tokens = pathname.split('-');
        if (tokens.length === 4) {
          const id = tokens[1];
          const page = parseInt(tokens[2], 10) || 1;
          return [this.ID_PREFIX + id, page];
        }
      }
    }
    return ['', 0];
  }

  applyExtraData(post: PostBase, extraData: any) {
    const postWithExtraData = post;
    postWithExtraData.images = extraData.images;
    return postWithExtraData;
  }
}

export const parser = new Parser1Point3Acres();
