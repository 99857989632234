import * as React from 'react';
import { Avatar as DefaultAvatar } from 'react-native-elements';

type Props = {
  imageSrc?: string;
  name?: string;
  size: number;
  rounded?: boolean;
};

// Randomly rotating color based on title.
const AVATAR_COLORS = ['#4285F4', '#BB001B', '#EA4335', '#FBBC05', '#34A853'];

export function Avatar({
  imageSrc, name, size, rounded,
}: Props) {
  const title = name ? name[0].toLocaleUpperCase() : '';
  const color = AVATAR_COLORS[title.charCodeAt(0) % AVATAR_COLORS.length];
  const avatarSize = size || 25;

  return (
    <DefaultAvatar
      placeholderStyle={{ backgroundColor: color }}
      overlayContainerStyle={{ backgroundColor: imageSrc ? 'transparent' : color }}
      imageProps={{ resizeMethod: 'scale', resizeMode: 'cover' }}
      size={avatarSize}
      source={imageSrc ? { uri: imageSrc } : undefined}
      title={title}
      titleStyle={{ backgroundColor: color }}
      rounded={rounded}
    />
  );
}

Avatar.defaultProps = {
  imageSrc: undefined,
  name: undefined,
  rounded: false,
};
