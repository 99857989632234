import * as React from 'react';
import {
  StyleSheet, GestureResponderEvent,
} from 'react-native';
import { Text, Icon, colors } from 'react-native-elements';
import RenderHtml, { useInternalRenderer } from 'react-native-render-html';
import { WebView } from 'react-native-webview';
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import { useTheme } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { PostItem, VideoType, Image } from '@topicfeed/common/types';
import { useStores } from '../models';
import { View } from './Themed';
import { Avatar } from './Avatar';
import { getRedirectUrl } from '../utils/api';

const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

type Props = {
  postId: string,
  postItem: PostItem,
  width: number,
  isPostAuthor: boolean,
  postUrl: string,
  setShowImageAndIndex: (images: Image[], index: number) => void,
  onCommentPost: (postId: string, postItemId?: string, page?: number) => void;
  onUpVotePost: (postId: string, postItemId?: string, page?: number) => void;
  isNewItem?: boolean,
};

const ICON_SIZE = 20;

const customHTMLElementModels = {
  iframe: iframeModel,
};

const defaultWebViewProps = {};

const LEVEL_INDENT = 20;
const MAX_INDENT = 80;

const ImagePlaceHolder = () => (
  <View style={styles.imagePlaceHolderContainer}>
    <Icon
      type="material-community"
      name="image-outline"
      color={colors.primary}
      size={50}
      tvParallaxProperties
    />
     <Text style={styles.imagePlaceHolderTitle}>Click to show image</Text>
  </View>
);

function PostItemViewImpl(props: Props) {
  const {
    postItem, width, isPostAuthor, postId, postUrl, setShowImageAndIndex,
    onCommentPost, onUpVotePost, isNewItem,
  } = props;
  const { colors: { text, border, background } } = useTheme();
  const html5Videos = postItem.videos.filter((v) => v.type === VideoType.HTML5);
  const imageToIndexMap = new Map<string, number>(
    postItem.images.map((img: Image, idx: number) => [img.url, idx]),
  );
  const { isReleased } = useStores();

  function provideEmbeddedHeaders(uri: string, tagName: string) {
    if (tagName === 'img') {
      return {
        Referer: postUrl,
      };
    }
    return undefined;
  }

  function CustomImageRenderer(
    props: any,
  ) {
    const { Renderer, rendererProps } = useInternalRenderer('img', props);
    const index = imageToIndexMap.get(rendererProps.source.uri || '');
    const image = index !== undefined ? postItem.images[index] : undefined;
    const disabled = index === undefined;
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        disabled={disabled}
        onPress={() => {
          if (index !== undefined) {
            const imageLink = image?.link;
            if (imageLink) {
              WebBrowser.openBrowserAsync(imageLink);
            } else {
              setShowImageAndIndex(postItem.images, index);
            }
          }
        }}
      >
      { image?.showPlaceHolder
        ? <ImagePlaceHolder/>
        : <Renderer {...rendererProps}/>
      }
      </TouchableOpacity>
    );
  }
  const renderers = {
    iframe: IframeRenderer,
    img: CustomImageRenderer,
  };

  const rendersProps = {
    iframe: {
      scalesPageToFit: true,
      webViewProps: {
        javaScriptEnabled: true,
        allowsFullscreenVideo: true,
        allowsInlineMediaPlayback: true,
        mediaPlaybackRequiresUserAction: true,
      },
    },
    a: {
      onPress: (event: GestureResponderEvent, href: string) => {
        WebBrowser.openBrowserAsync(getRedirectUrl(href, postId));
      },
    },
  };

  const leftPadding = Math.min((postItem.level || 0) * LEVEL_INDENT, MAX_INDENT);
  // console.log(`${postItem.id} images: `, images, imageToIndexMap);
  // console.log(`${postItem.id} videos: `, html5Videos);
  return (
    <View style={[styles.container, { paddingLeft: leftPadding }]}>
      { isNewItem
        && <View style={styles.newCommentContainer}>
          <View style={styles.newCommentDivider}></View>
          <Text style={styles.newCommentText}>NEW</Text>
        </View>
      }
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Avatar
            size={20}
            imageSrc={postItem.user.profileImage}
            name={postItem.user.username}
            rounded
          />
          <Text style={styles.username}>
            {postItem.user.username}
          </Text>
          {isPostAuthor && (
          <View style={styles.postAutherIcon}>
            <Text style={styles.postAuhtor}>楼主</Text>
          </View>
          )}
        </View>
        <View style={styles.headerRight}>
          <Text style={styles.time}>
            {postItem.timePublished
              ? dayjs(postItem.timePublished).fromNow() : postItem.timePublishedString}
          </Text>
          <Text style={styles.time}>
            {'  #'}
            {postItem.id}
          </Text>
        </View>
      </View>
      <View style={styles.content}>
        <RenderHtml
          contentWidth={width}
          source={{ html: postItem.content }}
          renderers={renderers}
          WebView={WebView}
          customHTMLElementModels={customHTMLElementModels}
          defaultWebViewProps={defaultWebViewProps}
          renderersProps={rendersProps}
          defaultTextProps={{
            style: { color: text },
          }}
          baseStyle={{
            fontSize: 16,
            lineHeight: 24,
          }}
          tagsStyles={{
            h1: {
              fontSize: 24, lineHeight: 36,
            },
            h2: {
              fontSize: 22, lineHeight: 33,
            },
            h3: {
              fontSize: 20, lineHeight: 30,
            },
            h4: {
              fontSize: 19, lineHeight: 28,
            },
            h5: {
              fontSize: 18, lineHeight: 27,
            },
            h6: {
              fontSize: 17, lineHeight: 25,
            },
            b: {
              fontWeight: 'bold',
            },
            p: {
              margin: 0,
            },
            blockquote: {
              backgroundColor: border,
              padding: 10,
              marginHorizontal: 0,
              marginVertical: 10,
              borderRadius: 5,
            },
            img: {
              alignSelf: 'flex-start',
              paddingVertical: 10,
            },
            th: {
              paddingHorizontal: 15,
              paddingVertical: 5,
              backgroundColor: border,
            },
            td: {
              paddingHorizontal: 15,
              paddingVertical: 5,
            },
          }}
          enableExperimentalBRCollapsing
          enableExperimentalGhostLinesPrevention
          provideEmbeddedHeaders={provideEmbeddedHeaders}
        />
        { html5Videos.map((l, i) => {
          const html = `<html lang="en"><body style="background-color:${background}">${l.content || ''}</body></html>`;
          return (
            <WebView
              containerStyle={styles.videoView}
              key={i.toString()}
              originWhitelist={['*']}
              source={{ html: `${html}` }}
              style={{ height: width, width }}
            />);
        })}
      </View>
      <View style={styles.footer}>
        { (postItem.upVotes !== undefined) ? (
        <View style={styles.upVotes}>
          <Icon
            type="material-community"
            name="thumb-up-outline"
            color={colors.grey3}
            size={ICON_SIZE}
            onPress={() => onUpVotePost(postId, postItem.itemId, postItem.page)}
            disabled={!isReleased}
            tvParallaxProperties
          />
          <Text style={styles.upVoteText}>{postItem.upVotes}</Text>
        </View>
        ) : (<View/>)}
        <Icon
          type="material-community"
          name="comment-outline"
          color={colors.grey3}
          size={ICON_SIZE}
          onPress={() => onCommentPost(postId, postItem.itemId, postItem.page)}
          disabled={!isReleased}
          tvParallaxProperties
        />
      </View>
    </View>
  );
}

export const PostItemView = React.memo(PostItemViewImpl);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 5,
    paddingBottom: 15,
    paddingHorizontal: 20,
  },
  content: {
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  username: {
    fontWeight: 'bold',
    fontSize: 15,
    marginLeft: 5,
  },
  dot: {
    borderRadius: 3,
    height: 3,
    width: 3,
    backgroundColor: colors.grey3,
    marginHorizontal: 10,
  },
  time: {
    fontSize: 15,
    color: colors.grey3,
  },
  upVotes: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  upVoteText: {
    marginLeft: 5,
    color: colors.grey3,
  },
  postAuhtor: {
    fontSize: 13,
    fontWeight: 'bold',
    color: 'white',
  },
  postAutherIcon: {
    backgroundColor: colors.primary,
    marginHorizontal: 8,
    paddingHorizontal: 5,
    paddingVertical: 3,
    borderRadius: 5,
  },
  videoView: {
    marginVertical: 10,
  },
  newCommentContainer: {
    marginTop: -2,
  },
  newCommentDivider: {
    height: 2,
    width: '100%',
    backgroundColor: 'red',
  },
  newCommentText: {
    color: 'red',
    fontWeight: 'bold',
    paddingRight: 20,
    paddingTop: 5,
    textAlign: 'right',
  },
  imagePlaceHolderContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 5,
  },
  imagePlaceHolderTitle: {
    fontWeight: 'bold',
    color: colors.grey3,
    marginLeft: 5,
  },
});
