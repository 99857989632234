import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { Badge } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';
import { observer } from 'mobx-react-lite';

import { useStores } from '@topicfeed/appcommon/models';
import { checkSignedIn } from '@topicfeed/appcommon/utils/auth';
import { View } from '@topicfeed/appcommon/components';

import ProfileScreen from '../screens/ProfileScreen';
import TopicsScreen from '../screens/TopicsScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import HomeScreen from '../screens/HomeScreen';

// import SavedPagesScreen from '../screens/SavedPagesScreen';
// import SearchScreen from '../screens/SearchScreen';

import {
  RootTabParamList, HomeParamList, ProfileParamList,
  TopicsParamList, NotificationsParamList, RootStackScreenProps,
} from '../types';

const BottomTab = createBottomTabNavigator<RootTabParamList>();

type TabBarProps = {
  color: string;
  focused: boolean;
};

const BottomTabNavigator = observer(({ navigation }: RootStackScreenProps<'BottomTab'>) => {
  // const colorScheme = useColorScheme();
  const { hasNewHomeFeed, hasNewHotFeed, hasNewNotification } = useStores();

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: false }}
      // tabBarOptions={{ activeTintColor: Colors[colorScheme].tint }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeNavigator}
        options={{
          tabBarIcon: ({ focused, color }: TabBarProps) => (
            <TabBarIcon
              name={focused ? 'home' : 'home-outline'}
              color={color}
              hasBadge={hasNewHomeFeed || hasNewHotFeed}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Topics"
        component={TopicsNavigator}
        options={{
          tabBarIcon: ({ focused, color }: TabBarProps) => (
            <TabBarIcon
              name={focused ? 'ios-grid' : 'ios-grid-outline'}
              color={color}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Notifications"
        component={NotificationsNavigator}
        options={{
          tabBarIcon: ({ focused, color }: TabBarProps) => (
            <TabBarIcon
              name={focused ? 'notifications' : 'notifications-outline'}
              color={color}
              hasBadge={hasNewNotification}
            />
          ),
        }}
        listeners={{
          tabPress: (e) => {
            if (!checkSignedIn(navigation)) {
              e.preventDefault();
            }
          },
        }}
      />
      <BottomTab.Screen
        name="Profile"
        component={ProfileNavigator}
        options={{
          tabBarIcon: ({ focused, color }: TabBarProps) => (
            <TabBarIcon
              name={focused ? 'person' : 'person-outline'}
              color={color}
            />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
});

export default BottomTabNavigator;

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: {
  name: React.ComponentProps<typeof Ionicons>['name'];
  color: string; hasBadge?: boolean }) {
  const { hasBadge, name, color } = props;
  return (
    <View>
      <Ionicons size={25} style={{ marginBottom: -3 }} name={name} color={color} />
      { hasBadge && (
        <Badge
          status="error"
          containerStyle={{ position: 'absolute', top: 0, right: 0 }}
        />
      )}
    </View>
  );
}

TabBarIcon.defaultProps = {
  hasBadge: false,
};

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const HomeStack = createStackNavigator<HomeParamList>();

function HomeNavigator() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="HomeScreen"
        component={HomeScreen}
      />
    </HomeStack.Navigator>
  );
}

const ProfileStack = createStackNavigator<ProfileParamList>();

function ProfileNavigator() {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{ headerShown: false }}
      />
    </ProfileStack.Navigator>
  );
}

const TopicsStack = createStackNavigator<TopicsParamList>();

function TopicsNavigator() {
  return (
    <TopicsStack.Navigator>
      <TopicsStack.Screen
        name="TopicsScreen"
        component={TopicsScreen}
        options={{ headerShown: false }}
      />
    </TopicsStack.Navigator>
  );
}

const NotificationsStack = createStackNavigator<NotificationsParamList>();

function NotificationsNavigator() {
  return (
    <NotificationsStack.Navigator>
      <NotificationsStack.Screen
        name="NotificationsScreen"
        component={NotificationsScreen}
        options={{
          headerShown: true,
          headerTitle: 'Notifications',
          headerTitleAlign: 'center',
        }}
      />
    </NotificationsStack.Navigator>
  );
}
