import * as React from 'react';
import {
  StyleSheet, TouchableOpacity,
} from 'react-native';
import { colors, Text } from 'react-native-elements';

import { SignIn, SafeAreaView, View } from '@topicfeed/appcommon/components';
import { useUser } from '@topicfeed/appcommon/context';
import * as auth from '@topicfeed/appcommon/utils/auth';

import { RootStackScreenProps } from '../types';

const AuthHomeScreen = ({ navigation, route }: RootStackScreenProps<'AuthHome'>) => {
  const { user } = useUser();
  const { title } = route.params;

  React.useEffect(() => {
    if (auth.isSignedIn(user)) {
      navigation.pop();
    }
  }, [user?.isAnonymous]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.innerContainer}>
        <SignIn
          onSignIn={() => navigation.navigate('Auth', { mode: 'signin' })}
          onSignUp={() => navigation.navigate('Auth', { mode: 'signup' })}
        />
      </View>
      <View style={styles.footer}>
        <TouchableOpacity onPress={() => navigation.pop()}>
          <Text style={styles.skip}>Skip</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: {
    justifyContent: 'center',
  },
  header: {
    padding: 30,
    paddingBottom: 50,
  },
  footer: {
    padding: 30,
    alignItems: 'flex-end',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  skip: {
    fontSize: 16,
    color: colors.primary,
  },
});

export default AuthHomeScreen;
