import * as React from 'react';
import { Platform, Share, StyleSheet } from 'react-native';
import { Text, colors } from 'react-native-elements';
import RenderHtml from 'react-native-render-html';
import { WebView } from 'react-native-webview';
import { iframeModel } from '@native-html/iframe-plugin';
import { useTheme } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { SearchItem } from '@topicfeed/common/types';
import { View } from './Themed';
import { Icon } from './Icon';
import { BottomMenu, BottomMenuRef } from './BottomMenu';
import { alert } from '../utils/polyfill';
import { usePostQuery } from '../hooks/queries';
import { useHidePostMutation, useBlockUserMutation } from '../hooks/mutations';

const ICON_SIZE = 20;
const HIT_SLOP = 25;

type Props = {
  item: SearchItem,
  width: number,
  onPress: (url: string) => void;
  checkAndRedirectToSignIn: () => boolean;
  onReportPost: (id: string) => void;
};

const customHTMLElementModels = {
  iframe: iframeModel,
};

const defaultWebViewProps = {};

function SearchItemViewImpl(props: Props) {
  const {
    item, width, onPress, checkAndRedirectToSignIn, onReportPost,
  } = props;
  const { colors: { text } } = useTheme();
  const { data: post } = usePostQuery(item.id, {
    id: item.id, title: '', text: '', mUrl: '', source0: { name: '' },
  });
  const bottomMenu = React.useRef<BottomMenuRef>(null);
  const hidePostMutation = useHidePostMutation(item.id);
  const blockUserMutation = useBlockUserMutation('', item.id);

  if (!post || post.hidden || post.user?.blocked) {
    return null;
  }

  const onShare = () => {
    if (Platform.OS === 'ios') {
      Share.share({ url: item.url });
    } else {
      Share.share({ message: item.url });
    }
  };

  const footerIcons = [
    <Icon
      key="3"
      type="ionicons"
      name="ios-share"
      color={colors.grey3}
      onPress={onShare}
      size={ICON_SIZE}
      hitSlopSize={HIT_SLOP}
    />,
    <Icon
      key="4"
      type="material-community"
      name="dots-horizontal"
      color={colors.grey3}
      onPress={() => bottomMenu.current?.show()}
      size={ICON_SIZE}
      hitSlopSize={HIT_SLOP}
    />,
  ];

  const bottomMenuList = [
    {
      title: 'Hide post',
      onPress: () => {
        if (checkAndRedirectToSignIn()) {
          alert(
            'Do you want to hide this post?',
            `${item.title}`,
            [
              { text: 'Cancel', style: 'cancel' },
              {
                text: 'OK',
                onPress: () => hidePostMutation.mutate({ id: item.id, hide: true }),
              },
            ],
            { cancelable: true },
          );
        }
      },
      iconType: 'ionicon',
      iconName: 'eye-off-outline',
    },
    {
      title: 'Block user',
      onPress: () => {
        if (checkAndRedirectToSignIn()) {
          alert(
            'Do you want to block this user?',
            '',
            [
              { text: 'Cancel', style: 'cancel' },
              {
                text: 'OK',
                onPress: () => {
                  blockUserMutation.mutate({ id: '', postId: item.id, block: true });
                },
              },
            ],
            { cancelable: true },
          );
        }
      },
      iconType: 'ionicon',
      iconName: 'person-remove-outline',
    },
    {
      title: post.reported ? 'Post reported' : 'Report post',
      onPress: () => {
        if (checkAndRedirectToSignIn()) { onReportPost(item.id); }
      },
      iconType: 'ionicon',
      iconName: post.reported ? 'flag' : 'flag-outline',
      iconColor: colors.grey3,
      disabled: post.reported,
    },
  ];

  return (
    <View style={[styles.container]}>
      <TouchableOpacity onPress={() => onPress(item.url)}>
        <Text style={styles.title}>
          {item.title}
        </Text>
        <View style={styles.content}>
          <RenderHtml
            contentWidth={width}
            source={{ html: item.content }}
            // renderers={renderers}
            WebView={WebView}
            customHTMLElementModels={customHTMLElementModels}
            defaultWebViewProps={defaultWebViewProps}
            // renderersProps={rendersProps}
            defaultTextProps={{
              style: { color: text },
            }}
            baseStyle={{
              fontSize: 16,
              lineHeight: 24,
            }}
            tagsStyles={{
              em: {
                fontWeight: 'bold',
              },
            }}
            enableExperimentalBRCollapsing
            enableExperimentalGhostLinesPrevention
          />
        </View>
      </TouchableOpacity>
      <View style={styles.footer}>
        { footerIcons }
      </View>
      <BottomMenu
        ref={bottomMenu}
        itemList={bottomMenuList}
      />
    </View>
  );
}

export const SearchItemView = React.memo(SearchItemViewImpl);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    lineHeight: 25,
    fontWeight: 'bold',
    paddingHorizontal: 8,
    paddingTop: 10,
  },
  content: {
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 5,
    paddingBottom: 15,
    paddingHorizontal: 20,
  },
});
