/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { StyleSheet, Image } from 'react-native';
import { Icon, colors } from 'react-native-elements';

import { View, Text } from './Themed';
import { MenuItem } from './MenuItem';

type Props = {
  itemList: Array<MenuItem>;
  iconSize?: number;
  showPromotion?: boolean;
  appName: string;
};

const ICON_HITSLOP = {
  top: 50, bottom: 50, left: 50, right: 50,
};

const logoForumreader = require('../assets/images/logo_forumreader.png');
const logoTopicFeed = require('../assets/images/logo_topicfeed.png');
const google = require('../assets/images/googleplay.png');
const apple = require('../assets/images/appstore.png');

const PromotionBanner = ({ appName }: { appName: string }) => {
  let logo:any = null;
  let title = '';
  let subTitle = '';
  let titleSize = 0;
  if (appName === 'forumreader') {
    logo = logoForumreader;
    title = '论坛浏览器';
    subTitle = '清爽论坛阅读体验';
    titleSize = 20;
  } else if (appName === 'topicfeed') {
    logo = logoTopicFeed;
    title = '海外中文话题';
    subTitle = '最热话题实时推送';
    titleSize = 20;
  }
  return (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    <Image source={logo} style={{ width: 42, height: 42, marginHorizontal: 5 }}/>
    <View style={{ alignItems: 'center', marginRight: 5 }}>
      <Text style={{ fontSize: titleSize, fontWeight: 'bold', marginBottom: 5 }}>{title}</Text>
      <Text style={{ fontSize: 14, fontWeight: 'bold', color: 'grey' }}>{subTitle}</Text>
    </View>
    <Image source={apple} style={{
      width: 110, height: 30, resizeMode: 'contain', marginHorizontal: 0,
    }}/>
    <Image source={google} style={{
      width: 110, height: 30, resizeMode: 'contain', marginHorizontal: 0,
    }}/>
  </View>);
};

const FooterBar = (props: Props) => {
  const {
    itemList, iconSize, showPromotion, appName,
  } = props;
  const itemToShow = showPromotion ? [] : itemList;

  return (
    <View style={styles.container}>
      {showPromotion && <PromotionBanner appName={appName}/>}
      {itemToShow
        .filter((l) => !l.hide)
        .map((l, i) => (l.item ? (
          <View key={i}>
            {l.item({})}
          </View>
        ) : (
          <Icon
            key={i}
            containerStyle={styles.icon}
            type={l.iconType}
            name={l.iconName || ''}
            size={iconSize || 30}
            onPress={l.onPress}
            hitSlop={ICON_HITSLOP}
            color={l.disabled ? colors.grey3 : l.iconColor}
            disabled={l.disabled}
            tvParallaxProperties
          />
        )
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  icon: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export { FooterBar };
