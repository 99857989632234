/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, User, IndexPost, Topics, Board, PagedPostFeed,
  Post, Image,
} from '../types';
import { ParserBase } from './parserBase';

const boards = require('./boards-mitbbs.json');

const BOARD_TO_TOPICS_MAP = new Map([
  ['AwesomeJoke', Topics.Joke],
  ['ScitechNews', Topics.TechNews],
  ['ChinaNews', Topics.ChinaNews],
  ['ChinaNews', Topics.ChinaNews],
  ['Headline', Topics.WorldNews],
  ['History', Topics.History],
  ['Military', Topics.Military],
  ['Military2', Topics.Military],
  ['MiscNews', Topics.Politics],
  ['PopNews', Topics.Celebrity],
  ['BusinessNews', Topics.BusinessNews],
  ['USANews', Topics.USNews],
  ['WorldNews', Topics.WorldNews],
  ['I485', Topics.Immigration],
  ['Immigration', Topics.Immigration],
  ['WeiMingYiMin', Topics.Immigration],
  ['EB23', Topics.Immigration],
  ['I140', Topics.Immigration],
  ['Automobile', Topics.Car],
  ['CellularPlan', Topics.CellPhonePlan],
  ['ChinaStock', Topics.Investment],
  ['CivilSociety', Topics.Politics],
  ['ebiz', Topics.EBusiness],
  ['Faculty', Topics.ResearchTeaching],
  ['Family', Topics.Family],
  ['FleaMarket', Topics.Sell],
  ['Food', Topics.Food],
  ['HouseRental', Topics.RealEstate],
  ['Investment', Topics.Investment],
  ['JobHunting', Topics.Job],
  ['JobMarket', Topics.Job],
  ['Living', Topics.Home],
  ['Medicine', Topics.Health],
  ['Money', Topics.Banking],
  ['NextGeneration', Topics.Parenting],
  ['Overseas', Topics.Education],
  ['Parenting', Topics.Parenting],
  ['PennySaver', Topics.Deal],
  ['Postdoc', Topics.ResearchTeaching],
  ['Returnee', Topics.Returnee],
  ['SellAbroad', Topics.EBusiness],
  ['shopping', Topics.Shopping],
  ['StartUp.', Topics.Entrepreneur],
  ['Stock', Topics.Investment],
  ['TAX', Topics.Tax],
  ['Tuangou', Topics.Shopping],
  ['Visa', Topics.Visa],
  ['Working', Topics.Career],
  ['Basketball', Topics.Basketball],
  ['Fitness', Topics.Fitness],
  ['Game', Topics.Game],
  ['GunsAndGears', Topics.Gun],
  ['loseweight', Topics.Fitness],
  ['NBA', Topics.Basketball],
  ['Outdoors', Topics.Outdoors],
  ['Running', Topics.Running],
  ['Ski', Topics.Ski],
  ['Soccer', Topics.Soccer],
  ['Travel', Topics.Travel],
  ['TVGame', Topics.Game],
  ['astrology', Topics.Fortune],
  ['AudioBook', Topics.Book],
  ['bagua', Topics.Celebrity],
  ['ClassicalMusic', Topics.Music],
  ['CouchPotato', Topics.MovieTV],
  ['Craft', Topics.Craft],
  ['E-Sports', Topics.Game],
  ['Exchange', Topics.Sell],
  ['Fashion', Topics.Fashion],
  ['gardening', Topics.Home],
  ['iShow', Topics.Selfie],
  ['Movie', Topics.MovieTV],
  ['Music', Topics.Music],
  ['MusicPlayer', Topics.Music],
  ['PhotoForum', Topics.Photography],
  ['PhotoGear', Topics.Photography],
  ['PhotoProcessing', Topics.Photography],
  ['Rock', Topics.Music],
  ['Sound_of_Music', Topics.Music],
  ['TVChinese', Topics.MovieTV],
  ['WeddingBells', Topics.Wedding],
  ['Blessing', Topics.Bless],
  ['Heart', Topics.Emotion],
  ['Joke', Topics.Joke],
  ['Love', Topics.Emotion],
  ['pets', Topics.Pet],
  ['Piebridge', Topics.BlindDate],
  ['Sex', Topics.Sex],
  ['Sex2', Topics.Sex],
  ['Arts', Topics.Art],
  ['Comic', Topics.Manga],
  ['TrustInJesus', Topics.Christian],
  ['Apple', Topics.AppleProduct],
  ['PDA', Topics.Electronics],
  ['Programming', Topics.Programming],
]);

class ParserMitbbs extends ParserBase {
  SOURCE = 'mitbbs.com';

  ID_PREFIX = 'mitbbs-';

  BASE_URL = 'https://mitbbs.com';

  ENCODING = 'gb2312';

  HOME_FEED_SECTION_HOT = '🔥热门';

  HOME_FEED_SECTION_TOP = '👍置顶';

  HOME_FEED_SECTIONS = [this.HOME_FEED_SECTION_HOT, this.HOME_FEED_SECTION_TOP];

  HOME_FEED_SECTIONS_HOT = [this.HOME_FEED_SECTION_HOT];

  FETCH_BOARD_MOBILE = true;

  FETCH_BOARD_INDEX_MOBILE = true;

  TITLE = 'MITBBS';

  DOMAIN = 'mitbbs.com';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostItemUrl(postId: string, postItemId: string, isMobile: boolean, page: number = 1):
  string {
    return this.getPostUrl(postId, page, isMobile);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCommentJs(postId: string, postItemId?: string): string {
    if (postItemId) {
      const postItemIdStripped = ParserBase.stripIdPrefix(postItemId);
      return `document.querySelector('[id="re_${postItemIdStripped}"] a').click(); true;`;
    }
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUpVoteJs(postId: string, postItemId?: string): string {
    return '';
  }

  getProfileUrlAndJs(): [string, string] {
    return [`${this.BASE_URL}/mwap/home/index.php`, ''];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHomeUrl(section: string, page: number): string {
    return this.BASE_URL;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getBoardUrl(id: string, page: number = 1): string {
    return `${this.BASE_URL}/mwap/forum/board.php?board=${ParserBase.stripIdPrefix(id)}&page=${page}`;
  }

  getBoardIndexUrl(): string {
    return `${this.BASE_URL}/mwap/forum/index.php`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseHomePage(html: string, url: string, section: string): PagedPostFeed {
    const $ = this.getCheerio(html, url);
    const postItemsList = [
      '.index_other_news ul li a',
      '.index_ten_big ol li a',
    ]
      .map((s) => $(s)
        .map((i: number, el: any) => {
          const title = $(el).text().trim();
          const url = $(el)[0].attribs.href;
          const id = this.getPostIdAndPage(url)[0];
          const post: Post = {
            id: this.getPostIdAndPage(url)[0],
            title,
            text: '',
            mUrl: this.getPostUrl(id, 1, true),
            source0: { name: this.SOURCE, blocked: false, title: this.TITLE },
          };
          return post;
        })
        .get()
        .filter((p: { id: string, title: string }) => !!p.id && p.title));

    if (section === this.HOME_FEED_SECTION_TOP) {
      return { posts: postItemsList[0], hasMore: false };
    } if (section === this.HOME_FEED_SECTION_HOT) {
      return { posts: postItemsList[1], hasMore: false };
    }
    throw new Error(`No ${section} section found in home page ${url}.`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseBoardPage(html: string, url: string): PagedPostFeed {
    const $ = this.getCheerio(html, url);
    const boardName: string = $('.ds_box').text().trim().split(' ')[0].trim();
    const board: Board = {
      id: this.getBoardId(url),
      name: boardName,
      label: boardName,
      section: '',
    };
    const posts = $('.theme_conter .theme_li')
      .map((i: number, el: any) => {
        const url = $('a.theme_a', el).attr('href');
        const id = this.getPostIdAndPage(url)[0];
        const title = $('.theme_middle', el).text().trim();
        const timePublishedString = `${$('.theme_time', el).text().trim()} ${$($('.theme_right span', el)[0]).text().trim()}`;
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-MM-DD HH:mm', 'America/New_York');
        const username = $('.theme-id', el).text().trim();
        const user = {
          id: this.ID_PREFIX + username,
          username,
          profileImage: $('.theme_small', el).attr('src'),
          sourceName: this.SOURCE,
          blocked: false,
        };
        const comments = parseInt($($('.p_r', el)).text(), 10) || 0;
        return {
          id,
          title,
          text: '',
          comments,
          timePublished,
          user,
          mUrl: this.getPostUrl(id, 1, true),
          source0: { name: this.SOURCE, blocked: false, title: this.TITLE },
          board,
        };
      })
      .get();
    const hasMore = Boolean($('#sub_page')[0]);
    return { posts, hasMore };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseBoardIndexPage(html: string, url: string): Map<string, Board[]> {
    return new Map(boards.map((s: any) => [s.section, s.boards]));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getBoardId(url: string): string {
    const { pathname, searchParams } = new URL(url);
    if (pathname === '/mwap/forum/board.php') {
      return this.ID_PREFIX + searchParams.get('board') || '';
    }
    if (pathname.startsWith('/bbsdoc/')) {
      return this.ID_PREFIX + pathname.split('/').slice(-1)[0].split('.')[0];
    }
    return '';
  }

  getIndexPageUrls(): string[] {
    return [this.BASE_URL];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const selectors = [
      '.index_other_news01 li', '.index_ten_big_left_con li', '.index_ten_big_left_con li',
      '.bbs_con_t_t01_con01 li', '.bbs_con_t_t01_con01 li', '.bbs_con_t_t01_con01 li',
    ];
    const postItemList = selectors.map((s) => $(s)
      .map((i: number, el: any) => {
        const url = $('a', el).attr('href');
        return {
          id: this.getPostIdAndPage(url)[0],
        };
      })
      .get()
      .filter((p: { id: string }) => !!p.id));
    const postItems = Array.prototype.concat.apply([], postItemList);
    return postItems;
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('body p').text().includes('获取主题失败')) {
      return '获取主题失败';
    }
    const currentPage = this.getPostIdAndPage(url)[1];
    const totalItems = (parseInt($('.news_title em').text().split('/')[0], 10) || 0) + 1;
    const totalPage = Math.ceil(totalItems / 10);

    $('a').each((i: number, el: any) => {
      const element = el;
      if (element.attribs.href) {
        if (element.attribs.href === $('img', element).attr('src')) {
          element.tagName = 'div';
        }
      }
    });

    const items: PostItem[] = $('.news_conter .news_li')
      .map((i: number, el: any) => {
        const indexString = $('.news_position', el).text();
        const id = indexString === '楼主' ? '1' : indexString.split('楼')[0].trim();
        const itemId = this.ID_PREFIX + $('.theme_middle', el).attr('id').split('_')[1].trim();
        const content = `<div>${$('.theme_middle', el).html()}</div>`;
        const timePublishedString = $('.theme_time', el).text().trim();
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-MM-DD  HH:mm', 'America/New_York');
        const username = $($('.theme_right h4', el)[0]).text().trim();
        const profileImageUrl = $('.theme_small', el).attr('src');
        const user: User = {
          id: this.ID_PREFIX + username,
          username,
          profileImage: profileImageUrl.startsWith('http') ? profileImageUrl : this.BASE_URL + profileImageUrl,
          sourceName: this.SOURCE,
          blocked: false,
        };
        const postItem: PostItem = {
          id,
          content,
          user,
          timePublished,
          images: [],
          videos: [],
          page: currentPage,
          itemId,
        };
        return postItem;
      })
      .get();

    const title = $('.news_title p').text().trim();
    const comments = totalItems - 1;

    // Parse board and assign topics.
    const boardUrl = $('.news_title span a').attr('href');
    const boardName = $('.news_title span a').text().trim();
    const boardId = this.getBoardId(boardUrl);
    const board: Board = {
      id: boardId,
      name: boardName,
      label: boardName,
      section: '',
    };
    const topicId = BOARD_TO_TOPICS_MAP.get(ParserBase.stripIdPrefix(boardId)) || Topics.Other;

    return {
      currentPage, totalPage, items, title, comments, topicId, board,
    };
  }

  extractImage(html: string): Image[] {
    const $ = this.getCheerio(html, '');
    const images = $('img')
      .map((i: number, ee: any) => {
        const url = $(ee).attr('src');
        if (url.includes('mitbbs.com/article2')) {
          const link = $(ee).attr('src');
          return {
            url,
            width: 0,
            height: 0,
            link,
            showPlaceHolder: true,
          };
        }
        return {
          url,
          width: 0,
          height: 0,
        };
      })
      .get();
    return images;
  }

  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    const boardAndId = ParserBase.stripIdPrefix(id);
    const idx = boardAndId.lastIndexOf('_');
    const board = boardAndId.substr(0, idx);
    const siteId = boardAndId.substr(idx + 1);
    if (isMobile) {
      return `${this.BASE_URL}/mwap/forum/article.php?board=${board}&groupid=${siteId}&content_type=all&page=${page}`;
    }
    return `${this.BASE_URL}/article_t/${board}/${siteId}_0_${page}.html`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname, searchParams } = new URL(url);
    // const { hostname, pathname, query } = urlparser.parse(url, { parseQueryString: true });
    if (hostname === 'www.mitbbs.com' || hostname === 'mitbbs.com') {
      const board = searchParams.get('board');
      const postId = searchParams.get('groupid');
      const pageNum = searchParams.get('page') || '1';
      if (pathname === '/mwap/forum/article.php' && board && postId) {
        return [`${this.ID_PREFIX}${board}_${postId}`, parseInt(pageNum, 10) || 1];
      }
      if (pathname.startsWith('/article_t/') && pathname.endsWith('.html')) {
        const tokens = pathname.split('/');
        if (tokens.length === 4) {
          const board = tokens[2];
          const idAndPage = tokens[3].replace('.html', '');
          const id = idAndPage.split('_')[0];
          const page = parseInt(idAndPage.split('_')[2], 10);
          return [`${this.ID_PREFIX}${board}_${id}`, page];
        }
      }
    }
    return ['', 0];
  }
}

export const parser = new ParserMitbbs();
