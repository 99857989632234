/* eslint-disable class-methods-use-this */
import { IndexPost, Topics } from '../types';
import { ParserDiscourse } from './parserDiscourse';

class ParserUsCardForum extends ParserDiscourse {
  BOARD_TO_TOPICS_MAP: Map<number, string> = new Map([
    [12, Topics.Banking],
    [5, Topics.Banking],
    [6, Topics.Banking],
    [32, Topics.Banking],
    [9, Topics.Investment],
    [13, Topics.Investment],
    [14, Topics.RealEstate],
    [10, Topics.Tax],
    [13, Topics.CryptoCurrency],
    [15, Topics.Travel],
    [38, Topics.Travel],
    [7, Topics.Travel],
    [17, Topics.Travel],
    [50, Topics.Travel],
    [58, Topics.Travel],
    [20, Topics.Shopping],
    [26, Topics.Shopping],
    [21, Topics.Deal],
    [23, Topics.Electronics],
    [25, Topics.Car],
    [44, Topics.CellPhonePlan],
    [51, Topics.EveryDayLife],
    [22, Topics.Food],
    [47, Topics.MovieTV],
    [49, Topics.Game],
    [55, Topics.Health],
    [52, Topics.Home],
    [37, Topics.Pet],
    [53, Topics.Sports],
    [18, Topics.Law],
    [19, Topics.Visa],
    [27, Topics.Politics],
    [28, Topics.Emotion],
    [45, Topics.Returnee],
    [29, Topics.Love],
    [31, Topics.BlindDate],
    [33, Topics.Career],
    [34, Topics.Job],
    [36, Topics.Job],
    [48, Topics.Education],
    [54, Topics.Education],
    [57, Topics.Art],
    [40, Topics.Covid19],
    [8, Topics.Covid19],
    [39, Topics.Covid19],
    [41, Topics.Covid19],
    [46, Topics.Covid19],
    [59, Topics.Covid19],
    [1, Topics.Gossip],
    [42, Topics.Politics],
  ]);

  SOURCE = 'uscardforum.com';

  ID_PREFIX = 'uscardforum-';

  BASE_URL = 'https://www.uscardforum.com';

  FETCH_INDEX_MOBILE = true;

  getIndexPageUrls(): string[] {
    return [this.BASE_URL];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const data = JSON.parse($('#data-preloaded').attr('data-preloaded') || '{}');
    const postItems: IndexPost[] = [];
    const categoriesList = JSON.parse(data.categories_list);
    categoriesList.category_list.categories.forEach((c:any) => {
      c.topics.forEach((t: any) => postItems.push({ id: `${this.ID_PREFIX}${t.id}` }));
    });
    const topicListLatest = JSON.parse(data.topic_list_latest);
    topicListLatest.topic_list.topics
      .forEach((t: any) => postItems.push({ id: `${this.ID_PREFIX}${t.id}` }));
    return postItems.filter((p) => p.id !== `${this.ID_PREFIX}7`);
  }
}

export const parser = new ParserUsCardForum();
