/* eslint-disable class-methods-use-this */
import {
  PostPage, IndexPost, PostBase, VideoType, Video, Image, Board, PagedPostFeed,
  PagedSearchItemFeed, Topics, TOPIC_NAME_MAP, DEFAULT_TOPIC_NAME,
} from '../types';

const cheerio = require('react-native-cheerio');
const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export abstract class ParserBase {
  SOURCE = '';

  ID_PREFIX = '';

  BASE_URL = '';

  ENCODING = 'utf-8';

  HTTP2 = false;

  FETCH_POST_MOBILE = true;

  FETCH_MORE_POST_ITEM_API = false;

  FETCH_INDEX_MOBILE = false;

  LINEAR_COMMENTS = true;

  // Need to be implemented for forumreader.
  FETCH_HOME_MOBILE = false;

  FETCH_BOARD_MOBILE = false;

  FETCH_BOARD_INDEX_MOBILE = false;

  TITLE = '';

  DOMAIN = '';

  HOME_FEED_SECTIONS: string[] = [];

  HOME_FEED_SECTIONS_HOT: string[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostItemUrl(postId: string, postItemId: string, isMobile: boolean, page: number = 1):
  string {
    throw new Error('Not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCommentJs(postId: string, postItemId?: string): string {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUpVoteJs(postId: string, postItemId?: string): string {
    return '';
  }

  getProfileUrlAndJs(): [string, string] {
    throw new Error('Not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHomeUrl(section: string, page: number): string {
    throw new Error('Not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getBoardUrl(id: string, page: number = 1): string {
    throw new Error('Not implemented.');
  }

  getBoardIndexUrl(): string {
    throw new Error('Not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseHomePage(html: string, url: string, secton: string): PagedPostFeed {
    throw new Error('Not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseBoardPage(html: string, url: string): PagedPostFeed {
    throw new Error('Not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseBoardIndexPage(html: string, url: string): Map<string, Board[]> {
    throw new Error('Not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getBoardId(url: string): string {
    throw new Error('Not implemented.');
  }
  // Need to be implemented for forumreader.

  abstract parseIndexPage(html: string, url: string): IndexPost[];

  abstract parsePostImpl(html: string, url: string): PostPage | string;

  abstract getPostIdAndPage(url: string): [string, number];

  abstract getPostUrl(id: string, page: number, isMobile: boolean): string;

  abstract getIndexPageUrls(): string[];

  parsePost(html: string, url: string): PostPage | string {
    const postPage = this.parsePostImpl(html, url);
    if (typeof postPage === 'string') {
      return postPage;
    }
    postPage.items.forEach((i) => {
      i.images.push(...this.extractImage(i.content));
      i.videos.push(...this.extractVideo(i.content));
    });
    postPage.source = { name: this.SOURCE, title: this.TITLE };
    postPage.topic = {
      id: postPage.topicId,
      name: TOPIC_NAME_MAP.get(postPage.topicId as Topics) || DEFAULT_TOPIC_NAME,
    };
    return postPage;
  }

  extractImage(html: string): Image[] {
    const $ = this.getCheerio(html, '');
    const images = $('img')
      .map((i: number, e: any) => e.attribs.src)
      .get()
      .filter((url: string) => url.startsWith('http') && this.filterImage(url))
      .map((url: string) => ({ url, width: 0, height: 0 }));
    return images;
  }

  extractVideo(html: string): Video[] {
    const $ = this.getCheerio(html, '');
    const videos = extractUrls($.text())
      .map((url) => parseVideoUrl(url))
      .filter((video) => video.type !== VideoType.Unknown);
    return videos;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filterImage(url: string) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  preloadJs(id: string): string {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostApiUrl(id: string): string {
    throw new Error('Not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parsePostApi(response: string): PostPage | string {
    throw new Error('Not implemented.');
  }

  extractText(content: string) {
    const $ = this.getCheerio(content, '');
    return $.text().trim();
  }

  processPostFetchError(id: string, error: any) {
    console.error(`[ERROR] fetch post ${id}: `, error);
  }

  filterPost(post: PostBase) {
    return post.comments > 5;
  }

  static stripIdPrefix(id: string): string {
    const idx = id.indexOf('-');
    return id.substr(idx + 1);
  }

  isUrlMatchPostId(url: string, id: string) {
    return this.getPostIdAndPage(url)[0] === id;
  }

  getCheerio(html: string, url: string) {
    const $ = cheerio.load(html);
    if (!url) {
      // Skip processing html if url is empty.
      return $;
    }

    $('font').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'span';
      element.attribs.style = '';
    });

    const { protocol, host, pathname } = new URL(url);
    const idx = pathname.lastIndexOf('/');
    const parentPath = idx !== -1 ? pathname.substr(0, idx) : '';
    $('a').each((i: number, el: any) => {
      const element = el;
      if (!element.attribs.href) {
        return;
      }
      if (!element.attribs.href.startsWith('http')) {
        if (element.attribs.href.startsWith('//')) {
          element.attribs.href = `${protocol}${element.attribs.href}`;
        } else if (element.attribs.href.startsWith('/')) {
          element.attribs.href = `${protocol}//${host}${element.attribs.href.trim()}`;
        } else if (element.attribs.href.startsWith('./')) {
          element.attribs.href = `${protocol}//${host}${parentPath}/${element.attribs.href.substr(2).trim()}`;
        } else {
          element.attribs.href = `${protocol}//${host}${parentPath}/${element.attribs.href.trim()}`;
        }
      } else {
        element.attribs.href = element.attribs.href.trim();
      }
    });
    $('img').each((i: number, el: any) => {
      const element = el;
      if (!element.attribs.src) {
        if (element.attribs['data-src']) {
          element.attribs.src = element.attribs['data-src'];
        } else {
          return;
        }
      }
      if (element.attribs.src.startsWith('data:')) {
        return;
      }
      if (!element.attribs.src.startsWith('http')) {
        if (element.attribs.src.startsWith('//')) {
          element.attribs.src = `${protocol}${element.attribs.src}`;
        } else if (element.attribs.src.startsWith('/')) {
          element.attribs.src = `${protocol}//${host}${element.attribs.src.trim()}`;
        } else if (element.attribs.src.startsWith('./')) {
          element.attribs.src = `${protocol}//${host}${parentPath}/${element.attribs.src.substr(2).trim()}`;
        } else {
          element.attribs.src = `${protocol}//${host}${parentPath}/${element.attribs.src.trim()}`;
        }
      } else {
        element.attribs.src = element.attribs.src.trim();
      }
    });
    $('source').each((i: number, el: any) => {
      const element = el;
      const { src } = element.attribs;
      if (!src) {
        return;
      }
      if (element.attribs.src.startsWith('data:')) {
        return;
      }
      if (!element.attribs.src.startsWith('http')) {
        if (element.attribs.src.startsWith('//')) {
          element.attribs.src = `${protocol}${element.attribs.src}`;
        } else if (element.attribs.src.startsWith('/')) {
          element.attribs.src = `${protocol}//${host}${element.attribs.src.trim()}`;
        } else if (element.attribs.src.startsWith('./')) {
          element.attribs.src = `${protocol}//${host}${parentPath}/${element.attribs.src.substr(2).trim()}`;
        } else {
          element.attribs.src = `${protocol}//${host}${parentPath}/${element.attribs.src.trim()}`;
        }
      } else {
        element.attribs.src = element.attribs.src.trim();
      }
    });
    $('iframe').each((i: number, el: any) => {
      const element = el;
      const { src } = element.attribs;
      if (!src) {
        return;
      }
      if (element.attribs.src.startsWith('data:')) {
        return;
      }
      if (!element.attribs.src.startsWith('http')) {
        if (element.attribs.src.startsWith('//')) {
          element.attribs.src = `${protocol}${element.attribs.src}`;
        } else if (element.attribs.src.startsWith('/')) {
          element.attribs.src = `${protocol}//${host}${element.attribs.src.trim()}`;
        } else if (element.attribs.src.startsWith('./')) {
          element.attribs.src = `${protocol}//${host}${parentPath}/${element.attribs.src.substr(2).trim()}`;
        } else {
          element.attribs.src = `${protocol}//${host}${parentPath}/${element.attribs.src.trim()}`;
        }
      } else {
        element.attribs.src = element.attribs.src.trim();
      }
    });
    return $;
  }

  parseSearchPost(html: string, url: string): PagedSearchItemFeed {
    const $ = this.getCheerio(html, url);
    const items = $('#search .g')
      .map((i: number, el: any) => {
        const title = $('h3', el).text().trim();
        const url = $($('h3', el).parent()).attr('href');
        const id = this.getPostIdAndPage(url)[0];
        const content = $('div[data-content-feature="1"]', el).html().trim();
        return {
          id,
          title,
          url,
          content,
        };
      })
      .get()
      .filter((p: any) => !!p.id);
    const hasMore = $('div[role="navigation"] tr td:last-child a').text().trim() === 'Next';
    return { items, hasMore };
  }

  static parseTime(timeString: string, format: string, timeZone: string) {
    if (format) {
      return dayjs.tz(timeString, format, timeZone).valueOf();
    }
    return dayjs.tz(timeString, timeZone).valueOf();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  applyExtraData(post: PostBase, extraData: any) {
    return post;
  }
}

export function extractUrls(text: string): string[] {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  const matches = text.matchAll(urlRegex);
  return Array.from(matches).map((m) => m[0]);
}

export function parseVideoUrl(url: string): Video {
  const urlProcessed = url.replace('www.youtube-nocookie.com', 'www.youtube.com');

  // Parse YouTube video.
  const youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const youtubeMatch = urlProcessed.match(youtubeRegExp);
  const youtubeVideoId = (youtubeMatch && youtubeMatch[7].length === 11) ? youtubeMatch[7] : '';

  // Parse Twitter video.
  const twitterRegExp = /^.*twitter\.com\/.*\/(\d+)$/;
  const twitterMatch = urlProcessed.match(twitterRegExp);
  const twitterVideoId = (twitterMatch && twitterMatch[1].length > 0) ? twitterMatch[1] : '';

  if (youtubeVideoId) {
    return {
      type: VideoType.YouTube,
      id: youtubeVideoId,
      url: urlProcessed,
    };
  } if (twitterVideoId) {
    return {
      type: VideoType.Twitter,
      id: twitterVideoId,
      url: urlProcessed,
    };
  }
  return {
    type: VideoType.Unknown,
    id: '',
    url: urlProcessed,
  };
}

export function parseFreeTimeString(timeString: string): number {
  const now = new Date().getTime();
  const today = new Date();
  if (timeString.includes('半小时前')) {
    return now - 1800 * 1000;
  }
  if (timeString.includes('半分钟前')) {
    return now - 30 * 1000;
  }
  if (timeString.includes('月前')) {
    return now - parseInt(timeString, 10) * 30 * 86400 * 1000;
  }
  if (timeString.includes('周前')) {
    return now - parseInt(timeString, 10) * 7 * 86400 * 1000;
  }
  if (timeString.includes('天前')) {
    return now - parseInt(timeString, 10) * 86400 * 1000;
  }
  if (timeString.includes('小时前')) {
    return now - parseInt(timeString, 10) * 3600 * 1000;
  }
  if (timeString.includes('分钟前')) {
    return now - parseInt(timeString, 10) * 60 * 1000;
  }
  if (timeString.includes('秒前')) {
    return now - parseInt(timeString, 10) * 1000;
  }
  if (timeString.includes('刚刚')) {
    return now - 10 * 1000;
  }
  if (timeString.includes('昨天')) {
    today.setDate(today.getDate() - 1);
    const [hour, min] = timeString.substr(timeString.indexOf('天') + 1).split(':');
    today.setHours(parseInt(hour, 10) || 0, parseInt(min, 10) || 0);
    return today.getTime();
  }
  if (timeString.includes('前天')) {
    today.setDate(today.getDate() - 2);
    const [hour, min] = timeString.substr(timeString.indexOf('天') + 1).split(':');
    today.setHours(parseInt(hour, 10) || 0, parseInt(min, 10) || 0);
    return today.getTime();
  }
  return 0;
}
