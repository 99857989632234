import * as React from 'react';
import {
  StyleSheet, FlatList, Platform,
} from 'react-native';
import {
  ListItem, Divider, Button,
} from 'react-native-elements';
import { observer } from 'mobx-react-lite';

import { User } from '@topicfeed/common/types';
import { Avatar, View, SearchBar } from '@topicfeed/appcommon/components';
import { useBlockUserMutation } from '@topicfeed/appcommon/hooks/mutations';
import { useBlockedUsersQuery, useUserQuery } from '@topicfeed/appcommon/hooks/queries';
import { alert } from '@topicfeed/appcommon/utils/polyfill';

type ItemProps = {
  userData: User;
};

const Item = ({ userData }: ItemProps) => {
  const blockUserMutation = useBlockUserMutation(userData.id, '');
  const { data: user } = useUserQuery(userData.id, true, userData);
  if (!user?.blocked) {
    return null;
  }
  return (
    <ListItem
      key={user.id}
      bottomDivider
    >
      <Avatar
        imageSrc={user.profileImage}
        name={user.username}
        size={25}
        rounded
      />
      <ListItem.Content>
        <ListItem.Title style={styles.itemTitle} numberOfLines={1}>
          {user.username}
          @
          {user.sourceName}
        </ListItem.Title>
      </ListItem.Content>
      <Button
        title="Unblock"
        buttonStyle={styles.followButton}
        titleStyle={styles.followButtonText}
        onPress={() => {
          alert(
            'Do you want to unblock this user?',
            user ? `${user.username} ${user.sourceName ? `from ${user.sourceName}` : ''} ` : '',
            [
              { text: 'Cancel', style: 'cancel' },
              {
                text: 'OK',
                onPress: () => {
                  blockUserMutation.mutate({ id: user.id, postId: '', block: false });
                },
              },
            ],
            { cancelable: true },
          );
        }}
        loading={blockUserMutation.isLoading}
      />
    </ListItem>
  );
};

const BlockedUsersScreen = observer(() => {
  const [searchText, setSearchText] = React.useState('');
  const { data, isLoading, refetch } = useBlockedUsersQuery();

  const onClear = () => {
    setSearchText('');
  };

  const users = data
    ? data.filter((t: User) => !searchText || t.username.includes(searchText))
    : [];

  return (
    <View style={styles.container}>
      <SearchBar
        containerStyle={styles.searchBar}
        platform={Platform.OS === 'android' ? 'android' : 'ios'}
        placeholder="Search"
        value={searchText}
        onChangeText={(text) => setSearchText(text)}
        onClear={onClear}
        returnKeyType="search"
      />
      <Divider style={styles.divider} />
      <FlatList<User>
        style={styles.list}
        data={users}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <Item userData={item} />
        )}
        refreshing={isLoading}
        onRefresh={() => refetch()}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  searchBar: {
    width: '100%',
    paddingHorizontal: 5,
  },
  list: {
    flex: 1,
    width: '100%',
  },
  divider: {
    height: 2,
  },
  followButton: {
    paddingVertical: 0,
    height: 32,
    width: 100,
    borderWidth: 2,
    borderRadius: 15,
  },
  followButtonText: {
    fontSize: 14,
  },
  itemTitle: {
    fontSize: 18,
  },
});

export default BlockedUsersScreen;
