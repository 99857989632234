const primary = '#3D85C6';
const tintColorLight = '#3D85C6';
const tintColorDark = '#3D85C6';

export default {
  light: {
    text: '#242424',
    background: '#ffffff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    primary,
    card: '#ffffff',
    border: '#e1e8ee',
  },
  dark: {
    text: '#f2f2f2',
    background: '#080808',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    primary,
    card: '#080808',
    border: '#393e42',
  },
};
