import 'react-native-url-polyfill/auto';
import { gql, GraphQLClient } from 'graphql-request';
import {
  Post, User, Topic, Source, TopicSource, PostViewPosition,
} from '@topicfeed/common/types';
import * as auth from './auth';

const endpoint = 'https://api.topicfeed.app/graphql';

const client = new GraphQLClient(endpoint);

const axios = require('axios').default;

const POST_FIELDS = `
    id
    title
    text
    mUrl
    timeCreated
    timePublished
    timeSaved
    timeViewed
    user {
      id
      username
      profileImage
      sourceName
      blocked
    }
    topic {
      id
      name
    }
    source0 {
      name
      blocked
    }
    topicSource {
      topic {
        id
        name
      }
      source {
        name
      }
      blocked
    }
    comments
    following
    saved
    hidden
    reported
    hideImage
    images {
      url
      width
      height
    }
    videos {
      url
      type
      id
    }
    viewPosition {
      id
      mUrl
    }
`;

export function getRedirectUrl(url: string, postId: string) {
  return `https://topicfeed.app/r?url=${encodeURIComponent(url)}&pid=${postId}`;
}

export async function resolveUrl(url: string, postId: string) {
  const token = await auth.getIdToken();
  const resp = await axios.get(
    getRedirectUrl(url, postId),
    { headers: { authorization: `Bearer ${token}` } },
  );
  return resp.request.responseURL;
}

async function getClient() {
  const token = await auth.getIdToken();
  client.setHeader('authorization', `Bearer ${token}`);
  return client;
}

export async function getUser(id: string): Promise<User> {
  const client = await getClient();
  const response = await client.request(gql`
      query getUser($id: String!) {
        user(id: $id) {
          id
          username
          profileImage
          sourceName
          blocked
        }
      }`, { id });
  return response.user;
}

export async function getPost(id: string): Promise<Post> {
  const client = await getClient();
  const response = await client.request(gql`
      query getPost($id: String!) {
        post(id: $id) {
          ${POST_FIELDS}
        }
      }
  `, { id });
  return response.post;
}

export async function getPostViewPosition(id: string): Promise<PostViewPosition> {
  const client = await getClient();
  const response = await client.request(gql`
      query getPostViewPosition($id: String!) {
        postViewPosition(id: $id) {
          id
          url
          mUrl
        }
      }
  `, { id });
  return response.postViewPosition;
}

export async function getPostShortUrl(id: string, appName: string): Promise<string> {
  const client = await getClient();
  const response = await client.request(gql`
      query getPostShortUrl($id: String!, $appName: String) {
        postShortUrl(id: $id, appName: $appName)
      }
  `, { id, appName });
  return response.postShortUrl;
}

export enum FeedType {
  Personal = 1,
  Hot = 2,
}

export async function getFeed(type: FeedType, after?: number, before?: number) {
  const client = await getClient();
  const limit = (type === FeedType.Personal) ? 20 : 100;
  const response = await client.request(gql`
      query getFeed($before: Float, $after: Float, $type: Int) {
        feed(limit: ${limit}, before: $before, after: $after, type: $type) {
          items {
            ${POST_FIELDS}
          }
          hasMore
        }
      }`, { after, before, type });
  return response.feed;
}

export async function getSavedPosts(after?: number, before?: number) {
  const client = await getClient();
  const response = await client.request(gql`
      query getSavedPosts($before: Float, $after: Float) {
        savedPosts(limit: 20, before: $before, after: $after) {
          items {
            ${POST_FIELDS}
          }
          hasMore
        }
      }`, { after, before });
  return response.savedPosts;
}

export async function getViewedPosts(after?: number, before?: number) {
  const client = await getClient();
  const response = await client.request(gql`
      query getViewedPosts($before: Float, $after: Float) {
        viewedPosts(limit: 20, before: $before, after: $after) {
          items {
            ${POST_FIELDS}
          }
          hasMore
        }
      }`, { after, before });
  return response.viewedPosts;
}

export async function getTopicPosts(topicId: string, after?: number, before?: number) {
  const client = await getClient();
  const response = await client.request(gql`
      query posts($topicId: String!, $before: Float, $after: Float) {
        posts(topicIds: [$topicId], limit: 20, before: $before, after: $after) {
          items {
            ${POST_FIELDS}
          }
          hasMore
        }
      }`, { topicId, after, before });
  return response.posts;
}

export async function getTopic(id: string): Promise<Topic> {
  const client = await getClient();
  const response = await client.request(gql`
      query getTopic($id: String!) {
        topic(id: $id) {
          id
          name
          following
        }
      }
  `, { id });
  return response.topic;
}

export async function getSource(name: string): Promise<Source> {
  const client = await getClient();
  const response = await client.request(gql`
      query getSource($name: String!) {
        source(name: $name) {
          name
          blocked
        }
      }
  `, { name });
  return response.source;
}

export async function getTopicSource(topicId: string, sourceName: string): Promise<TopicSource> {
  const client = await getClient();
  const response = await client.request(gql`
      query getTopicSource($topicId: String!, $sourceName: String!) {
        topicSource(topicId: $topicId, sourceName: $sourceName) {
          topic {
            id
            name
          }
          source {
            name
          }
          blocked
        }
      }
  `, { topicId, sourceName });
  return response.topicSource;
}

export async function getTopics(): Promise<Topic[]> {
  const client = await getClient();
  const response = await client.request(gql`
    query {
      topics {
        id
        name
        following
      }
    }`);
  return response.topics;
}

export async function getBlockedUsers() {
  const client = await getClient();
  const response = await client.request(gql`
    query {
      blockedUsers {
        id
        username
        profileImage
        sourceName
        blocked
      }
    }`);
  return response.blockedUsers;
}

export async function getBlockedTopicSources(): Promise<TopicSource[]> {
  const client = await getClient();
  const response = await client.request(gql`
    query {
      blockedTopicSources {
        topic {
          id
          name
        }
        source {
          name
        }
        blocked
      }
    }`);
  return response.blockedTopicSources;
}

export async function getNotifications(after?: number, before?: number) {
  const client = await getClient();
  const response = await client.request(gql`
      query getNotifications($before: Float, $after: Float) {
        notifications(limit: 100, before: $before, after: $after) {
          items {
            id
            read
            type
            timeCreated
            detailJson
          }
          hasMore
        }
      }`, { after, before });
  return response.notifications;
}

export async function followTopic(id: string, follow: boolean) {
  const client = await getClient();
  const response = await client.request(gql`
    mutation followTopic($id: String!, $follow: Boolean!) {
      followTopic(id: $id, follow: $follow) 
    }`, { id, follow });
  return response.followTopic;
}

export async function blockTopicSource(topicId: string, sourceName: string, block: boolean) {
  const client = await getClient();
  const response = await client.request(gql`
    mutation blockTopicSource($topicId: String!, $sourceName: String!, $block: Boolean!) {
      blockTopicSource(topicId: $topicId, sourceName: $sourceName, block: $block) 
    }`, { topicId, sourceName, block });
  return response.blockTopicSource;
}

export async function followPost(id: string, follow: boolean) {
  const client = await getClient();
  const response = await client.request(gql`
    mutation followPost($id: String!, $follow: Boolean!) {
      followPost(id: $id, follow: $follow) 
    }`, { id, follow });
  return response.followPost;
}

export async function savePost(id: string, save: boolean) {
  const client = await getClient();
  const response = await client.request(gql`
    mutation savePost($id: String!, $save: Boolean!) {
      savePost(id: $id, save: $save) 
    }`, { id, save });
  return response.savePost;
}

export async function hidePost(id: string, hide: boolean) {
  const client = await getClient();
  const response = await client.request(gql`
    mutation hidePost($id: String!, $hide: Boolean!) {
      hidePost(id: $id, hide: $hide) 
    }`, { id, hide });
  return response.hidePost;
}

export async function reportPost(id: string, reasonCode: number, reason: string) {
  const client = await getClient();
  const response = await client.request(gql`
    mutation reportPost($id: String!, $reasonCode: Int!, $reason: String) {
      reportPost(id: $id, reasonCode: $reasonCode, reason: $reason)
    }`, { id, reasonCode, reason });
  return response.resportPost;
}

export async function blockUser(id: string, block: boolean) {
  const client = await getClient();
  const response = await client.request(gql`
    mutation blockUser($id: String!, $block: Boolean!) {
      blockUser(id: $id, block: $block) 
    }`, { id, block });
  return response.blockUser;
}

export async function getPostReportReasons() {
  const client = await getClient();
  const response = await client.request(gql`
    query {
      postReportReasons {
        reasonCode
        reasonString
      }
    }`);
  return response.postReportReasons;
}

export async function markNotificationRead(id: string) {
  console.log('Mark notification read: ', id);
  const client = await getClient();
  const response = await client.request(gql`
    mutation markNotificationRead($id: String!) {
      markNotificationRead(id: $id) 
    }`, { id });
  return response.markNotificationRead;
}

export async function savePostViewPosition(id: string, url?: string, mUrl?: string,
  comments?: number) {
  console.log('Save post view position: ', id, url, mUrl, comments);
  const client = await getClient();
  const response = await client.request(gql`
    mutation savePostViewPosition($id: String!, $url: String, $mUrl: String, $comments: Float) {
      savePostViewPosition(id: $id, url: $url, mUrl: $mUrl, comments: $comments) 
    }`, {
    id, url, mUrl, comments,
  });
  return response.savePostViewPosition;
}
