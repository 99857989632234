import React from 'react';
import { Platform } from 'react-native';
import firebase from 'firebase/app';

import AppTemplate from '@topicfeed/appcommon/AppTemplate';
import { registerTasks } from './utils/task';
import RootNavigator from './navigation';
import LinkingConfiguration from './navigation/LinkingConfiguration';
import Colors from './constants/Colors';

const VERSION = '1.7.0';

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyBNXn-4tVOxbVC6XLjg-xlNavxITJTpSj0',
  authDomain: 'topicfeed-drawerapp.firebaseapp.com',
  databaseURL: 'https://topicfeed-drawerapp.firebaseio.com',
  projectId: 'topicfeed-drawerapp',
  storageBucket: 'topicfeed-drawerapp.appspot.com',
  messagingSenderId: '219364402152',
  appId: '1:219364402152:web:8bf7bd78b257fb83247a5d',
  measurementId: 'G-Z8HPH1MGPE',
};

if (!firebase.apps.length) { firebase.initializeApp(firebaseConfig); }

if (Platform.OS !== 'web') {
  registerTasks();
}

export default function App() {
  return (
    <AppTemplate
      rootNavigator={<RootNavigator version={VERSION}/>}
      colors={Colors}
      linkingConfiguration={LinkingConfiguration}
      name='topicfeed'
      version={VERSION}
    />
  );
}
