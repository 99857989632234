/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, User, VideoType, Topics, PostBase,
} from '../types';
import { ParserBase } from './parserBase';

export abstract class ParserDiscourse extends ParserBase {
  BOARD_TO_TOPICS_MAP: Map<number, string> = new Map([]);

  SOURCE = '';

  ID_PREFIX = '';

  BASE_URL = '';

  FETCH_MORE_POST_ITEM_API = true;

  getIndexPageUrls(): string[] {
    return [this.BASE_URL];
  }

  extractText(content: string) {
    const $ = this.getCheerio(content, '');
    $('img').remove();
    return $.text().trim();
  }

  filterPost(post: PostBase) {
    return post.comments > 3;
  }

  preloadJs(id: string) {
    return `
    var oldXHROpen = window.XMLHttpRequest.prototype.open;
    window.XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
      this.addEventListener('load', function() {
        if (this.responseURL.startsWith('${this.BASE_URL}/t/') && this.responseURL.includes('/posts.json')) {
          var data = JSON.parse(this.responseText);
          var preloadElement = document.getElementById('data-preloaded');
          var preload = preloadElement ? JSON.parse(preloadElement.getAttribute('data-preloaded') || '{}') : {};
          var post = JSON.parse(preload.topic_${ParserBase.stripIdPrefix(id)} || '{}');
          data.title = post.title;
          data.posts_count = post.posts_count;
          data.category_id = post.category_id;
          data.last_posted_at = post.last_posted_at;
          data.highest_post_number = post.highest_post_number;
          ReactNativeWebView.postMessage(JSON.stringify({
            event: 'api', data: JSON.stringify(data) }));
        }
      });
      return oldXHROpen.apply(this, arguments);
    }`;
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('.page-not-found')[0]) {
      return $('.page-not-found h1').text().trim();
    }
    const id = this.getPostIdAndPage(url)[0];

    const data = JSON.parse($('#data-preloaded').attr('data-preloaded') || '{}');
    return this.parsePostApi(data[`topic_${ParserBase.stripIdPrefix(id)}`]);
  }

  parsePostApi(response: string): PostPage | string {
    const data = JSON.parse(response);
    const currentPage = 1;
    const totalPage = 1;
    const items: PostItem[] = [];

    data.post_stream.posts.forEach((p: any) => {
      const id = p.post_number.toString();
      const $ = this.getCheerio(p.cooked, this.getPostUrl(`${this.ID_PREFIX}${data.id}`, 1));
      const timePublished = ParserBase.parseTime(p.created_at, '', 'UTC');
      const { username } = p;
      const profileImage = `${this.BASE_URL}${p.avatar_template.replace('{size}', '48')}`;
      const user: User = {
        id: this.ID_PREFIX + username,
        username,
        profileImage,
        sourceName: this.SOURCE,
        blocked: false,
      };

      // const images = (p.link_counts || [])
      //   .map((l:any) => {
      //     if (l.url.startsWith(`${this.BASE_URL}/uploads/`)) {
      //       return l.url;
      //     }
      //     return '';
      //   })
      //   .filter((url: string) => !!url)
      //   .map((url: string) => ({ url, width: 0, height: 0 }));

      const videos = $('video').map((i: number, el: any) => {
        const url = $('source', el).attr('src') || '';
        const filename = url.split('/')[url.split('/').length - 1];
        return {
          type: VideoType.HTML5,
          id: filename,
          url,
          content: `<video width="100%" height="100%" preload="metadata" controls>${$(el).html()}</video>`,
        };
      }).get();

      $('.lightbox').each((i: number, el: any) => {
        const element = el;
        element.tagName = 'div';
      });
      $('.lightbox-wrapper .meta').remove();
      $('video').remove();
      const content = $.html();

      items.push({
        id,
        content,
        user,
        upVotes: p.actions_summary[0]?.count || 0,
        downVotes: 0,
        timePublished,
        images: [],
        videos,
      });
    });

    const { title } = data;
    const comments = data.posts_count - 1;
    const boardId = data.category_id;
    const topicId = this.BOARD_TO_TOPICS_MAP.get(boardId) || Topics.Other;
    const timeLastComment = ParserBase.parseTime(data.last_posted_at, '', 'UTC');
    const lastPostNumber = data.post_stream.posts[data.post_stream.posts.length - 1].post_number;
    const firstPostNumber = data.post_stream.posts[0].post_number;
    const hasNext = lastPostNumber < data.highest_post_number;
    const hasPrevious = firstPostNumber > 1;
    return {
      currentPage,
      totalPage,
      items,
      title,
      comments,
      topicId,
      timeLastComment,
      hasNext,
      hasPrevious,
    };
  }

  filterImage(url: string) {
    return url.startsWith(`${this.BASE_URL}/uploads/`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    return `${this.BASE_URL}/t/topic/${ParserBase.stripIdPrefix(id)}`;
  }

  getPostApiUrl(id: string): string {
    return `${this.BASE_URL}/t/${ParserBase.stripIdPrefix(id)}.json`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname: baseHostname } = new URL(this.BASE_URL);
    const { hostname, pathname } = new URL(url);
    if (hostname === baseHostname && pathname.startsWith('/t/topic/')) {
      const id = pathname.split('/')[3];
      return [`${this.ID_PREFIX}${id}`, 1];
    }
    return ['', 0];
  }
}
