/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, User, IndexPost, Topics,
} from '../types';
import { ParserBase } from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['enter1', Topics.Joke],
  ['enter8', Topics.Celebrity],
  ['netstar', Topics.Play],
  ['enter4', Topics.Music],
  ['chan7', Topics.Music],

  ['chan6', Topics.Electronics],
  ['chan8', Topics.Photography],
  ['life6', Topics.Food],
  ['life8', Topics.Pet],
  ['life7', Topics.Travel],

  ['enter5', Topics.Sports],
  ['sport', Topics.Fitness],
  ['enter3', Topics.Game],
  ['enter6', Topics.Manga],
  ['gz3', Topics.Fortune],

  ['finance', Topics.BusinessNews],
  ['chan4', Topics.Investment],
  ['enter7', Topics.Car],
  ['know1', Topics.Computer],
  ['chan2', Topics.TechNews],

  ['life9', Topics.Marriage],
  ['life3', Topics.Love],
  ['life1', Topics.Fashion],
  ['chan10', Topics.Women],
  ['chan5', Topics.Parenting],

  ['military', Topics.Military],
  ['nz', Topics.Military],
  ['pk', Topics.Gossip],
  ['other', Topics.Gossip],
  ['bolun', Topics.Gossip],

  ['chan1', Topics.History],

  ['newspark', Topics.WorldNews],

  ['chan3', Topics.Family],
  ['life4', Topics.Education],

  ['life5', Topics.Health],
  ['ghost', Topics.Ghost],

  // bbsid -> topic id.
  ['2103', Topics.Electronics],
  ['2080', Topics.ChinaNews],

  ['2101', Topics.Music],
  ['2065', Topics.ChinaNews],
  ['2032', Topics.Manga],
  ['2024', Topics.Electronics],
  ['2017', Topics.Family],

  ['2076', Topics.Military],
  ['2016', Topics.Politics],

  ['2115', Topics.Investment],
  ['2087', Topics.Family],
  ['2000', Topics.Joke],

  ['2094', Topics.WorldNews],
  ['2086', Topics.Health],
  ['2021', Topics.Basketball],

  ['2111', Topics.Joke],
  ['2093', Topics.Gun],
  ['2027', Topics.History],
  ['2020', Topics.Home],

  // More mapping for mobile site.
  ['17', Topics.Joke],
  ['6', Topics.History],
  ['43', Topics.Military],
  ['42', Topics.Marriage],
  ['24', Topics.BusinessNews],
  ['23', Topics.Celebrity],
  ['20', Topics.Sports],
  ['22', Topics.Car],
  ['12', Topics.Electronics],
  ['39', Topics.Food],
  ['33', Topics.Fashion],
  ['7', Topics.Women],
  ['51', Topics.Fitness],
  ['18', Topics.Game],
  ['10', Topics.Investment],
  ['36', Topics.Love],
  ['8', Topics.TechNews],
  ['49', Topics.Gossip],
  ['31', Topics.Computer],
  ['14', Topics.Photography],
  ['13', Topics.Music],
  ['47', Topics.Gossip],
  ['46', Topics.Military],
  ['4', Topics.Gossip],
  ['9', Topics.Family],
  ['44', Topics.Play],
  ['40', Topics.Travel],
  ['11', Topics.Parenting],
  ['41', Topics.Pet],
  ['29', Topics.Fortune],
  ['21', Topics.Manga],
  ['19', Topics.Music],
]);

class Parser6Park extends ParserBase {
  SOURCE = '6park.com';

  ID_PREFIX = '6park-';

  BASE_URL = 'https://www.6parkbbs.com';

  FETCH_INDEX_MOBILE = true;

  FETCH_MORE_POST_ITEM_API = true;

  LINEAR_COMMENTS = false;

  getIndexPageUrls(): string[] {
    return [
      `${this.BASE_URL}/index.php?app=index&act=newslist`,
      `${this.BASE_URL}/index.php?app=index&act=bbshome&ajax=1`,
    ];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseIndexPage(html: string, url: string): IndexPost[] {
    const result = JSON.parse(html);
    const postItems = result.threadlist.map((l: any) => ({
      id: `${this.ID_PREFIX}${l.cid}`,
    }));
    return postItems;
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('.alert-box .alertMsg')[0]) {
      return $('.alert-box .alertMsg').text().trim();
    }
    const currentPage = this.getPostIdAndPage(url)[1];
    const totalPage = 1;

    $('center').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });

    $('img').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });

    $('.comment4c').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
    });
    $('.OUTBRAIN').remove();

    const usernameMain = $('.author').text();
    const userMain: User = {
      id: this.ID_PREFIX + usernameMain.replace('/', '_'),
      username: usernameMain,
      profileImage: $('.headPic img').attr('src'),
      sourceName: this.SOURCE,
      blocked: false,
    };
    const timePublishedStringMain = $($('.time')[0]).text();
    const timePublishedMain = ParserBase.parseTime(timePublishedStringMain, 'YYYY-MM-DD', 'America/New_York');

    const mainItem: PostItem = {
      id: '1',
      content: `<div>${$('.article-content').html()}</div>`,
      upVotes: parseInt($('.list-li .content_like').text(), 10) || 0,
      downVotes: 0,
      user: userMain,
      timePublished: timePublishedMain,
      images: [],
      videos: [],
    };

    const items: PostItem[] = $('.comment-list')
      .map((i: number, el: any) => {
        const id = parseInt($('.namer', el).text(), 10) + 1;
        const content = `<div>${$('.con', el).html()}</div>`;
        const timePublishedString = $('.other .dataTime', el).text();
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-MM-DD H:mm', 'America/New_York');
        const username = $('.info .namel', el).text().trim();
        const user: User = {
          id: this.ID_PREFIX + username,
          username,
          profileImage: $('.headPic img', el).attr('src'),
          sourceName: this.SOURCE,
          blocked: false,
        };
        const postItem: PostItem = {
          id: id.toString(),
          content,
          user,
          upVotes: parseInt($('.commentZan .doGood', el).text(), 10) || 0,
          downVotes: 0,
          timePublished,
          images: [],
          videos: [],
        };
        return postItem;
      })
      .get();

    const title = $('.article-tit').text().trim();
    const comments = parseInt($('.footer-nav .ft_words_num').text(), 10) || items.length;
    const boardUrl = $('.article-favor a.favorBtn').attr('href');
    const { searchParams } = new URL(boardUrl);
    const boardId = searchParams.get('bbsid') || '';
    const topicId = BOARD_TO_TOPICS_MAP.get(boardId) || Topics.Other;

    return {
      currentPage,
      totalPage,
      items: [mainItem].concat(items),
      title,
      comments,
      topicId,
      hasPrevious: false,
      hasNext: items.length < comments,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    const sideId = ParserBase.stripIdPrefix(id);
    const [site, boardId, postId] = sideId.split('_');
    if (site === 'c') {
      return `https://club.6parkbbs.com/${boardId}/index.php?app=forum&act=threadview&tid=${postId}`;
    }
    if (site === 'w') {
      return `https://web.6parkbbs.com/index.php?act=view&bbsid=${boardId}&tid=${postId}`;
    }
    return `${this.BASE_URL}/index.php?app=index&act=view&cid=${sideId}`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname, searchParams } = new URL(url);
    if (hostname === 'www.6parkbbs.com') {
      if (pathname.startsWith('/index.php')
      && searchParams.get('act') === 'view'
      && searchParams.get('app') === 'index'
      && searchParams.get('cid')) {
        return [`${this.ID_PREFIX + searchParams.get('cid')}`, 1];
      }
    }
    if (hostname === 'club.6parkbbs.com') {
      if (pathname.endsWith('index.php') && searchParams.get('act') === 'threadview' && searchParams.get('app') === 'forum') {
        const tokens = pathname.split('/');
        if (tokens.length > 1 && searchParams.get('tid')) {
          const board = tokens[1];
          return [`${this.ID_PREFIX}c_${board}_${searchParams.get('tid')}`, 1];
        }
      }
    }
    if (hostname === 'web.6parkbbs.com') {
      if (pathname === '/index.php' && searchParams.get('act') === 'view' && searchParams.get('bbsid') && searchParams.get('tid')) {
        return [`${this.ID_PREFIX}w_${searchParams.get('bbsid')}_${searchParams.get('tid')}`, 1];
      }
    }
    return ['', 0];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isUrlMatchPostId(url: string, id: string) {
    const { hostname, pathname, searchParams } = new URL(url);
    if (hostname === 'www.6parkbbs.com') {
      if (pathname.startsWith('/index.php')
      && searchParams.get('act') === 'view'
      && searchParams.get('app') === 'index'
      && searchParams.get('cid')) {
        return true;
      }
    }
    return false;
  }

  preloadJs(id: string) {
    return `
    var oldXHROpen = window.XMLHttpRequest.prototype.open;
    window.XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
      this.addEventListener('load', function() {
        var parsedURL = new URL(this.responseURL);
        if (this.responseURL.startsWith('${this.BASE_URL}/index.php')
            && parsedURL.searchParams.get('act') === 'replylist'
            && parsedURL.searchParams.get('cid') === '${ParserBase.stripIdPrefix(id)}') {
          var data = this.responseText;
          ReactNativeWebView.postMessage(JSON.stringify({
            event: 'api', data: this.responseText }));
        }
      });
      return oldXHROpen.apply(this, arguments);
    }`;
  }

  parsePostApi(response: string): PostPage | string {
    const data = JSON.parse(response);
    const currentPage = 1;
    const totalPage = 1;
    const items: PostItem[] = [];

    data.replylist?.forEach((p: any) => {
      const id = p.floor.toString();
      const content = p.r_content;
      const upVotes = parseInt(p.likes, 10) || 0;
      const timePublished = ParserBase.parseTime(p.dateline, 'YYYY-MM-DD H:mm:ss', 'America/New_York');
      const { username, uid } = p;
      const profileImage = `${this.BASE_URL}/index.php?act=showface&uid=${uid}`;
      const user: User = {
        id: this.ID_PREFIX + username,
        username,
        profileImage,
        sourceName: this.SOURCE,
        blocked: false,
      };

      items.push({
        id,
        content,
        user,
        upVotes,
        downVotes: 0,
        timePublished,
        images: [],
        videos: [],
      });
    });

    const hasNext = items.length > 0;
    const hasPrevious = false;
    return {
      currentPage,
      totalPage,
      items,
      title: '',
      comments: 0,
      topicId: '',
      hasNext,
      hasPrevious,
    };
  }
}

export const parser = new Parser6Park();
