import * as React from 'react';
import {
  StyleSheet, Image, Platform, ActivityIndicator,
} from 'react-native';
import { observer } from 'mobx-react-lite';
import { useIsFocused, useTheme } from '@react-navigation/native';
import { Icon, Button, Badge } from 'react-native-elements';
import { TabView, TabBar, Route } from 'react-native-tab-view';

import { Post } from '@topicfeed/common/types';
import {
  View, PostPreview, TimelineFeed, TimelineFeedRef, Text,
} from '@topicfeed/appcommon/components';
import { useStores, PostPreviewStyle } from '@topicfeed/appcommon/models';
import { getFeed, FeedType } from '@topicfeed/appcommon/utils/api';
import { checkSignedIn } from '@topicfeed/appcommon/utils/auth';
import { useDimension } from '@topicfeed/appcommon/context';
import { useTopicsQuery } from '@topicfeed/appcommon/hooks/queries';
import { RootTabScreenProps } from '../types';

const logo = require('../assets/images/icon_logo.png');

const TAB_KEY_HOT = 'hot';
const TAB_KEY_PERSONAL = 'personal';

type FeedTabProps = {
  navigation: any,
  isSimplePreview: boolean,
  usePostReaderView: boolean,
  hasNewFeed: boolean,
  isEmpty?: boolean,
  onRefresh: () => void;
};

const PersonalFeed = React.memo(React.forwardRef(({
  // eslint-disable-next-line react/prop-types
  navigation, isEmpty, isSimplePreview, usePostReaderView, hasNewFeed, onRefresh,
}: FeedTabProps, ref: any) => {
  if (isEmpty) {
    return (
      <View style={styles.empty}>
        <Text style={styles.emptyTitle}>No topics followed</Text>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 15 }}>
          <Text style={styles.emptySubTitle}>Click </Text>
          <Icon
            type="material-community"
            name="view-grid-outline"
            color="grey"
            tvParallaxProperties
          />
           <Text style={styles.emptySubTitle}> to find topics to follow </Text>
        </View>
      </View>
    );
  }
  return (
    <Feed
      navigation={navigation}
      type={FeedType.Personal}
      hasNewFeed={hasNewFeed}
      isSimplePreview={isSimplePreview}
      usePostReaderView={usePostReaderView}
      ref={ref}
      onRefresh={onRefresh}
    />
  );
}));

const HotFeed = React.memo(React.forwardRef(({
  navigation, isSimplePreview, usePostReaderView, hasNewFeed, onRefresh,
} : FeedTabProps, ref: any) => (
    <Feed
      navigation={navigation}
      type={FeedType.Hot}
      hasNewFeed={hasNewFeed}
      isSimplePreview={isSimplePreview}
      usePostReaderView={usePostReaderView}
      ref={ref}
      onRefresh={onRefresh}
    />)));

const HomeScreen = observer(({ navigation }: RootTabScreenProps<'Home'>) => {
  const {
    settings, hasNewHotFeed, hasNewPersonalFeed, setHasNewHotFeed, setHasNewPersonalFeed,
    setTimePersonalRead, setTimeHotRead, refreshHome, setRefreshHome,
  } = useStores();
  const { data: topics, isLoading } = useTopicsQuery();
  const feedRefHot = React.useRef<FeedRef>(null);
  const feedRefPersonal = React.useRef<FeedRef>(null);
  const isFocused = useIsFocused();
  const layout = useDimension();
  const { colors } = useTheme();
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: TAB_KEY_HOT, title: '🔥Hot' },
    { key: TAB_KEY_PERSONAL, title: '⭐Following' },
  ]);

  const onRefreshPersonal = React.useCallback(() => {
    setTimePersonalRead(new Date().getTime());
    if (hasNewPersonalFeed) { setHasNewPersonalFeed(false); }
    if (refreshHome) { setRefreshHome(false); }
  }, [setTimePersonalRead, hasNewPersonalFeed, setHasNewPersonalFeed, refreshHome, setRefreshHome]);

  const onRefreshHot = React.useCallback(() => {
    setTimeHotRead(new Date().getTime());
    if (hasNewHotFeed) { setHasNewHotFeed(false); }
  }, [setTimeHotRead, hasNewHotFeed, setHasNewHotFeed]);

  React.useEffect(() => {
    if (isFocused && refreshHome) {
      feedRefPersonal.current?.reload();
    }
  }, [refreshHome, isFocused]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <Image source={logo} style={styles.logo} />
      ),
      headerTitleAlign: 'center',
      headerRight: () => (Platform.OS === 'web'
        ? (
          <Icon
            type="material-community"
            name="reload"
            color={colors.primary}
            onPress={() => {
              if (index === 0) {
                feedRefHot.current?.reload();
              } else {
                feedRefPersonal.current?.reload();
              }
            }}
            containerStyle={styles.headerIcon}
            tvParallaxProperties
          />
        ) : null
      ),
    });
  }, [index]);

  const hasFollowingTopics = Boolean(topics?.find((t) => t.following));
  const renderScene = ({ route } : { route: Route }) => {
    switch (route.key) {
      case TAB_KEY_HOT:
        return (
          <HotFeed
            navigation={navigation}
            ref={feedRefHot}
            isSimplePreview={settings.postPreviewStyle === PostPreviewStyle.Simple}
            usePostReaderView={settings.usePostReaderView}
            hasNewFeed={hasNewHotFeed}
            onRefresh={onRefreshHot}
          />);
      case TAB_KEY_PERSONAL:
        return (
          <PersonalFeed
            navigation={navigation}
            ref={feedRefPersonal}
            isEmpty={!hasFollowingTopics}
            isSimplePreview={settings.postPreviewStyle === PostPreviewStyle.Simple}
            usePostReaderView={settings.usePostReaderView}
            hasNewFeed={hasNewPersonalFeed}
            onRefresh={onRefreshPersonal}
          />);
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <View style={styles.empty}>
        <ActivityIndicator size="large"></ActivityIndicator>
      </View>);
  }

  return (
    <TabView
      lazy={true}
      renderTabBar={(props) => (
        <TabBar
          {...props}
          style={[styles.tab, { backgroundColor: colors.background }]}
          activeColor={colors.primary}
          inactiveColor={'grey'}
          indicatorStyle={{ backgroundColor: colors.primary }}
          renderLabel={({ route, color }) => {
            const hasBadge = (route.key === TAB_KEY_HOT && hasNewHotFeed)
            || (route.key === TAB_KEY_PERSONAL && hasNewPersonalFeed);
            return (
              <View>
                <Text style={[styles.tabTitle, { color }]}>
                  {route.title}
                </Text>
                { hasBadge && <Badge status="error" containerStyle={styles.badge}/> }
              </View>
            );
          }}
          onTabPress={({ route }) => {
            if (route.key === 'hot') {
              feedRefHot.current?.scrollToTop();
            } else if (route.key === 'personal') {
              feedRefPersonal.current?.scrollToTop();
            }
          }}

        />)}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={layout}
    />
  );
});

type FeedProps = {
  navigation: any,
  type: FeedType,
  hasNewFeed: boolean,
  isSimplePreview: boolean,
  usePostReaderView: boolean,
  onRefresh: () => void,
};

interface FeedRef {
  reload: () => void;
  scrollToTop: () => void;
}

const Feed = React.forwardRef(({
  navigation, type, hasNewFeed, isSimplePreview, usePostReaderView, onRefresh,
} : FeedProps, ref: any) => {
  const feedRef = React.useRef<TimelineFeedRef>(null);
  const { width } = useDimension();
  const { colors } = useTheme();

  React.useImperativeHandle(ref, () => ({
    reload() {
      refresh();
    },
    scrollToTop() {
      feedRef.current?.scrollToIndex(0);
    },
  }));

  const refresh = () => {
    feedRef.current?.reload();
    feedRef.current?.scrollToIndex(0);
  };

  const fetchPosts = async (type: FeedType, after?: number, before?: number) => {
    try {
      console.log('request home feed posts between: ', after, before);
      const response = await getFeed(type, after, before);
      const { items, hasMore } = response;
      let nextAfter = after !== undefined ? after : (before || 0);
      let nextBefore = before !== undefined ? before : (after || 0);
      if (items.length > 0) {
        nextAfter = items[0].timeCreated;
        nextBefore = items[items.length - 1].timeCreated;
      }
      console.log(`Fetched ${items.length} posts, next cursor (${nextAfter}, ${nextBefore}), has more: ${hasMore}`);
      return {
        items, hasMore, after: nextAfter, before: nextBefore,
      };
    } catch (error: any) {
      console.log(error.message);
      throw new Error('Can not get home feed, please try again later.');
    }
  };

  const fetchLatestFn = React.useCallback(
    async (after: number) => {
      onRefresh();
      return fetchPosts(type, after);
    },
    [onRefresh],
  );

  return (
    <View style={styles.container}>
      {hasNewFeed && (
        <View style={styles.newPostsContainer}>
          <Button
            title="new posts"
            icon={
              <Icon
                type="material-community"
                name="arrow-up-circle-outline"
                color='white'
                size={20}
                tvParallaxProperties
              />}
            buttonStyle={[styles.newPostsButton, { backgroundColor: colors.primary }]}
            titleStyle={styles.newPostsTitle}
            onPress={() => refresh()}
          >
          </Button>
        </View>
      )}
      <TimelineFeed<Post>
        inputRef={feedRef}
        keyExtractor={(item) => item.id}
        renderItem={(item) => (
          <PostPreview
            postData={item}
            width={width}
            isSimple={isSimplePreview}
            usePostReaderView={usePostReaderView}
            checkAndRedirectToSignIn={() => checkSignedIn(navigation)}
            onReportPost={(id: string) => navigation.navigate('ReportPost', { id })}
            onPress={(id: string) => navigation.navigate('Post', { id })}
            showTopic
            showSource
            showNotification
            showSavePost
            showTopicSourceFilter
          />
        )}
        fetchLatestFun={fetchLatestFn}
        fetchPreviousFun={async (before: number) => fetchPosts(type, undefined, before)}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    width: 30,
    height: 30,
  },
  headerIcon: {
    paddingHorizontal: 20,
  },
  newPostsTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  newPostsButton: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    borderRadius: 15,
    borderWidth: 2,
  },
  newPostsContainer: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    margin: 20,
    borderRadius: 15,
    padding: 0,
  },
  empty: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  emptyTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'grey',
  },
  emptySubTitle: {
    fontSize: 15,
    color: 'grey',
  },
  tabTitle: {
    margin: 8,
    fontWeight: 'bold',
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  tab: {
    shadowOffset: { height: 0, width: 0 },
    shadowColor: 'transparent',
    shadowOpacity: 0,
    elevation: 0,
  },
});

export default HomeScreen;
