/* eslint-disable no-bitwise */
// API client to directly fetch from source site.
import { Buffer } from 'buffer';
import { Cache } from 'react-native-cache';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  getParser, getParserById, parsePostUrlToIdAndPage,
} from '@topicfeed/common/parsers';
import { Post, PostViewPosition, PagedPostFeed } from '@topicfeed/common/types';
import { setupRootStore } from '../models';

const USER_AGENT = 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.84 Safari/537.36';
const USER_AGENT_MOBILE = 'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.84 Mobile Safari/537.36';

const axios = require('axios').default;
const iconv = require('iconv-lite');

const client = axios.create({
  timeout: 60 * 1000,
  headers: { 'User-Agent': USER_AGENT },
  withCredentials: true,
  responseType: 'arraybuffer',
  maxContentLength: 50 * 1000 * 1000,
  maxRedirects: 10,
});

const clientMobile = axios.create({
  timeout: 60 * 1000,
  headers: { 'User-Agent': USER_AGENT_MOBILE },
  withCredentials: true,
  responseType: 'arraybuffer',
  maxContentLength: 50 * 1000 * 1000,
  maxRedirects: 10,
});

const postViewPositionCache = new Cache({
  namespace: 'postviewpostion',
  policy: {
    maxEntries: 10000,
    stdTTL: 0,
  },
  backend: AsyncStorage,
});

async function saveObjToCache(cache: Cache, id: string, obj: any) {
  return cache.set(id, JSON.stringify(obj));
}

async function getObjFromCache(cache: Cache, id: string) {
  const objStr = await cache.get(id);
  return objStr ? JSON.parse(objStr) : objStr;
}

async function getRootStore() {
  return setupRootStore();
}

async function getPage(url: string, encoding: string, isMobile = false) {
  const response = await (isMobile ? clientMobile.get(url) : client.get(url));
  const encodingToUse = url.includes('1point3acres.com') ? 'gbk' : encoding;
  return iconv.decode(Buffer.from(response.data), encodingToUse);
}

export async function getPost(id: string): Promise<Post> {
  const parser = getParserById(id);
  if (!parser) {
    throw new Error(`Parser not found for ${id}`);
  }
  const post = {
    id,
    title: '',
    text: '',
    mUrl: parser.getPostUrl(id, 1, true),
    source0: { name: parser.SOURCE, blocked: false },
  };
  populatePostData(post);
  return Promise.resolve(post);
}

export async function getPostViewPosition(id: string): Promise<PostViewPosition> {
  const store = await getRootStore();
  const fromCache = await getObjFromCache(postViewPositionCache, id);
  return fromCache || store.getPostViewPosition(id) || { id };
}

export function getHomeFeedSections(source: string) {
  const parser = getParser(source);
  if (!parser) {
    throw new Error(`Parser not found for ${source}`);
  }
  return parser.HOME_FEED_SECTIONS;
}

const COMBINED_FEED_SOURCE_BATCH_NUM = 5;
const COMBINED_FEED_POST_BATCH_NUM = 20;
const COMBINED_FEED_REFETCH_THRESHOLD = 100;

function shuffle(array: any[]) {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    // eslint-disable-next-line no-param-reassign
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

function hash(s: string) {
  let h = 0xdeadbeef;
  for (let i = 0; i < s.length; i += 1) {
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
  }
  return (h ^ h >>> 16) >>> 0;
}

interface PostFeedSource {
  page?: number;
  encoding: string;
  isMobile: boolean;
  parserFun: (html: string, url: string) => PagedPostFeed;
  getUrlFun: (page: number) => string;
}

const postFeedSourceCurrent: Map<string, PostFeedSource[]> = new Map();
const postFeedSourceNext: Map<string, PostFeedSource[]> = new Map();
const postFeedPostCache: Map<string, Post[]> = new Map();
const postFeedPostIdSet: Map<string, Set<string>> = new Map();

const sortFunByTime = (p1: Post, p2: Post) => (
  (p1.timeLastComment || p1.timePublished || 0)
  > (p2.timeLastComment || p2.timePublished || 0) ? -1 : 1);

const sortFunById = (p1: Post, p2: Post) => (
  hash(p1.id) > hash(p2.id) ? 1 : -1);

export async function getCombinedHomeFeed(forums: string[], page: number) {
  const sources: PostFeedSource[] = [];
  forums.forEach((source) => {
    const parser = getParser(source);
    parser.HOME_FEED_SECTIONS_HOT.forEach((section) => {
      sources.push({
        encoding: parser.ENCODING,
        isMobile: parser.FETCH_HOME_MOBILE,
        parserFun: (html: string, url: string) => parser.parseHomePage(html, url, section),
        getUrlFun: (page: number) => parser.getHomeUrl(section, page),
      });
    });
  });
  return getCombinedFeed('home', sources, page, sortFunById);
}

export async function getCombinedBoardFeed(boardIds: string[], page: number) {
  const sources = boardIds.map((id) => {
    const parser = getParserById(id);
    if (!parser) {
      throw new Error(`Parser not found for board ${id}`);
    }
    return {
      encoding: parser.ENCODING,
      isMobile: parser.FETCH_BOARD_MOBILE,
      parserFun: (html: string, url: string) => parser.parseBoardPage(html, url),
      getUrlFun: (page: number) => parser.getBoardUrl(id, page),
    };
  });
  return getCombinedFeed('board', sources, page, sortFunByTime);
}

export async function getCombinedFeed(
  name: string, sources: PostFeedSource[], page: number, sortFun: (p1: Post, p2: Post)=> number,
): Promise<PagedPostFeed> {
  if (!postFeedPostCache.has(name)) {
    postFeedPostCache.set(name, []);
  }

  // Reset when page is 1.
  if (page === 1) {
    if (!postFeedSourceCurrent.get(name) || postFeedSourceCurrent.get(name)?.length === 0) {
      postFeedSourceCurrent.set(name, shuffle(sources.map((s: PostFeedSource) => {
        const source = s;
        source.page = 1;
        return source;
      })));
      postFeedSourceNext.set(name, []);
    }
    postFeedPostCache.set(name, []);
    postFeedPostIdSet.set(name, new Set());
  }

  const cachedPosts: Post[] = postFeedPostCache.get(name) || [];
  if (!postFeedPostCache.has(name)) {
    postFeedPostCache.set(name, cachedPosts);
  }
  const cachedPostIds: Set<string> = postFeedPostIdSet.get(name) || new Set();
  if (!postFeedPostIdSet.has(name)) {
    postFeedPostIdSet.set(name, cachedPostIds);
  }
  let currentSources = postFeedSourceCurrent.get(name) || [];
  let nextSources = postFeedSourceNext.get(name) || [];

  // Fetch more posts if number of cached posts is low or has unfetched sources.
  if (cachedPosts.length < COMBINED_FEED_REFETCH_THRESHOLD || currentSources.length > 0) {
    if (currentSources.length === 0) {
      currentSources = nextSources;
      postFeedSourceCurrent.set(name, currentSources);
      nextSources = [];
      postFeedSourceNext.set(name, nextSources);
    }
    const sourcesToFetch = currentSources.splice(0, COMBINED_FEED_SOURCE_BATCH_NUM);
    const [{ posts }, hasMores] = await getPostFeeds(sourcesToFetch, sortFun);
    cachedPosts.push(...posts.filter((p) => {
      if (cachedPostIds.has(p.id)) {
        return false;
      }
      cachedPostIds.add(p.id);
      return true;
    }));
    cachedPosts.sort(sortFun);

    // Enqueue next sources.
    for (let i = 0; i < sourcesToFetch.length; i += 1) {
      if (hasMores[i]) {
        const source = sourcesToFetch[i];
        source.page = (source.page || 1) + 1;
        nextSources.push(source);
      }
    }
  }

  // Get posts from cache and return.
  console.log('cache length before return: ', cachedPosts.length);
  const postsToReturn = cachedPosts.splice(0, COMBINED_FEED_POST_BATCH_NUM);
  const hasMore = cachedPosts.length > 0 || currentSources.length > 0 || nextSources.length > 0;
  console.log('current sources: ', currentSources.map((s) => s.getUrlFun(s.page || 1)));
  console.log('next sources: ', nextSources.map((s) => s.getUrlFun(s.page || 1)));
  console.log('cache length after return: ', cachedPosts.length);
  return { posts: postsToReturn, hasMore };
}

async function getPostFeeds(sources: PostFeedSource[], sortFun: (p1: Post, p2: Post)=> number)
  : Promise<[PagedPostFeed, Boolean[]]> {
  const promises = sources.map((s) => getPostFeed(
    s.getUrlFun(s.page || 1), s.encoding, s.isMobile, s.parserFun,
  ));
  const feeds = await Promise.all(
    promises.map((p) => p.catch((e: any) => {
      console.error('Error fetching feed: ', e);
      return { posts: [], hasMore: false };
    })),
  );
  const posts: Post[] = [];
  const hasMores: Boolean[] = [];
  let hasMore = false;
  feeds.forEach((f) => {
    posts.push(...f.posts);
    hasMores.push(f.hasMore);
    hasMore = hasMore || f.hasMore;
  });
  posts.sort(sortFun);
  return [{ posts, hasMore }, hasMores];
}

async function getPostFeed(
  url: string, encoding: string, isMobile: boolean,
  parserFun: (html: string, url: string) => PagedPostFeed,
) {
  const html = await getPage(url, encoding, isMobile);
  const feed = parserFun(html, url);
  feed.posts = await Promise.all(feed.posts.map((p) => populatePostData(p)));
  return feed;
}

export async function getHomeFeed(source: string, section: string, page: number) {
  const parser = getParser(source);
  if (!parser) {
    throw new Error(`Parser not found for ${source}`);
  }
  const url = parser.getHomeUrl(section, page);
  return getPostFeed(url, parser.ENCODING, parser.FETCH_HOME_MOBILE,
    (html, url) => parser.parseHomePage(html, url, section));
}

export async function getBoardFeed(boardId: string, page: number) {
  const parser = getParserById(boardId);
  if (!parser) {
    throw new Error(`Parser not found for board ${boardId}`);
  }
  const url = parser.getBoardUrl(boardId, page);
  return getPostFeed(url, parser.ENCODING, parser.FETCH_BOARD_MOBILE,
    (html, url) => parser.parseBoardPage(html, url));
}

export async function getBoards(source: string) {
  const parser = getParser(source);
  if (!parser) {
    throw new Error(`Parser not found for ${source}`);
  }
  const html = await getPage(
    parser.getBoardIndexUrl(), parser.ENCODING, parser.FETCH_BOARD_INDEX_MOBILE,
  );
  const boardMap = parser.parseBoardIndexPage(html, parser.getBoardIndexUrl());
  const store = await getRootStore();
  return new Map(Array.from(boardMap, ([section, boards]) => {
    boards.forEach((b) => {
      const boardData = b;
      boardData.favorite = store.favoriteBoards.has(b.id);
    });
    return [section, boards];
  }));
}

async function populatePostData(post: Post) {
  const store = await getRootStore();
  const postViewPosition = await getPostViewPosition(post.id);
  const postData = post;
  const { user } = post;
  if (user) {
    user.blocked = store.blockedUsers.has(user.id);
  }
  postData.hidden = store.hiddenPosts.has(post.id);
  postData.reported = store.reportedPosts.has(post.id);
  postData.viewPosition = postViewPosition;
  return post;
}

export async function savePostViewPosition(id: string, url?: string, mUrl?: string,
  comments?: number) {
  const viewUrl = url || mUrl || '';
  const postId = parsePostUrlToIdAndPage(viewUrl)[0];
  if (postId === id) {
    return (saveObjToCache(postViewPositionCache, id, {
      id, url, mUrl, comments,
    }));
  }
  return Promise.resolve();
}

export async function hidePost(id: string, hide: boolean) {
  return (await getRootStore()).hidePost(id, hide);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function reportPost(id: string, reasonCode: number, reason: string) {
  return (await getRootStore()).reportPost(id);
}

export async function blockUser(id: string, block: boolean) {
  return (await getRootStore()).blockUser(id, block);
}

export async function favoriteBoard(id: string, name: string, favorite: boolean) {
  return (await getRootStore()).favoriateBoard(id, name, favorite);
}
