import * as React from 'react';
import {
  StyleSheet, Platform,
} from 'react-native';

import { View } from './Themed';
import { SearchBar } from './SearchBar';
import { WebViewFeed, WebViewFeedResult } from './WebViewFeed';

export interface WebViewSearchProps<T> {
  keyExtractor: (item: T) => string;
  renderItem: (item: T) => React.ReactElement | null;
  parsePageFun: (page: string, url: string) => WebViewFeedResult<T>;
  showWebViewFun: (page: string, url: string) => Boolean;
  getSearchText: (text: string) => string;
  renderBlank: () => React.ReactElement | null;
}

const USER_AGENT = 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.71 Safari/537.36';

const PRE_LOAD_JS = `
  document.addEventListener("DOMContentLoaded", function(){
    var html = document.documentElement.outerHTML;
    ReactNativeWebView.postMessage(html);
  });
  true;
`;

const NEXT_PAGE_JS = `
  document.querySelector('div[role="navigation"] tr td:last-child a').click();
  true;
`;

function WebViewSearch<T>(props: WebViewSearchProps<T>) {
  const {
    keyExtractor, renderItem, parsePageFun, showWebViewFun, getSearchText, renderBlank,
  } = props;
  const [searchText, setSearchText] = React.useState('');
  const [inputText, setInputext] = React.useState('');
  const searchUrl = `https://www.google.com/search?q=${getSearchText(searchText)}`;

  return (
    <View style={styles.container}>
      <SearchBar
        containerStyle={styles.searchBar}
        platform={Platform.OS === 'android' ? 'android' : 'ios'}
        placeholder="Search Posts"
        value={inputText}
        onChangeText={(text) => setInputext(text)}
        onClear={() => setInputext('')}
        onSubmitEditing={() => {
          setSearchText('');
          setTimeout(() => setSearchText(inputText), 100);
        }}
        returnKeyType="search"
      />
      { searchText.length > 0
        ? <WebViewFeed
            url={searchUrl}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            parsePageFun={parsePageFun}
            showWebViewFun={showWebViewFun}
            preLoadJs={PRE_LOAD_JS}
            nextPageJs={NEXT_PAGE_JS}
            userAgent={USER_AGENT}
          />
        : <View style={styles.blank}>
            {renderBlank()}
          </View>
      }
    </View>
  );
}

export { WebViewSearch };

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  searchBar: {
    width: '100%',
    paddingHorizontal: 5,
  },
  blank: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
