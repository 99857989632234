/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, User, IndexPost, Topics,
  PagedPostFeed, Board, Post,
} from '../types';
import { ParserBase } from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['finance', Topics.Investment],
  ['tzlc', Topics.Investment],
  ['cytd', Topics.Entrepreneur],

  ['immigration', Topics.Immigration],
  ['imminews', Topics.Immigration],
  ['college', Topics.Education],

  ['military', Topics.Military],
  ['currentevent', Topics.WorldNews],
  ['mychina', Topics.ChinaNews],
  ['taiwan', Topics.ChinaNews],
  ['memory', Topics.History],

  ['style', Topics.Fashion],
  ['tv', Topics.MovieTV],
  ['travel', Topics.Travel],
  ['ent', Topics.Celebrity],

  ['myhourse', Topics.Family],
  ['cooking', Topics.Food],
  ['znjy', Topics.Parenting],
  ['kids', Topics.Parenting],
  ['health', Topics.Health],
  ['law', Topics.Law],
  ['auto', Topics.Car],
  ['computer', Topics.Computer],
  ['diy', Topics.Home],

  ['joke', Topics.Joke],
  ['ghost', Topics.Ghost],

  ['art', Topics.Art],
  ['photography', Topics.Photography],

  ['career', Topics.Career],
  ['backhome', Topics.Returnee],
  ['kghy', Topics.Marriage],
  ['marriage', Topics.Marriage],
  ['romance', Topics.Love],
  ['rdzn', Topics.Gossip],
  ['gossip', Topics.Gossip],
  ['cqrs', Topics.Gossip],

  ['pet', Topics.Pet],
  ['ktv', Topics.Music],
  ['music', Topics.Music],
  ['kxtw', Topics.TechNews],
  ['smsh', Topics.Electronics],
  ['sports', Topics.Sports],
  ['mysj', Topics.English],
  ['game', Topics.Game],
]);

class ParserWenxueCity extends ParserBase {
  SOURCE = 'wenxuecity.com';

  ID_PREFIX = 'wenxuecity-';

  BASE_URL_MOBILE = 'https://m.wenxuecity.com';

  BASE_URL = 'https://bbs.wenxuecity.com';

  HOME_FEED_SECTION_HOT = '🔥热贴';

  HOME_FEED_SECTION_TOPIC = '💬话题';

  HOME_FEED_SECTIONS = [this.HOME_FEED_SECTION_HOT, this.HOME_FEED_SECTION_TOPIC];

  HOME_FEED_SECTIONS_HOT = [this.HOME_FEED_SECTION_HOT];

  LINEAR_COMMENTS = false;

  // Need to be implemented for forumreader.
  TITLE = '文学城';

  DOMAIN = 'bbs.wenxuecity.com';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostItemUrl(postId: string, postItemId: string, isMobile: boolean, page: number = 1):
  string {
    return this.getPostUrl(postItemId, page, isMobile);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCommentJs(postId: string, postItemId?: string): string {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUpVoteJs(postId: string, postItemId?: string): string {
    return '';
  }

  getProfileUrlAndJs(): [string, string] {
    return [`${this.BASE_URL_MOBILE}/members/`, ''];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHomeUrl(section: string, page: number): string {
    return `${this.BASE_URL_MOBILE}/bbs/?by=day&page=${page}`;
  }

  getBoardUrl(id: string, page: number = 1): string {
    return `${this.BASE_URL_MOBILE}/bbs/${ParserBase.stripIdPrefix(id)}/?page=${page}`;
  }

  getBoardIndexUrl(): string {
    return `${this.BASE_URL}/catalog/`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseHomePage(html: string, url: string, section: string): PagedPostFeed {
    const $ = this.getCheerio(html, url);
    if (section === this.HOME_FEED_SECTION_HOT) {
      const posts = $('#day .content-structure')
        .map((i: number, el: any) => {
          const url = $('a', el).attr('href');
          const title = $('h4', el).text().trim();
          const id = this.getPostIdAndPage(url)[0];
          const text = $(el.children[2]).text().trim();
          const timePublishedString = $('.content-info .content-left', el).text().trim();
          const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-MM-DD hh:mm:ss', 'America/Los_Angeles');
          const commentsString = $('.content-info .content-right', el).text().split('\n')[1].trim();
          const comments = (parseInt(commentsString, 10) - 1) || 0;
          const username = $('.bbs-user', el).text().trim();
          const user: User = {
            id: this.ID_PREFIX + username,
            username,
            profileImage: '',
            sourceName: this.SOURCE,
            blocked: false,
          };
          const post: Post = {
            id: this.getPostIdAndPage(url)[0],
            title,
            text,
            timePublished,
            comments,
            mUrl: this.getPostUrl(id, 1, true),
            user,
            source0: { name: this.SOURCE, blocked: false, title: this.TITLE },
          };
          return post;
        })
        .get();
      const hasMore = Boolean($('#day .scroll-content .next')[0]);
      return { posts, hasMore };
    } if (section === this.HOME_FEED_SECTION_TOPIC) {
      const posts = $('.scroll-content a', $('.section-bbs')[1])
        .map((i: number, el: any) => {
          const url = $(el).attr('href');
          const title = $('h4', el).text().trim();
          const id = this.getPostIdAndPage(url)[0];
          const post: Post = {
            id: this.getPostIdAndPage(url)[0],
            title,
            text: '',
            mUrl: this.getPostUrl(id, 1, true),
            source0: { name: this.SOURCE, blocked: false, title: this.TITLE },
          };
          return post;
        })
        .get();
      return { posts, hasMore: false };
    }
    throw new Error(`No ${section} section found in home page ${url}.`);
  }

  parseBoardPage(html: string, url: string): PagedPostFeed {
    const $ = this.getCheerio(html, url);
    const boardName: string = $('.site-name').text().trim();
    const board: Board = {
      id: this.getBoardId(url),
      name: boardName,
      label: boardName,
      section: '',
    };
    const posts = $('.usermenu .content-structure')
      .filter((i: number, el: any) => $('.mt10', el)[0])
      .map((i: number, el: any) => {
        const url = $('a', el).attr('href');
        const title = $('h4', el).text().trim();
        const id = this.getPostIdAndPage(url)[0];
        const timePublishedString = $('.date', el).text().trim();
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-MM-DD hh:mm:ss', 'America/Los_Angeles');
        const username = $('.name', el).text().trim();
        const user: User = {
          id: this.ID_PREFIX + username,
          username,
          profileImage: '',
          sourceName: this.SOURCE,
          blocked: false,
        };
        const post: Post = {
          id: this.getPostIdAndPage(url)[0],
          title,
          text: '',
          mUrl: this.getPostUrl(id, 1, true),
          timePublished,
          user,
          source0: { name: this.SOURCE, blocked: false, title: this.TITLE },
          board,
        };
        return post;
      })
      .get();
    const hasMore = $('.usermenu .content-structure h5').text().trim() === '下一页';
    return { posts, hasMore };
  }

  parseBoardIndexPage(html: string, url: string): Map<string, Board[]> {
    const $ = this.getCheerio(html, url);
    const sections = $('#catalogColumn ul')
      .map((i: number, el: any) => {
        const section = $('h2', el).text().trim();
        const boards = $('li a', el).map((ii: number, ee: any) => {
          const name = $(ee).text().trim();
          const id = this.getBoardId($(ee).attr('href'));
          return {
            id,
            name,
            section,
            label: name,
          };
        }).get();
        return { section, boards };
      })
      .get()
      .filter((s: { section: string, boards: Board[] }) => s.section !== '热门论坛');
    return new Map(sections.map((t:any) => [t.section, t.boards]));
  }

  getBoardId(url: string): string {
    const { hostname, pathname } = new URL(url);
    if (hostname === 'm.wenxuecity.com') {
      if (pathname.startsWith('/bbs/')) {
        return this.ID_PREFIX + pathname.split('/')[2];
      }
      return '';
    } if (hostname === 'bbs.wenxuecity.com') {
      return this.ID_PREFIX + pathname.split('/')[1];
    }
    return '';
  }
  // Need to be implemented for forumreader.

  getIndexPageUrls(): string[] {
    return [this.BASE_URL];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const selectors = [
      '#hottopic #day .item .title', '#hottopic #week .item .title',
      '#hottopic #month .item .title', '#rightside ul li',
    ];
    const postItemList = selectors.map((s) => $(s)
      .map((i: number, el: any) => {
        const url = $('a', el).attr('href');
        return {
          id: this.getPostIdAndPage(url)[0],
        };
      })
      .get()
      .filter((p: { id: string }) => !!p.id));
    const postItems = Array.prototype.concat.apply([], postItemList);
    return postItems;
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('body p').text().includes('您请求访问的页面地址不存在')) {
      return '您请求访问的页面地址不存在';
    }
    const [postId, currentPage] = this.getPostIdAndPage(url);
    const totalPage = 1;

    $('th').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });
    $('td').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });
    $('tr').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });
    $('table').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });

    const usernameMain = $($('.news-info .mr2')[0]).text();
    const userMain: User = {
      id: this.ID_PREFIX + usernameMain,
      username: usernameMain,
      profileImage: '',
      sourceName: this.SOURCE,
      blocked: false,
    };
    const timePublishedStringMain = $($('.news-info .mr2')[1]).text();
    const timePublishedMain = ParserBase.parseTime(timePublishedStringMain, 'YYYY-MM-DD hh:mm:ss', 'America/Los_Angeles');

    const mainItem: PostItem = {
      id: '1',
      content: `<div>${$('.small-font').html()}</div>`,
      user: userMain,
      timePublished: timePublishedMain,
      images: [],
      videos: [],
      page: currentPage,
      itemId: postId,
    };

    const items: PostItem[] = $('.comments')
      .map((i: number, el: any) => {
        const id = i + 2;
        // Get comment level.
        const style = $('.comment', el).attr('style');
        const margin = style.split(';')
          .filter((s: string) => s.trim().startsWith('margin:'))
          .map((s: string) => parseInt(s.split(':')[1].split(' ')[3], 10))[0];
        const level = margin ? margin / 20 : 0;

        const content = `<div>${$('.comments-wrapper p', el).html()}</div>`;
        const timePublishedString = $('.date', el).text();
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-MM-DD hh:mm:ss', 'America/Los_Angeles');
        const username = $('.comments-wrapper h2', el).text().trim();
        const user: User = {
          id: this.ID_PREFIX + username,
          username,
          profileImage: '',
          sourceName: this.SOURCE,
          blocked: false,
        };
        // const itemId = this.getPostIdAndPage($('.comment a', el).attr('href'))[0];
        const postItem: PostItem = {
          id: id.toString().trim(),
          content,
          user,
          timePublished,
          level,
          images: [],
          videos: [],
          page: currentPage,
          itemId: postId,
        };
        return postItem;
      })
      .get();

    const title = $('.heading h1').text().trim();
    const boardUrl = $('header a.pl3').attr('href');
    const boardId = this.getBoardId(boardUrl);
    const boardName = ParserBase.stripIdPrefix(boardId);
    const board: Board = {
      id: boardId,
      name: boardName,
      label: boardName,
      section: '',
    };
    const topicId = BOARD_TO_TOPICS_MAP.get(ParserBase.stripIdPrefix(boardId)) || Topics.Other;

    return {
      currentPage,
      totalPage,
      items: [mainItem].concat(items),
      title,
      topicId,
      board,
      comments: items.length,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  filterImage(url: string) {
    return (
      !url.includes('editor/ckeditor/plugins/smiley/images')
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    const [boardId, postId] = ParserBase.stripIdPrefix(id).split('_');
    if (isMobile) {
      return `${this.BASE_URL_MOBILE}/bbs/${boardId}/${postId}.html`;
    }
    return `${this.BASE_URL}/${boardId}/${postId}.html`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname } = new URL(url);
    if (hostname === 'm.wenxuecity.com') {
      if (pathname.startsWith('/bbs/') && pathname.endsWith('.html')) {
        const tokens = pathname.split('.html')[0].split('/');
        if (tokens.length === 4) {
          return [`${this.ID_PREFIX + tokens[2]}_${tokens[3]}`, 1];
        }
      }
    }
    if (hostname === 'bbs.wenxuecity.com') {
      if (pathname.endsWith('.html')) {
        const tokens = pathname.split('.html')[0].split('/');
        if (tokens.length === 3) {
          return [`${this.ID_PREFIX + tokens[1]}_${tokens[2]}`, 1];
        }
      }
    }
    return ['', 0];
  }
}

export const parser = new ParserWenxueCity();
