import React from 'react';
import PropTypes from 'prop-types';
import { useWindowDimensions, Platform } from 'react-native';

export interface DimensionContextType {
  width: number,
  height: number,
  screenWidth: number,
  screenHeight: number,
}

export const DimensionContext = React.createContext<DimensionContextType>(
  {
    width: 0, height: 0, screenWidth: 0, screenHeight: 0,
  },
);

export const DimensionProvider: React.FC = (props: any) => {
  const { children } = props;
  const { width, height } = useWindowDimensions();
  const contentWidth = Platform.OS === 'web' ? Math.min(800, width) : width;
  const value = {
    width: contentWidth, height, screenWidth: width, screenHeight: height,
  };
  return (
    <DimensionContext.Provider value={value}>
      {children}
    </DimensionContext.Provider>
  );
};

DimensionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDimension = () => React.useContext(DimensionContext);
