import {
  PostPage, PostItem, User, IndexPost, Topics,
} from '../types';
import { ParserBase } from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['1', Topics.Other],
  ['59', Topics.Food],
  ['21', Topics.Fashion],
  ['63', Topics.Photography],
  ['9', Topics.RealEstate],
  ['13', Topics.Selfie],
  ['86', Topics.Travel],
  ['26', Topics.Car],
  ['68', Topics.Pet],
  ['23', Topics.Fitness],
  ['83', Topics.Parenting],

  ['10', Topics.Education],
  ['7', Topics.Immigration],
  ['74', Topics.Education],
  ['75', Topics.Career],
  ['78', Topics.English],

  ['48', Topics.Gossip],
  ['87', Topics.Electronics],
  ['27', Topics.Emotion],
  ['77', Topics.Ask],

  ['55', Topics.Sell],
  ['53', Topics.Sell],
  ['80', Topics.Gun],
  ['25', Topics.Game],
  ['17', Topics.Play],
]);

class ParserVanPeople extends ParserBase {
  SOURCE = 'vanpeople.com';

  ID_PREFIX = 'vanpeople-';

  BASE_URL = 'https://forum.vanpeople.com';

  FETCH_INDEX_MOBILE = true;

  getIndexPageUrls(): string[] {
    return [
      `${this.BASE_URL}/forum.php?mod=forumdisplay&filter=heat&orderby=heats&mobile=2`,
      `${this.BASE_URL}/forum.php?mod=forumdisplay&filter=digest&digest=1&orderby=dateline&mobile=2`,
      `${this.BASE_URL}/forum.php?mod=forumdisplay&filter=dateline&orderby=dateline&mobile=4`,
    ];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const postItems = $('.tl_shadow a')
      .map((i: number, el: any) => {
        const url = el.attribs.href;
        const id = this.getPostIdAndPage(url)[0];
        return {
          id,
        };
      }).get()
      .filter((p: { id: string }) => !!p.id);
    return postItems;
  }

  extractText(content: string) {
    const $ = this.getCheerio(content, '');
    $('.pstatus').remove();
    return $.text().trim();
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('#messagetext')[0]) {
      return $('#messagetext .am-alert').text().trim();
    }
    const currentPage = this.getPostIdAndPage(url)[1];
    const pagingElement = $('#page-num')[0];
    let totalPage = 1;
    if (pagingElement) {
      totalPage = parseInt(pagingElement.attribs.value.split('/')[1], 10) || currentPage;
    }

    $('.attach li a').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
      element.attribs.style = '';
    });
    $('.attach ul').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
      element.attribs.style = '';
    });
    $('.attach li').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'div';
      element.attribs.style = '';
    });
    $('.list .post_list_item .content style').remove();
    const items: PostItem[] = $('.list .post_list_item')
      .map((i: number, el: any) => {
        const id = $('.list_item_floor_num', el).text().split('楼')[0];
        const content = `<div>${$('.content', el).html()}</div>`;
        const timePublishedString = $('.list_item_time', el).text().trim();
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-M-D HH:mm', 'America/Los_Angeles');
        const username = $('a.user_name', el).text().trim();
        const userUrl = `${this.BASE_URL}/${$('a.user_name', el).attr('href')}`;
        const { searchParams } = new URL(userUrl);
        const user: User = {
          id: this.ID_PREFIX + searchParams.get('uid') || '',
          username,
          profileImage: $('.user_img', el).attr('src'),
          sourceName: this.SOURCE,
          blocked: false,
        };
        let upVotes = 0;
        if ($('#recommend_nums', el)[0]) {
          upVotes = parseInt($('#recommend_nums', el).text(), 10) || 0;
        } else {
          upVotes = parseInt($('.onenumber .numbers', el).text(), 10) || 0;
        }

        const postItem: PostItem = {
          id,
          content,
          user,
          upVotes,
          downVotes: 0,
          timePublished,
          images: [],
          videos: [],
        };
        return postItem;
      })
      .get();

    const title = $('.post_title_embed').text().trim();
    const commentsElement = $('.content_one_lou_footer .right span')[3];
    let comments = 0;
    if (commentsElement) {
      comments = parseInt($($('.content_one_lou_footer .right span')[3]).text(), 10) || 0;
    }
    let boardId = '';
    const boardElement = $('.more_spinner li a')[1];
    if (boardElement) {
      const { searchParams } = new URL(boardElement.attribs.href);
      boardId = searchParams.get('fid') || '';
    }
    const topicId = BOARD_TO_TOPICS_MAP.get(boardId) || Topics.Other;
    return {
      currentPage, totalPage, items, title, comments, topicId,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  filterImage(url: string) {
    return (
      !url.startsWith(`${this.BASE_URL}/static/image/`)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    return `${this.BASE_URL}/forum.php?mod=viewthread&tid=${ParserBase.stripIdPrefix(id)}&extra=&page=${page}`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname, searchParams } = new URL(url);
    const mod = searchParams.get('mod');
    const postId = searchParams.get('tid');
    if (hostname === 'forum.vanpeople.com') {
      if (pathname.startsWith('/showtopic-') && pathname.endsWith('.php')) {
        const tokens = pathname.split('-');
        if (tokens.length > 1) {
          const id = tokens[1].split('.')[0];
          return [this.ID_PREFIX + id, 1];
        }
      } else if (pathname === '/forum.php' && mod === 'viewthread' && postId) {
        const page = parseInt(searchParams.get('page') || '', 10) || 1;
        return [this.ID_PREFIX + postId, page];
      }
    }
    return ['', 0];
  }
}

export const parser = new ParserVanPeople();
