import * as React from 'react';
import {
  StyleSheet, TouchableOpacity, Platform,
} from 'react-native';
import {
  Text, Icon, colors,
} from 'react-native-elements';

import { Notification as NotificationModel, NotificationType } from '@topicfeed/common/types';

import { View } from './Themed';
import { markNotificationRead } from '../utils/api';
import { openURL } from '../utils/polyfill';

const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

export interface NotificationProps {
  notification: NotificationModel;
  onPress: (id: string) => void;
  usePostReaderView?: boolean;
}

const ICON_SIZE = 20;

export function Notification(props: NotificationProps) {
  const { notification, onPress } = props;
  const [isRead, setIsRead] = React.useState(notification.read);
  const iconColor = isRead ? colors.grey3 : colors.primary;
  const iconName = 'notifications-circle';

  const onNotificationPress = () => {
    markNotificationRead(notification.id)
      .then(() => setIsRead(true));
  };

  if (notification.type === NotificationType.POST_COMMENT) {
    const post = JSON.parse(notification.detailJson);
    return (
      <TouchableOpacity
        onPress={() => {
          onNotificationPress();
          if (Platform.OS === 'web') {
            openURL(post.mUrl);
          } else {
            onPress(post.id);
          }
        }}
        delayPressIn={100}
      >
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Icon
                type="ionicon"
                name={iconName}
                color={iconColor}
                size={ICON_SIZE}
                tvParallaxProperties
              />
              <Text style={styles.headerText}>New comment</Text>
              <View style={styles.dot} />
              <Text style={isRead ? styles.topicRead : styles.topic}>{post.topic.name}</Text>
            </View>
            <View style={styles.headerRight}>
              <Text style={styles.time}>{dayjs(notification.timeCreated).fromNow()}</Text>
            </View>
          </View>
          <Text numberOfLines={2} style={isRead ? styles.titleRead : styles.title}>
            {post.title}
          </Text>
        </View>
        <View style={{ backgroundColor: colors.grey5, height: 1 }} />
      </TouchableOpacity>
    );
  }
  return null;
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 15,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 5,
    paddingBottom: 15,
    paddingHorizontal: 20,
  },
  topic: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  topicRead: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.grey3,
  },
  headerText: {
    fontSize: 15,
    paddingLeft: 5,
    color: colors.grey3,
  },
  dot: {
    borderRadius: 3,
    height: 3,
    width: 3,
    backgroundColor: colors.grey3,
    marginHorizontal: 10,
  },
  time: {
    fontSize: 15,
    color: colors.grey3,
  },
  title: {
    fontSize: 16,
  },
  titleRead: {
    fontSize: 16,
    color: colors.grey3,
  },
});
