import * as React from 'react';
import {
  StyleSheet, TouchableOpacity,
} from 'react-native';
import { observer } from 'mobx-react-lite';

import { Post } from '@topicfeed/common/types';
import {
  View, PostPreview, TimelineFeed, TimelineFeedRef, Text,
} from '@topicfeed/appcommon/components';
import { useStores, PostPreviewStyle } from '@topicfeed/appcommon/models';
import { getTopicPosts } from '@topicfeed/appcommon/utils/api';
import { useDimension } from '@topicfeed/appcommon/context';
import { checkSignedIn } from '@topicfeed/appcommon/utils/auth';

import { RootStackScreenProps } from '../types';

const TopicPostsScreen = observer(({ route, navigation }: RootStackScreenProps<'TopicPosts'>) => {
  const { topicId, title } = route.params;
  const { settings } = useStores();
  const feedRef = React.useRef<TimelineFeedRef>(null);
  const { width } = useDimension();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <TouchableOpacity onPress={() => feedRef.current?.scrollToIndex(0)}>
          <Text style={styles.header}>{title}</Text>
        </TouchableOpacity>
      ),
      headerTitleAlign: 'center',
    });
  }, []);

  const fetchPosts = async (after?: number, before?: number) => {
    try {
      console.log('request topic posts between: ', topicId, after, before);
      const response = await getTopicPosts(topicId, after, before);
      const { items, hasMore } = response;
      let nextAfter = after !== undefined ? after : (before || 0);
      let nextBefore = before !== undefined ? before : (after || 0);
      if (items.length > 0) {
        nextAfter = items[0].timeCreated;
        nextBefore = items[items.length - 1].timeCreated;
      }
      console.log(`Fetched ${items.length} posts, next cursor (${nextAfter}, ${nextBefore}), has more: ${hasMore}`);
      return {
        items, hasMore, after: nextAfter, before: nextBefore,
      };
    } catch (error: any) {
      console.log(error.message);
      throw new Error('Can not get posts, please try again later.');
    }
  };

  return (
    <View style={styles.container}>
      <TimelineFeed<Post>
        inputRef={feedRef}
        keyExtractor={(item) => item.id}
        renderItem={(item) => (
          <PostPreview
            postData={item}
            width={width}
            isSimple={settings.postPreviewStyle === PostPreviewStyle.Simple}
            usePostReaderView={settings.usePostReaderView}
            checkAndRedirectToSignIn={() => checkSignedIn(navigation)}
            onReportPost={(id: string) => navigation.navigate('ReportPost', { id })}
            onPress={(id: string) => navigation.navigate('Post', { id })}
            showTopic
            showSource
            showSavePost
            showNotification
            showTopicSourceFilter
          />
        )}
        fetchLatestFun={async (after: number) => fetchPosts(after)}
        fetchPreviousFun={async (before: number) => fetchPosts(undefined, before)}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export default TopicPostsScreen;
