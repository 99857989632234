import * as React from 'react';
import {
  View, FlatList, ActivityIndicator, NativeSyntheticEvent,
  NativeScrollEvent, StyleSheet,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { colors, Icon, Text } from 'react-native-elements';
import { useTheme } from '@react-navigation/native';
import { WebView } from 'react-native-webview';
import { WebViewMessageEvent } from 'react-native-webview/lib/WebViewTypes';

import { useDimension } from '../context';

export interface WebViewFeedResult<T> {
  items: T[];
  hasMore: boolean;
}

export interface WebViewFeedProps<T> {
  inputRef?: React.MutableRefObject<any>;
  keyExtractor: (item: T) => string;
  renderItem: (item: T) => React.ReactElement | null;
  parsePageFun: (page: string, url: string) => WebViewFeedResult<T>;
  showWebViewFun: (page: string, url: string) => Boolean;
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  url: string;
  nextPageJs: string;
  preLoadJs: string;
  userAgent?: string
}

export type WebViewFeedRef = {
  reload: () => void;
  scrollToIndex: (i: number) => void;
};

/**
 * WebViewFeed component renders a list of items fetched by hidden web view.
 */
function WebViewFeed<T>(props: WebViewFeedProps<T>) {
  const {
    keyExtractor, renderItem, parsePageFun, showWebViewFun, onScroll, inputRef,
    url, preLoadJs, nextPageJs, userAgent,
  } = props;
  const [loaded, setLoaded] = React.useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [showWebView, setShowWebView] = React.useState(false);
  const [items, setItems] = React.useState<Array<T>>([]);
  const [itemIds, setItemIds] = React.useState<Set<string>>(new Set());
  const listRef = React.useRef<FlatList>(null);
  const { height, width } = useDimension();
  const { colors: { border } } = useTheme();
  const webviewRef = React.useRef<WebView>(null);

  console.log(`Render webview feed ${url}, has more: ${hasMore}`);

  const loadInitial = () => {
    if (!isLoadingInitial && !isLoadingMore) {
      setIsLoadingInitial(true);
      setItemIds(new Set<string>());
      webviewRef.current?.injectJavaScript(`window.location='${url}';`);
    }
  };

  const loadMore = () => {
    if (!isLoadingInitial && !isLoadingMore && hasMore) {
      setIsLoadingMore(true);
      webviewRef.current?.injectJavaScript(nextPageJs);
    }
  };

  const onMessage = async (event: WebViewMessageEvent) => {
    try {
      const { data, url } = event.nativeEvent;
      if (showWebViewFun(data, url)) {
        if (!showWebView) {
          setShowWebView(true);
        }
      } else if (showWebView) {
        setShowWebView(false);
      }
      const result = parsePageFun(data, url);
      const itemDedup = result.items
        .filter((i) => {
          if (!itemIds.has(keyExtractor(i))) {
            itemIds.add(keyExtractor(i));
            return true;
          }
          return false;
        });
      if (isLoadingInitial) {
        setItems(itemDedup);
      } else {
        setItems(items.concat(itemDedup));
      }
      setHasMore(result.hasMore);
    } catch (error: any) {
      showMessage({
        message: 'Error happened, please retry later.',
        type: 'danger',
      });
    } finally {
      if (isLoadingInitial) { setIsLoadingInitial(false); }
      if (isLoadingMore) { setIsLoadingMore(false); }
      if (!loaded) { setLoaded(true); }
    }
  };

  React.useImperativeHandle(inputRef, () => ({
    reload() {
      loadInitial();
    },
    scrollToIndex(i: number) {
      if (items.length > 0) { listRef.current?.scrollToIndex({ animated: true, index: i }); }
    },
  }));

  const Footer = () => {
    if (isLoadingMore) {
      return (
        <ActivityIndicator size="large" color={colors.grey3} />
      );
    }
    if (!hasMore && items.length !== 0) {
      return (
        <Icon type="material-community" name="sunglasses" size={30} color={colors.grey3} tvParallaxProperties/>
      );
    }
    return (
      <View />
    );
  };

  const EmptyComponent = () => (
    <View style={[styles.empty, { paddingTop: height / 3 }]}>
      <Text style={styles.emptyTitle}>No content found 🤔</Text>
    </View>
  );

  const Separator = () => <View style={[styles.separator, { backgroundColor: border }]} />;

  return (
  <View style={styles.container}>
    <View style={{ width: showWebView ? 0 : width }}>
      { loaded
        ? <FlatList<T>
            ref={listRef}
            style={styles.list}
            data={items}
            refreshing={isLoadingInitial}
            onScroll={onScroll}
            keyExtractor={keyExtractor}
            renderItem={({ item }) => renderItem(item)}
            onRefresh={loadInitial}
            onEndReached={loadMore}
            ListFooterComponent={Footer}
            ListFooterComponentStyle={styles.footer}
            ListEmptyComponent={EmptyComponent}
            ItemSeparatorComponent={Separator}
            windowSize={3}
          />
        : <View style={styles.loading}>
            <ActivityIndicator size="large" color={colors.grey3} />
          </View>
      }
    </View>
    <WebView
      ref={webviewRef}
      source={{ uri: url }}
      style={[styles.webview, { width }]}
      onContentProcessDidTerminate={(syntheticEvent) => {
        const { nativeEvent } = syntheticEvent;
        console.warn('Content process terminated, reloading: ', nativeEvent);
        webviewRef.current?.reload();
      }}
      injectedJavaScriptBeforeContentLoaded={preLoadJs}
      onMessage={onMessage}
      sharedCookiesEnabled
      domStorageEnabled
      mediaPlaybackRequiresUserAction
      setSupportMultipleWindows={false}
      androidHardwareAccelerationDisabled
      cacheEnabled
      originWhitelist={['*']}
      userAgent={userAgent}
    />
  </View>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  list: {
  },
  separator: {
    height: 1,
  },
  footer: {
    padding: 20,
    alignContent: 'center',
    justifyContent: 'center',
  },
  empty: {
    justifyContent: 'center',
  },
  emptyTitle: {
    fontSize: 18,
    color: colors.grey3,
    paddingVertical: 20,
    textAlign: 'center',
  },
  loading: {
    height: '100%',
    justifyContent: 'center',
  },
  webview: {
  },
});

export { WebViewFeed };
