import firebase from 'firebase/app';

const BATCH_UPLOAD_SIZE = 450;

let fdb: firebase.firestore.Firestore;

export function db() {
  if (!fdb) {
    fdb = firebase.firestore();
  }
  return fdb;
}

export async function createUser(userId: string) {
  const userRef = db().collection('users').doc(userId);
  const user = await userRef.get();
  if (!user.exists) {
    await userRef.set({ uid: userId });
  }
}

export async function batchUpload(userId: string, entityType: string, entities: any[]) {
  console.log(`Uploading ${entityType} for user ${userId}.`);
  let n = 0;
  let i = 0;
  let batch = db().batch();
  const promises = [];
  while (i < entities.length) {
    const siteRef = db().collection(`users/${userId}/${entityType}`).doc(entities[i].id);
    batch.set(siteRef, entities[i]);
    i += 1;
    n += 1;
    if (n === BATCH_UPLOAD_SIZE) {
      promises.push(batch.commit());
      n = 0;
      batch = db().batch();
    }
  }
  promises.push(batch.commit());
  await Promise.all(promises);
}
