/* global __DEV__ */

import { onSnapshot } from 'mobx-state-tree';
import { RootStoreModel, RootStore } from './root-store';

import * as storage from '../utils/storage';

const ROOT_STATE_STORAGE_KEY = 'root-store';

let rootStore: RootStore;

export async function setupRootStore() {
  if (rootStore) {
    return rootStore;
  }

  let data: any;

  try {
    // load data from storage
    data = (await storage.load(ROOT_STATE_STORAGE_KEY)) || {};
    // Change previous page id from url to timesaved.
    if (data.savedPages) {
      console.log('Converting saved pages id format: ', data.savedPages);
      const pages = Object.entries(data.savedPages);
      data.savedPages = Object.fromEntries(pages.map((kv) => {
        const page = JSON.parse(JSON.stringify(kv[1]));
        page.id = page.timeSaved.toString();
        page.siteId = page.siteId || '';
        return [page.id, page];
      }));
      console.log('Converted saved pages id format: ', data.savedPages);
    }
    if (data.forums === undefined) {
      data.forums = { 'huaren.us': 'huaren.us' };
    }
    rootStore = RootStoreModel.create(data);
  } catch (e: any) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({ });

    // but please inform us what happened
    if (__DEV__) {
      console.error(e.message, null);
    }
  }

  // track changes & save to storage
  onSnapshot(rootStore, (snapshot) => {
    // console.log('Save store snapshot: ', snapshot);
    storage.save(ROOT_STATE_STORAGE_KEY, snapshot);
  });

  return rootStore;
}
