import * as React from 'react';
import {
  View, FlatList, ActivityIndicator, NativeSyntheticEvent,
  NativeScrollEvent, StyleSheet, Platform, RefreshControl,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { colors, Icon, Text } from 'react-native-elements';
import { useTheme } from '@react-navigation/native';
import { useDimension } from '../context';

const refreshControl = require('react-native-web-refresh-control');

export interface PagedFeedResult<T> {
  items: T[];
  hasMore: boolean;
}

export interface PagedFeedProps<T> {
  inputRef?: React.MutableRefObject<any>;
  keyExtractor: (item: T) => string;
  renderItem: (item: T) => React.ReactElement | null;
  fetchPageFun: (page: number) => Promise<PagedFeedResult<T>>;
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

export type PagedFeedRef = {
  reload: () => void;
  scrollToIndex: (i: number) => void;
};

/**
 * PagedFeed component renders a list of items can be fetched by pages.
 */
function PagedFeed<T>(props: PagedFeedProps<T>) {
  const {
    keyExtractor, renderItem, fetchPageFun, onScroll, inputRef,
  } = props;
  const [loaded, setIsLoaded] = React.useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [items, setItems] = React.useState<Array<T>>([]);
  const [itemIds, setItemIds] = React.useState<Set<string>>(new Set());
  const listRef = React.useRef<FlatList>(null);
  const { height } = useDimension();
  const { colors: { border } } = useTheme();

  console.log(`Render Paged feed to page ${page}, has more: ${hasMore}`);

  const fetchInitialFeed = async () => {
    setIsLoadingInitial(true);
    return fetchPageFun(1)
      .then((result) => {
        const itemIds: Set<string> = new Set();
        const itemDedup = result.items.filter((i) => {
          if (!itemIds.has(keyExtractor(i))) {
            itemIds.add(keyExtractor(i));
            return true;
          }
          return false;
        });
        setItemIds(itemIds);
        setItems(itemDedup);
        setHasMore(result.hasMore);
        setPage(1);
      })
      .catch((error) => {
        showMessage({
          message: error.message,
          type: 'danger',
        });
      })
      .finally(() => {
        setIsLoadingInitial(false);
      });
  };

  const fetchMoreFeed = async (page: number) => {
    setIsLoadingMore(true);
    return fetchPageFun(page)
      .then((result) => {
        const itemDedup = result.items.filter((i) => !itemIds.has(keyExtractor(i)));
        setItems(items.concat(itemDedup));
        itemDedup.forEach((i) => itemIds.add(keyExtractor(i)));
        setPage(page);
        setHasMore(result.hasMore);
      })
      .catch((error) => {
        showMessage({
          message: error.message,
          type: 'danger',
        });
      })
      .finally(() => setIsLoadingMore(false));
  };

  const loadInitial = () => {
    if (!isLoadingInitial && !isLoadingMore) {
      fetchInitialFeed().then(() => setIsLoaded(true));
    }
  };

  const loadMore = () => {
    if (!isLoadingInitial && !isLoadingMore && hasMore) {
      fetchMoreFeed(page + 1);
    }
  };

  React.useEffect(() => {
    loadInitial();
  }, []);

  React.useImperativeHandle(inputRef, () => ({
    reload() {
      loadInitial();
    },
    scrollToIndex(i: number) {
      if (items.length > 0) { listRef.current?.scrollToIndex({ animated: true, index: i }); }
    },
  }));

  const Footer = () => {
    if (isLoadingMore) {
      return (
        <ActivityIndicator size="large" color={colors.grey3} />
      );
    }
    if (!hasMore && items.length !== 0) {
      return (
        <Icon type="material-community" name="sunglasses" size={30} color={colors.grey3} tvParallaxProperties/>
      );
    }
    return (
      <View />
    );
  };

  const EmptyComponent = () => (
    <View style={[styles.empty, { paddingTop: height / 3 }]}>
      <Text style={styles.emptyTitle}>No content found 🤔</Text>
    </View>
  );

  const Separator = () => <View style={[styles.separator, { backgroundColor: border }]} />;

  if (loaded) {
    return (
      <FlatList<T>
        ref={listRef}
        style={styles.list}
        data={items}
        refreshing={isLoadingInitial}
        onScroll={onScroll}
        keyExtractor={keyExtractor}
        renderItem={({ item }) => renderItem(item)}
        onRefresh={loadInitial}
        onEndReached={loadMore}
        ListFooterComponent={Footer}
        ListFooterComponentStyle={styles.footer}
        ListEmptyComponent={EmptyComponent}
        ItemSeparatorComponent={Separator}
        windowSize={3}
        refreshControl={
        Platform.OS === 'web'
          ? (
            <refreshControl.RefreshControl
              refreshing={isLoadingInitial}
              onRefresh={loadInitial}
            />
          ) : (
            <RefreshControl
              refreshing={isLoadingInitial}
              onRefresh={loadInitial}
              tintColor={colors.grey3}
            />
          )
      }
      />
    );
  }
  return (
    <View style={styles.loading}>
      <ActivityIndicator size="large" color={colors.grey3} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    justifyContent: 'center',
    opacity: 0.6,
  },
  list: {
    height: '100%',
    width: '100%',
  },
  separator: {
    height: 1,
  },
  footer: {
    padding: 20,
    alignContent: 'center',
    justifyContent: 'center',
  },
  empty: {
    justifyContent: 'center',
  },
  emptyTitle: {
    fontSize: 18,
    color: colors.grey3,
    paddingVertical: 20,
    textAlign: 'center',
  },
  loading: {
    height: '100%',
    justifyContent: 'center',
  },
});

export { PagedFeed };
