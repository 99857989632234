/* eslint-disable class-methods-use-this */
import {
  PostPage, PostItem, User, IndexPost, PostBase, Topics, Video,
} from '../types';
import { ParserBase, parseVideoUrl } from './parserBase';

const BOARD_TO_TOPICS_MAP = new Map([
  ['18', Topics.Politics],
  ['15', Topics.Politics],
  ['1', Topics.Politics],
  ['6', Topics.Gossip],
  ['5', Topics.Joke],
  ['10', Topics.Politics],
  ['7', Topics.History],
  ['4', Topics.Other],
  ['11', Topics.Other],
  ['14', Topics.Gossip],
  ['2', Topics.Gossip],
]);

class ParserPinCong extends ParserBase {
  SOURCE = 'pincong.rocks';

  ID_PREFIX = 'pincong-';

  BASE_URL = 'https://pincong.rocks';

  HTTP2 = true;

  getIndexPageUrls(): string[] {
    return [
      `${this.BASE_URL}/sort_type-new`,
      `${this.BASE_URL}/recommend-1`,
      `${this.BASE_URL}/sort_type-hot__day-2`,
    ];
  }

  parseIndexPage(html: string, url: string): IndexPost[] {
    const $ = this.getCheerio(html, url);
    const postItems = $('.aw-item .aw-question-content')
      .map((i: number, el: any) => {
        const url = $('a', el).attr('href');
        const categoryUrl = $('.aw-question-tags', el).attr('href');
        const category = categoryUrl.split('-')[1];
        return {
          id: this.getPostIdAndPage(url)[0],
          extraData: { category },
        };
      })
      .get();
    return postItems;
  }

  extractVideo(html: string): Video[] {
    const $ = this.getCheerio(html, '');
    const videos = $('iframe')
      .map((i: number, e: any) => parseVideoUrl(e.attribs.src))
      .get();
    return videos;
  }

  parsePostImpl(html: string, url: string): PostPage | string {
    const $ = this.getCheerio(html, url);
    if ($('body').text() === 'Not Found') {
      return '文章不存在';
    }
    const currentPage = this.getPostIdAndPage(url)[1];
    const totalPage = 1;

    $('blockquote a').each((i: number, el: any) => {
      const element = el;
      element.tagName = 'span';
    });

    $('.content a').each((i: number, el: any) => {
      const element = el;
      if (element.attribs.title.startsWith('https://i.imgur.com/')) {
        element.tagName = 'div';
      }
    });

    $('.content img').each((i: number, el: any) => {
      const element = el;
      element.attribs.style = '';
    });

    const usernameMain = $('.col-sm-12.col-md-3.aw-side-bar .aw-user-name').text().trim();
    const userMain: User = {
      id: this.ID_PREFIX + usernameMain,
      username: usernameMain,
      profileImage: $('.col-sm-12.col-md-3.aw-side-bar .aw-user-img img').attr('src'),
      sourceName: this.SOURCE,
      blocked: false,
    };

    let timePublishedStringMain = '';
    if ($('.pull-right.more-operate em')[0]) {
      timePublishedStringMain = $($('.pull-right.more-operate em')[0]).text();
    } else {
      timePublishedStringMain = $('.aw-question-detail .mod-footer .meta span').text();
    }
    const timePublishedMain = ParserBase.parseTime(timePublishedStringMain, 'YYYY-MM-DD', 'Pacific/Tongatapu');
    const video = $('.aw-question-detail .ab-video')[0]
      ? `${$('.aw-question-detail .ab-video').html()}<br>` : '';

    const mainItem: PostItem = {
      id: '1',
      content: `<div>${video}${$('.content').html()}</div>`,
      upVotes: parseInt($('.aw-question-detail .aw-article-vote .count').text(), 10) || 0,
      downVotes: 0,
      user: userMain,
      timePublished: timePublishedMain,
      images: [],
      videos: [],
    };

    const items: PostItem[] = $('.aw-replies .aw-item')
      .map((i: number, el: any) => {
        const id = i + 2;
        const replyToUsername = $('.aw-user-name', el)[1];
        if (replyToUsername) {
          $('blockquote', el).each((i1: number, el1: any) => {
            $(el1).prepend(`<div>回复 <b>${$(replyToUsername).text()}</b></div>`);
          });
        }
        const content = `<div>${$('.markitup-box', el).html()}</div>`;
        const timePublishedString = $('.mod-footer .meta .pull-right', el).text();
        const timePublished = ParserBase.parseTime(timePublishedString, 'YYYY-MM-DD', 'Pacific/Tongatapu');
        const username = $($('.aw-user-name', el)[0]).text().trim();
        const user: User = {
          id: this.ID_PREFIX + username,
          username,
          profileImage: $('.aw-user-img img', el).attr('src'),
          sourceName: this.SOURCE,
          blocked: false,
        };
        const postItem: PostItem = {
          id: id.toString(),
          content,
          user,
          upVotes: parseInt($('.agree .count', el).text().trim(), 10) || 0,
          downVotes: 0,
          timePublished,
          images: [],
          videos: [],
        };
        return postItem;
      })
      .get();

    const title = $('.mod-head h1').text().trim();
    const comments = parseInt($('.mod-head h2', $('.aw-main-content .aw-mod')[2]).text().split(' ')[0], 10) || 0;
    return {
      currentPage, totalPage, items: currentPage === 1 ? [mainItem].concat(items) : items, title, comments, topicId: '',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPostUrl(id: string, page: number, isMobile: boolean = true): string {
    const [postType, postId] = ParserBase.stripIdPrefix(id).split('_');
    return `${this.BASE_URL}/${postType}/id-${postId}__page-${page}`;
  }

  getPostIdAndPage(url: string): [string, number] {
    const { hostname, pathname, searchParams } = new URL(url);
    if ((hostname === 'pincong.rocks')) {
      const tokens = pathname.split('/');
      if (tokens.length >= 3) {
        const postType = tokens[1];
        let postId = '';
        let page = 1;
        if (tokens[2].includes('__')) {
          const paramMap: Map<string, string> = new Map(
            tokens[2].split('__')
              .map((t: string) => t.split('-'))
              .map((t: string[]) => [t[0], t[1]]),
          );
          postId = paramMap.get('id') || '';
          page = parseInt(paramMap.get('page') || '', 10) || 1;
        } else {
          // eslint-disable-next-line prefer-destructuring
          postId = tokens[2];
          page = parseInt(searchParams.get('page') || '', 10) || 1;
        }
        return [`${this.ID_PREFIX + postType}_${postId}`, page];
      }
    }
    return ['', 0];
  }

  applyExtraData(post: PostBase, extraData: any) {
    const postWithExtraData = post;
    postWithExtraData.topicId = BOARD_TO_TOPICS_MAP.get(extraData.category) || Topics.Other;
    return postWithExtraData;
  }
}

export const parser = new ParserPinCong();
