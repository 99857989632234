import * as React from 'react';
import { StyleSheet, Platform } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useIsFocused } from '@react-navigation/native';
import { colors, Icon } from 'react-native-elements';

import { Notification as NotificationModel } from '@topicfeed/common/types';
import {
  View, Notification, TimelineFeed, TimelineFeedRef,
} from '@topicfeed/appcommon/components';
import { useStores } from '@topicfeed/appcommon/models';
import { getNotifications } from '@topicfeed/appcommon/utils/api';

import { RootTabScreenProps } from '../types';

const NotificationsScreen = observer(({ navigation }: RootTabScreenProps<'Notifications'>) => {
  const {
    hasNewNotification, refreshNotifications, setTimeNotificationRead, setHasNewNotification,
    setRefreshNotifications, settings,
  } = useStores();
  const feedRef = React.useRef<TimelineFeedRef>(null);
  const isFocused = useIsFocused();

  const refresh = () => {
    feedRef.current?.reload();
    feedRef.current?.scrollToIndex(0);
  };

  React.useEffect(() => {
    if (isFocused) {
      if (hasNewNotification || refreshNotifications) {
        refresh();
      }
    }
  }, [hasNewNotification, refreshNotifications, isFocused]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (Platform.OS === 'web'
        ? (
          <Icon
            type="material-community"
            name="reload"
            color={colors.primary}
            onPress={refresh}
            containerStyle={styles.headerIcon}
            tvParallaxProperties
          />
        ) : null
      ),
    });
  }, []);

  const fetchNotifications = async (after?: number, before?: number) => {
    try {
      const response = await getNotifications(after, before);

      const { items, hasMore } = response;
      let nextAfter = after !== undefined ? after : (before || 0);
      let nextBefore = before !== undefined ? before : (after || 0);
      if (items.length > 0) {
        nextAfter = items[0].timeCreated;
        nextBefore = items[items.length - 1].timeCreated;
      }
      console.log(`Fetched ${items.length} notifications, next cursor (${nextAfter}, ${nextBefore}), has more: ${hasMore}`);
      return {
        items, hasMore, after: nextAfter, before: nextBefore,
      };
    } catch (error: any) {
      console.log(error.message);
      throw new Error('Can not get notifications, please try again later.');
    }
  };

  const fetchLatestFn = React.useCallback(
    async (after: number) => {
      if (hasNewNotification) { setHasNewNotification(false); }
      if (refreshNotifications) { setRefreshNotifications(false); }
      setTimeNotificationRead(new Date().getTime());
      return fetchNotifications(after);
    }, [hasNewNotification, refreshNotifications],
  );

  return (
    <View style={styles.container}>
      <TimelineFeed<NotificationModel>
        inputRef={feedRef}
        keyExtractor={(item) => item.id}
        renderItem={(item) => (
          <Notification
            notification={item}
            onPress={(id: string) => navigation.navigate('Post', { id })}
            usePostReaderView={settings.usePostReaderView}
          />
        )}
        fetchLatestFun={fetchLatestFn}
        fetchPreviousFun={async (before: number) => fetchNotifications(undefined, before)}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  headerIcon: {
    paddingHorizontal: 20,
  },
});

export default NotificationsScreen;
