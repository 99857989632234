import * as React from 'react';
import {
  ActivityIndicator, StyleSheet, TouchableOpacity, Platform, Share, ImageBackground, ViewStyle,
} from 'react-native';
import {
  Text, colors, Image, Badge,
} from 'react-native-elements';
import { SwiperFlatList } from 'react-native-swiper-flatlist';
import { useTheme } from '@react-navigation/native';

import { Post, VideoType } from '@topicfeed/common/types';
import { Icon } from './Icon';
import { View } from './Themed';
import {
  usePostQuery, useUserQuery, useSourceQuery, useTopicSourceQuery,
} from '../hooks/queries';
import {
  useFollowPostMutation, useHidePostMutation, useSavePostMutation, useBlockUserMutation,
  useSavePostViewPostionMutation, useBlockTopicSourceMutation,
} from '../hooks/mutations';
import { Avatar } from './Avatar';
import { BottomMenu, BottomMenuRef } from './BottomMenu';
import { MenuItem } from './MenuItem';
import { alert, openURL } from '../utils/polyfill';

const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const THUMBNAIL_SIZE = 65;

interface ImagePreview {
  height: number;
  width: number;
  url: string;
  logoType?: string;
  logoName?: string;
}

function getLogoStyle(
  imageWidth: number, imageHeight: number, frameWidth: number, frameHeight: number,
): ViewStyle {
  const OFFSET = 20;
  let IMAGE_MARGIN = 0;
  if (imageWidth >= imageHeight) {
    IMAGE_MARGIN = (frameHeight - (frameWidth * imageHeight) / imageWidth) / 2;
  } else {
    IMAGE_MARGIN = (frameWidth - (frameHeight * imageWidth) / imageHeight) / 2;
  }
  if (imageWidth >= imageHeight) {
    return {
      ...styles.imageLogo,
      marginBottom: OFFSET + IMAGE_MARGIN,
      marginRight: OFFSET,
    };
  }
  return {
    ...styles.imageLogo,
    marginBottom: OFFSET,
    marginRight: OFFSET + IMAGE_MARGIN,
  };
}

export interface PostPreviewContentProps {
  post: Post;
  width: number;
  isSimple?: boolean;
  onPress: (id: string) => void;
}

export function PostPreviewContent(props: PostPreviewContentProps) {
  const {
    post, width, isSimple, onPress,
  } = props;
  const savePostViewPositionMutation = useSavePostViewPostionMutation();
  const { colors: { background } } = useTheme();
  const images: ImagePreview[] = (post.images || [])
    .filter((image) => Platform.OS !== 'web' || (
      !image.url.startsWith('https://mitbbs.com')
      && !image.url.startsWith('https://www.mitbbs.com')
      && !image.url.startsWith('https://www.popo8.com')
      && !image.url.startsWith('https://web.popo8.com')
      && !image.url.startsWith('https://oss.1point3acres.cn')))
    .filter((image) => image.width / image.height < MAX_ASPECT_RATIO_LANDSCAPE
    && image.height / image.width < MAX_ASPECT_RATIO_PORTRAIT)
    .filter((image) => !image.url.endsWith('.gif'))
    .slice(0, NUM_IMAGES_TO_SHOW)
    .map((image) => ({
      url: image.url,
      width: image.width,
      height: image.height,
    }));
  const videos: ImagePreview[] = (post.videos || [])
    .map((video) => {
      let logoName = '';
      if (video.type === VideoType.YouTube) {
        logoName = 'youtube';
      }
      return {
        url: `https://img.youtube.com/vi/${video.id}/0.jpg`,
        width,
        height: (width * 3) / 4,
        logoType: 'font-awesome',
        logoName,
      };
    })
    .filter((preview) => !!preview.logoName);
  const imagePreviews = images.concat(videos);
  const heights = imagePreviews.map((image) => (width * image.height) / image.width);
  const height = Math.min(Math.max(...heights), width);
  const showImage = !post.hideImage && imagePreviews.length > 0;

  const onPressContent = () => {
    if (Platform.OS === 'web') {
      savePostViewPositionMutation.mutate({ id: post.id, mUrl: post.mUrl });
      openURL(`https://topicfeed.app/posts/${post.id}`);
    } else {
      onPress(post.id);
    }
  };

  const headers = { Referer: post.mUrl };
  const textToDisplay = post.text.slice(0, 100) + (post.text.length > 100 ? '...' : '');
  const textToDisplayShort = post.text.slice(0, 50) + (post.text.length > 50 ? '...' : '');
  if (showImage) {
    if (isSimple) {
      const titleNumOfLines = post.text.length > 0 ? 1 : 2;
      return (
        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.contentSimple} onPress={onPressContent} delayPressIn={100}>
          <View style={{ flex: 1 }}>
            <Text style={styles.title} numberOfLines={titleNumOfLines}>{post.title}</Text>
            { post.text.length > 0
            && <>
            { Platform.OS === 'web' ? (
              <Text style={styles.text}>{textToDisplayShort}</Text>
            ) : (
              <Text numberOfLines={1} style={styles.text}>{post.text}</Text>)
            }
            </>}
          </View>
          <Image
            source={{ uri: imagePreviews[0].url, headers }}
            style={[
              styles.imageSimple,
              {
                overlayColor: background,
                width: THUMBNAIL_SIZE,
                height: THUMBNAIL_SIZE,
              },
            ]}
            resizeMode="cover"
            width= {THUMBNAIL_SIZE}
            height={THUMBNAIL_SIZE}
          />
        </TouchableOpacity>
      );
    }
    return (
      <>
        <TouchableOpacity activeOpacity={0.6} onPress={onPressContent} delayPressIn={100}>
          <Text style={styles.title} numberOfLines={2}>{post.title}</Text>
        </TouchableOpacity>
        <SwiperFlatList
          style={styles.swiper}
          showPagination={imagePreviews.length > 1}
          data={imagePreviews}
          paginationStyle={styles.pagination}
          paginationStyleItemActive={styles.activeItem}
          paginationStyleItemInactive={styles.inactiveItem}
          renderItem={({ item }) => (
            <TouchableOpacity activeOpacity={0.8} onPress={onPressContent} delayPressIn={100}>
              {/* @ts-ignore: children not exists on property */}
              <ImageBackground
                source={{ uri: item.url, headers }}
                style={[styles.imagePreview, { width, height }]}
                resizeMode="contain"
              >
                { item.logoName && (
                <Icon
                  style={getLogoStyle(item.width, item.height, width, height)}
                  type={item.logoType}
                  name={item.logoName}
                  size={40}
                  color={colors.grey5}
                />
                )}
              </ImageBackground>
            </TouchableOpacity>
          )}
        />
      </>
    );
  }
  return (
    <TouchableOpacity
      activeOpacity={0.6} onPress={onPressContent} delayPressIn={100}>
      <Text style={styles.title} numberOfLines={2}>{post.title}</Text>
      { post.text.length > 0 && <>
      { isSimple ? (
        <>
          { Platform.OS === 'web' ? (
            <Text style={styles.text}>{textToDisplayShort}</Text>
          ) : (
            <Text numberOfLines={1} style={styles.text}>{post.text}</Text>
          )}
        </>
      ) : (
        <>
          { Platform.OS === 'web' ? (
            <Text style={styles.text}>{textToDisplay}</Text>
          ) : (
            <Text numberOfLines={4} style={styles.text}>{post.text}</Text>
          )}
        </>
      )}</>}
    </TouchableOpacity>
  );
}

export interface PostPreviewProps {
  postData: Post;
  width: number;
  notRenderFn?: (post: Post) => boolean;
  isSimple?: boolean;
  usePostReaderView?: boolean;
  showTopicSourceFilter?: boolean;
  showUser?: boolean;
  showTopic?: boolean;
  showSource?: boolean;
  showSourceTitle?: boolean;
  showBoard?: boolean;
  showNotification?: boolean;
  showSavePost?: boolean;
  checkAndRedirectToSignIn: () => boolean;
  onReportPost: (id: string) => void;
  onPress: (id: string) => void;
}

const ICON_SIZE = 20;
const HIT_SLOP = 25;
const NUM_IMAGES_TO_SHOW = 10;
const MAX_ASPECT_RATIO_LANDSCAPE = 2;
const MAX_ASPECT_RATIO_PORTRAIT = 2;

function PostPreviewImpl(props: PostPreviewProps) {
  const {
    postData, width, notRenderFn, isSimple,
    showTopicSourceFilter, showUser, showTopic, showSource, showBoard,
    showNotification, showSavePost, showSourceTitle,
    onReportPost, checkAndRedirectToSignIn, onPress,
  } = props;
  const { data: post } = usePostQuery(postData.id, postData);
  const { data: source } = useSourceQuery(
    postData.source0.name, postData.source0,
  );
  const { data: topicSource } = useTopicSourceQuery(
    postData.topic?.id || '', postData.source0.name, postData.topicSource,
  );
  const userId = postData.user?.id || '';
  const { data: user } = useUserQuery(userId, userId.length > 0, postData.user);
  const followPostMutation = useFollowPostMutation(postData.id);
  const savePostMutation = useSavePostMutation(postData.id);
  const hidePostMutation = useHidePostMutation(postData.id);
  const blockUserMutation = useBlockUserMutation(postData.user?.id || '', postData.id);
  const blockTopicSourceMutation = useBlockTopicSourceMutation();

  if (!post) {
    return null;
  }

  const bottomMenu = React.useRef<BottomMenuRef>(null);

  const onShare = () => {
    if (Platform.OS === 'ios') {
      Share.share({ url: post.mUrl });
    } else {
      Share.share({ message: post.mUrl });
    }
  };

  const bottomMenuList: MenuItem[] = showSavePost ? [
    {
      title: post.saved ? 'Unsave post' : 'Save post',
      onPress: () => {
        if (checkAndRedirectToSignIn()) {
          savePostMutation.mutate({ id: post.id, save: !post.saved });
        }
      },
      iconType: 'ionicon',
      iconName: post.saved ? 'bookmark' : 'bookmark-outline',
      iconColor: post.saved ? colors.primary : colors.grey3,
    },
  ] : [];
  bottomMenuList.push(
    {
      title: 'Hide post',
      onPress: () => {
        if (checkAndRedirectToSignIn()) {
          alert(
            'Do you want to hide this post?',
            `${post.title}`,
            [
              { text: 'Cancel', style: 'cancel' },
              {
                text: 'OK',
                onPress: () => hidePostMutation.mutate({ id: post.id, hide: true }),
              },
            ],
            { cancelable: true },
          );
        }
      },
      iconType: 'ionicon',
      iconName: 'eye-off-outline',
    },
    {
      title: 'Block user',
      onPress: () => {
        if (checkAndRedirectToSignIn()) {
          alert(
            'Do you want to block this user?',
            user ? `${user.username}${user.sourceName ? `@${user.sourceName}` : ''} ` : '',
            [
              { text: 'Cancel', style: 'cancel' },
              {
                text: 'OK',
                onPress: () => {
                  blockUserMutation.mutate({ id: user?.id || '', postId: post.id, block: true });
                },
              },
            ],
            { cancelable: true },
          );
        }
      },
      iconType: 'ionicon',
      iconName: 'person-remove-outline',
    },
    {
      title: post.reported ? 'Post reported' : 'Report post',
      onPress: () => {
        if (checkAndRedirectToSignIn()) { onReportPost(post.id); }
      },
      iconType: 'ionicon',
      iconName: post.reported ? 'flag' : 'flag-outline',
      iconColor: colors.grey3,
      disabled: post.reported,
    },
  );

  if (showTopicSourceFilter) {
    bottomMenuList.push(
      {
        title: `Filter ${post.topic?.name} posts from ${post.source0.name}`,
        onPress: () => {
          if (checkAndRedirectToSignIn()) {
            alert(
              `Do not want to see posts from ${post.source0.name} on ${post.topic?.name}?`,
              '',
              [
                { text: 'Cancel', style: 'cancel' },
                {
                  text: 'OK',
                  onPress: () => {
                    blockTopicSourceMutation.mutate(
                      { topicId: post.topic?.id || '', sourceName: post.source0.name, block: true },
                    );
                  },
                },
              ],
              { cancelable: true },
            );
          }
        },
        iconType: 'ionicon',
        iconName: 'funnel-outline',
      },
      {
        title: `Filter all posts from ${post.source0.name}`,
        onPress: () => {
          if (checkAndRedirectToSignIn()) {
            alert(
              `Do not want to see all posts from ${post.source0.name}?`,
              '',
              [
                { text: 'Cancel', style: 'cancel' },
                {
                  text: 'OK',
                  onPress: () => {
                    blockTopicSourceMutation.mutate(
                      { topicId: '', sourceName: post.source0.name, block: true },
                    );
                  },
                },
              ],
              { cancelable: true },
            );
          }
        },
        iconType: 'ionicon',
        iconName: 'funnel-outline',
      },
    );
  }

  if (user?.blocked || source?.blocked || topicSource?.blocked
    || post.hidden || (notRenderFn && notRenderFn(post))) {
    return (<View />);
  }

  const headerIcons = [];
  if (showUser && user) {
    headerIcons.push(
      <Avatar
        key="0"
        size={20}
        imageSrc={user?.profileImage}
        name={user?.username}
        rounded
      />,
      <Text key="1" style={styles.username}>
        {user?.username}
      </Text>,
    );
  }
  if (showTopic && post.topic) {
    headerIcons.push(
      <Text key="3" style={styles.topic}>
        {post.topic?.name}
      </Text>,
    );
  }
  if (showBoard && post.board) {
    headerIcons.push(
      <Avatar
        key="4"
        size={20}
        name={post.board.label}
        rounded
      />,
      <Text key="5" style={styles.username}>{post.board.label}</Text>,
    );
  }
  if (showSource && post.source0) {
    if (headerIcons.length > 0) {
      headerIcons.push(<View key="6" style={styles.dot} />);
    }
    headerIcons.push(
      <Text key="7" style={styles.source}>{showSourceTitle ? post.source0.title : post.source0.name}</Text>,
    );
  }
  const showHeader = headerIcons.length > 0 || post.timePublished;

  const footerIcons = [];
  let hasNewComment = false;
  if (post.comments !== undefined && post.viewPosition?.comments !== undefined) {
    hasNewComment = post.comments > post.viewPosition.comments;
  }
  if (post.comments !== undefined) {
    footerIcons.push(
      <View style={styles.iconContainer} key="0">
        <View>
          <Icon type="material-community" name="comment-outline" color={colors.grey3} size={ICON_SIZE} disabled />
          {hasNewComment && <Badge containerStyle={{ position: 'absolute', top: 0, right: 0 }}/>}
        </View>
        <Text style={styles.iconText}>{post.comments}</Text>
      </View>,
    );
  }
  if (showNotification) {
    if (followPostMutation.isLoading) {
      footerIcons.push(<ActivityIndicator size="small" color={colors.grey3} key="1"/>);
    } else {
      footerIcons.push(<Icon
        key="1"
        type="material-community"
        name={post.following ? 'bell' : 'bell-outline'}
        color={post.following ? colors.primary : colors.grey3}
        onPress={() => {
          if (checkAndRedirectToSignIn()) {
            followPostMutation.mutate(
              { id: post.id, follow: !post.following },
            );
          }
        }}
        size={ICON_SIZE}
        hitSlopSize={HIT_SLOP}
      />);
    }
  }
  footerIcons.push(
    <Icon
      key="3"
      type="ionicons"
      name="ios-share"
      color={colors.grey3}
      onPress={onShare}
      size={ICON_SIZE}
      hitSlopSize={HIT_SLOP}
    />,
    <Icon
      key="4"
      type="material-community"
      name="dots-horizontal"
      color={colors.grey3}
      onPress={() => bottomMenu.current?.show()}
      size={ICON_SIZE}
      hitSlopSize={HIT_SLOP}
    />,
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        { showHeader && <>
        <View style={styles.headerLeft}>
          { headerIcons }
        </View>
        <View style={styles.headerRight}>
          {post.timePublished !== undefined
          && <Text style={styles.time}>{dayjs(post.timePublished).fromNow()}</Text>}
        </View>
        </>}
      </View>
      <PostPreviewContent
        width={width}
        post={post}
        isSimple={isSimple}
        onPress={onPress}
      />
      <View style={styles.footer}>
        { footerIcons }
      </View>
      <BottomMenu
        ref={bottomMenu}
        itemList={bottomMenuList}
      />
    </View>
  );
}

export const PostPreview = React.memo(PostPreviewImpl);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 15,
    paddingHorizontal: 20,
  },
  topic: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  username: {
    fontWeight: 'bold',
    fontSize: 15,
    marginLeft: 5,
  },
  source: {
    fontSize: 16,
    color: colors.grey3,
  },
  dot: {
    borderRadius: 3,
    height: 3,
    width: 3,
    backgroundColor: colors.grey3,
    marginHorizontal: 10,
  },
  time: {
    fontSize: 15,
    color: colors.grey3,
  },
  title: {
    fontSize: 18,
    lineHeight: 25,
    fontWeight: 'bold',
    paddingHorizontal: 8,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    padding: 8,
  },
  swiper: {
    paddingVertical: 8,
  },
  activeItem: {
    width: 8,
    height: 8,
    backgroundColor: colors.grey5,
  },
  inactiveItem: {
    width: 5,
    height: 5,
    backgroundColor: colors.grey2,
  },
  pagination: {
    bottom: 60, alignItems: 'center',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconText: {
    paddingHorizontal: 10,
    color: colors.grey3,
    fontSize: 16,
  },
  contentSimple: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingRight: Platform.OS === 'web' ? 8 : 0,
  },
  imageSimple: {
    marginHorizontal: 20,
    borderRadius: 5,
  },
  imagePreview: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  imageLogo: {
    opacity: 0.8,
  },
});
