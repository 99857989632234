import * as React from 'react';
import {
  StyleSheet, Share, ActivityIndicator, Platform,
  PanResponder, GestureResponderEvent, PanResponderGestureState,
  ViewStyle,
} from 'react-native';
import { WebView } from 'react-native-webview';
import { WebViewMessageEvent, WebViewNativeEvent, WebViewNavigation } from 'react-native-webview/lib/WebViewTypes';
import { Text, Button, colors } from 'react-native-elements';
import DrawerLayout from 'react-native-gesture-handler/DrawerLayout';
import Dialog from 'react-native-dialog';
import { useTheme } from '@react-navigation/native';
import { useQueryClient } from 'react-query';
import { getParserById } from '@topicfeed/common/parsers';

import { Post, PostItem } from '@topicfeed/common/types';
import { View } from './Themed';
import { getMainDomainFromUrl } from '../utils/parser';
import { FooterBar } from './FooterBar';
import { MenuItem } from './MenuItem';
import { PostView, PostViewRef } from './PostView';
import { DrawerMenu } from './DrawerMenu';
import { usePostQuery, usePostViewPositionQuery, usePostShortUrlQuery } from '../hooks/queries';
import {
  useFollowPostMutation, useHidePostMutation, useSavePostMutation, useBlockUserMutation,
  useSavePostViewPostionMutation, useBlockTopicSourceMutation,
} from '../hooks/mutations';
import { getRedirectUrl } from '../utils/api';
import { alert, openURL } from '../utils/polyfill';
import { useDimension } from '../context';
import { useStores } from '../models';

type Props = {
  id: string,
  page?: number,
  readerView: boolean,
  navigateBack: () => void,
  onReportPost: (id: string) => void,
  checkAndRedirectToSignIn: () => boolean,
  showTopicSourceFilter?: boolean,
  showNotification?: boolean,
  showSavePost?: boolean,
  navigateToPostItem: (url: string, js: string, title: string) => void,
  showSourceTitle?: boolean,
  showTopic?: boolean,
  appName: string,
};

const WIDTH_CAPTURE_GESTURE = 10;
const DEFAULT_ERROR_MESSAGE = 'Can not open the reader view 😞';

function PostWebView({
  id, page, readerView, navigateBack, onReportPost, checkAndRedirectToSignIn,
  showNotification, showSavePost, showTopicSourceFilter, navigateToPostItem,
  showSourceTitle, showTopic, appName,
}: Props) {
  const queryClient = useQueryClient();
  const parser = getParserById(id);
  const url = parser?.getPostUrl(id, page || 1, true) || '';
  const webviewRef = React.useRef<WebView>(null);
  const postViewRef = React.useRef<PostViewRef>(null);
  const drawerRef = React.useRef<DrawerLayout>(null);
  //   const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [webUrl, setWebUrl] = React.useState('');
  const [webTitle, setWebTitle] = React.useState('');
  const [canGoBack, setCanGoBack] = React.useState(false);
  const [canGoForward, setCanGoForward] = React.useState(false);
  const [isLoadingWeb, setIsLoadingWeb] = React.useState(true);
  const [isLoadingPostItems, setIsLoadingPostItems] = React.useState(true);
  const [isLoadingPostItemsPrev, setIsLoadingPostItemsPrev] = React.useState(false);
  const [isLoadingPostItemsNext, setIsLoadingPostItemsNext] = React.useState(false);
  const [isReaderView, setIsReaderView] = React.useState(readerView || false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = React.useState(1);
  const [startPage, setStartPage] = React.useState(1);
  const [endPage, setEndPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [hasNext, setHasNext] = React.useState(false);
  const [hasPrevious, setHasPrevious] = React.useState(false);
  const [postItems, setPostItems] = React.useState<PostItem[]>([]);
  const [postItemIds, setPostItemIds] = React.useState<Set<string>>(new Set());
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isPageInputVisible, setIsPageInputVisible] = React.useState(false);
  const [inputPage, setInputPage] = React.useState('');
  const [inputPageError, setInputPageError] = React.useState('');
  const [showPromotion, setShowPromotion] = React.useState(false);
  const { developerMode } = useStores();

  const { data: post, isLoading: isLoadingPost } = usePostQuery(id);
  const {
    data: postViewPosition,
    isLoading: isLoadingPostViewPosition,
  } = usePostViewPositionQuery(id);
  const { data: shortUrl } = usePostShortUrlQuery(id, appName, showPromotion);
  const followPostMutation = useFollowPostMutation(id);
  const savePostMutation = useSavePostMutation(id);
  const hidePostMutation = useHidePostMutation(id, () => navigateBack());
  const blockUserMutation = useBlockUserMutation(post?.user?.id || '', id, () => navigateBack());
  const savePostViewPositionMutation = useSavePostViewPostionMutation();
  const blockTopicSourceMutation = useBlockTopicSourceMutation(() => navigateBack());
  const user = post?.user;
  const { width } = useDimension();
  const { colors } = useTheme();

  const navigateToPostItemWithJs = React.useCallback((
    postId: string, postItemId?: string, page?: number, js?: string,
  ) => {
    let url = '';
    if (postItemId) {
      url = parser?.getPostItemUrl(postId, postItemId, true, page) || '';
    } else {
      url = parser?.getPostUrl(postId, page || 1, true) || '';
    }
    navigateToPostItem(url, js || '', post?.title || '');
  }, [post]);

  const onCommentPost = React.useCallback((postId: string, postItemId?: string, page?: number) => {
    const js = parser?.getCommentJs(postId, postItemId);
    navigateToPostItemWithJs(postId, postItemId, page, js);
  }, [navigateToPostItemWithJs]);

  const onUpVotePost = React.useCallback((postId: string, postItemId?: string, page?: number) => {
    const js = parser?.getUpVoteJs(postId, postItemId);
    navigateToPostItemWithJs(postId, postItemId, page, js);
  }, [navigateToPostItemWithJs]);

  const handleNavChange = (navState: WebViewNativeEvent) => {
    setCanGoBack(navState.canGoBack);
    setCanGoForward(navState.canGoForward);
    setWebUrl(navState.url);
  };

  const onMessage = async (event: WebViewMessageEvent) => {
    const { data } = event.nativeEvent;
    const message = JSON.parse(data);
    if (message.event === 'navigationChange') {
      console.log('Receive nav change event from js: ', event.nativeEvent);
      handleNavChange(event.nativeEvent);
    } else if (message.event === 'html' || message.event === 'api') {
      const { url } = event.nativeEvent;
      console.log(`Receive ${message.event} event from webview: `, url);
      if (message.title) { setWebTitle(message.title); }
      if (parser?.isUrlMatchPostId(url, id || '')) {
        // Only parse page if it match the post id.
        try {
          const result = message.event === 'api'
            ? parser.parsePostApi(message.data) : parser.parsePost(message.html, url);
          if (typeof result === 'string') {
            setErrorMessage(result);
          } else {
            const {
              currentPage: cp, totalPage: tp, items, hasNext, hasPrevious, title, board, comments,
            } = result;
            // Update existing post in query cache.
            const newData: any = {
              source0: { name: parser.SOURCE, title: parser.TITLE },
              viewPosition: { id, mUrl: url, comments },
            };
            if (title) {
              newData.title = title;
            }
            if (board) {
              newData.board = board;
            }
            if (comments) {
              newData.comments = comments;
            }
            queryClient.setQueryData(['post', id], (oldData?: Post) => ({ ...oldData, ...newData }));
            savePostViewPositionMutation.mutate({ id, mUrl: url, comments });
            setTotalPage(tp);
            if (isLoadingPostItemsPrev) {
              console.log('Load previous items.');
              setStartPage(cp);
              setHasPrevious(hasPrevious !== undefined ? hasPrevious : cp > 1);
              const itemsDedup = items.filter((i) => !postItemIds.has(i.id));
              setPostItems(itemsDedup.concat(postItems));
              itemsDedup.forEach((i) => postItemIds.add(i.id));
              // setTimeout(() => {
              //   if (postItems.length > 0) { postViewRef.current?.scrollTo(0); }
              // }, 10);
            } else if (isLoadingPostItemsNext) {
              console.log('Load next items.');
              setEndPage(cp);
              setHasNext(hasNext !== undefined ? hasNext : cp < tp);
              const itemsDedup = items.filter((i) => !postItemIds.has(i.id));
              setPostItems(postItems.concat(itemsDedup));
              itemsDedup.forEach((i) => postItemIds.add(i.id));
            } else if (isLoadingPostItems) {
              console.log('Load initial items.');
              setCurrentPage(cp);
              setStartPage(cp);
              setEndPage(cp);
              setHasPrevious(hasPrevious !== undefined ? hasPrevious : cp > 1);
              setHasNext(hasNext !== undefined ? hasNext : cp < tp);
              setPostItems(items);
              setPostItemIds(new Set(items.map((i) => i.id)));
              // setTimeout(() => {
              //   if (postItems.length > 0) { postViewRef.current?.scrollTo(0); }
              // }, 10);
            }
            setErrorMessage('');
            console.log(`Parsed ${items.length} post items, has previous: ${hasPrevious}, has next: ${hasNext}.`);
          }
        } catch (error: any) {
          setErrorMessage(DEFAULT_ERROR_MESSAGE);
          console.log(`Error parsing post (${id}, ${url}): `, error);
        } finally {
          if (isLoadingPostItemsPrev) {
            setIsLoadingPostItemsPrev(false);
          } else if (isLoadingPostItemsNext) {
            setIsLoadingPostItemsNext(false);
          } else {
            setIsLoadingPostItems(false);
          }
        }
      } else if (isReaderView) {
        setIsReaderView(false);
      }
    }
  };

  const onNavChange = (navState: WebViewNavigation) => {
    console.log('Nav change from webview: ', navState);
    if (Platform.OS === 'android') {
      webviewRef.current?.injectJavaScript(JS_PATCH_WINDOW_HISTORY);
    }
    handleNavChange(navState);
  };

  const navigateToPostPage = (page: number) => {
    navigateTo(parser?.getPostUrl(id, page, true) || '');
  };

  const navigateTo = (href: string) => {
    webviewRef.current?.injectJavaScript(`window.location='${href}';`);
  };

  const scrollToBottom = () => {
    webviewRef.current?.injectJavaScript('window.scrollTo(0, document.body.scrollHeight);');
  };

  const scrollToTop = () => {
    webviewRef.current?.injectJavaScript('window.scrollTo(0, 0);');
  };

  const onEnterPageInput = () => {
    const parsedPageNumber = parseInt(inputPage, 10);
    if (!parsedPageNumber || parsedPageNumber > totalPage) {
      setInputPageError('Please enter a valid page number.');
      return;
    }
    setIsLoadingPostItems(true);
    navigateToPostPage(parsedPageNumber);
    setInputPageError('');
    setIsPageInputVisible(false);
  };

  const onCancelPageInput = () => {
    setInputPage('');
    setInputPageError('');
    setIsPageInputVisible(false);
  };

  const JS_PATCH_WINDOW_HISTORY = `
    (function() {
      function wrap(fn) {
        return function wrapper() {
          var res = fn.apply(this, arguments);
          window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'navigationChange' }));
          return res;
        }
      }
      history.pushState = wrap(history.pushState);
      history.replaceState = wrap(history.replaceState);
      window.addEventListener('popstate', function() {
        window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'navigationChange' }));
      });
    })();
    true;
  `;

  const JS_EXTRACT_HTML = `
    document.addEventListener("DOMContentLoaded", function(){
      var title = document.title;
      var html = document.documentElement.outerHTML;
      var url = window.location.href;
      var image = '';
      var imageMeta = document.querySelector("link[rel='icon']") || document.querySelector("link[rel='shortcut icon']");
      if (imageMeta) {
        image = imageMeta.getAttribute('href')
        if (image.startsWith('//')) {
          image = window.location.protocol + image;
        } else if (image.startsWith('/')) {
          image = window.location.protocol + '//' + window.location.host + image;
        } else if (!image.startsWith('http')) {
          image = window.location.protocol + '//' + window.location.host + '/' + image;
        }
      }
      ReactNativeWebView.postMessage(JSON.stringify({
        event: 'html', html: html, title: title, url: url, image: image }));
    });
    ${parser?.preloadJs(id) || ''}
    true;
  `;

  const shouldCaptureGesture = (
    evt: GestureResponderEvent, gestureState: PanResponderGestureState,
  ) => {
    const shouldCapture = (
      (gestureState.moveX < WIDTH_CAPTURE_GESTURE && gestureState.moveX > 0 && gestureState.dx >= 0)
      || (gestureState.moveX > width - WIDTH_CAPTURE_GESTURE && gestureState.dx <= 0)
    );
    // console.log(name, shouldCapture, gestureState);
    return shouldCapture;
  };

  const disableWebviewSelection = () => {
    webviewRef.current?.injectJavaScript(
      `
        if (document.body.style.userSelect !== 'none') {
          document.body.style.userSelect = 'none';
        }
        true;
      `,
    );
  };

  const enableWebviewSelection = () => {
    webviewRef.current?.injectJavaScript(
      `
        if (document.body.style.userSelect === 'none') {
          document.body.style.userSelect = '';
        }
        true;
      `,
    );
  };

  const panResponder = React.useRef(
    PanResponder.create({
      onStartShouldSetPanResponder:
      (evt, gestureState) => shouldCaptureGesture(evt, gestureState),
      onStartShouldSetPanResponderCapture:
      (evt, gestureState) => shouldCaptureGesture(evt, gestureState),
      onMoveShouldSetPanResponder:
      (evt, gestureState) => shouldCaptureGesture(evt, gestureState),
      onMoveShouldSetPanResponderCapture:
      (evt, gestureState) => shouldCaptureGesture(evt, gestureState),
      onPanResponderGrant: (evt, gestureState) => {
        // The gesture has started. Show visual feedback so the user knows
        // what is happening!
        // gestureState.d{x,y} will be set to zero now
        // console.log('onPanResponderGrant: ', gestureState);
        if (gestureState.moveX > width - 30) {
          console.log('Open drawer menu');
          //   setIsDrawerOpen(true);
          drawerRef.current?.openDrawer({ velocity: 0.01 });
        }
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // Another component has become the responder, so this gesture
        // should be cancelled
        console.log('onPanResponderTerminate: ', gestureState);
        if (Platform.OS === 'android') {
          disableWebviewSelection();
          setTimeout(() => { enableWebviewSelection(); }, 1000);
        }
      },
    }),
  ).current;

  const moreItems: MenuItem[] = [];
  if (post) {
    moreItems.push(
      {
        title: 'Open in browser',
        onPress: () => {
          openURL(getRedirectUrl(post.mUrl, id));
        },
        iconType: 'material-community',
        iconName: 'google-chrome',
      },
      {
        title: 'Share post',
        onPress: () => {
          if (Platform.OS === 'ios') {
            Share.share({ url: shortUrl || post.mUrl });
          } else {
            Share.share({ message: shortUrl || post.mUrl });
          }
        },
        iconType: 'ionicons',
        iconName: 'ios-share',
      },

    );
    if (showNotification) {
      moreItems.push({
        title: post.following ? 'Turn off notification' : 'Turn on notification',
        onPress: () => {
          if (checkAndRedirectToSignIn()) {
            followPostMutation.mutate({ id, follow: !post.following });
          }
        },
        iconType: 'material-community',
        iconName: post.following ? 'bell' : 'bell-outline',
        iconColor: post.following ? colors.primary : 'grey',
      });
    }
    if (showSavePost) {
      moreItems.push({
        title: post.saved ? 'Unsave post' : 'Save post',
        onPress: () => {
          if (checkAndRedirectToSignIn()) { savePostMutation.mutate({ id, save: !post.saved }); }
        },
        iconType: 'ionicon',
        iconName: post.saved ? 'bookmark' : 'bookmark-outline',
        iconColor: post.saved ? colors.primary : 'grey',
      });
    }
    moreItems.push({
      title: 'Hide post',
      onPress: () => {
        if (checkAndRedirectToSignIn()) {
          alert(
            'Do you want to hide this post?',
            `${post.title}`,
            [
              { text: 'Cancel', style: 'cancel' },
              {
                text: 'OK',
                onPress: () => hidePostMutation.mutate({ id, hide: !post.hidden }),
              },
            ],
            { cancelable: true },
          );
        }
      },
      iconType: 'ionicon',
      iconName: 'eye-off-outline',
    },
    {
      title: 'Block user',
      onPress: () => {
        if (checkAndRedirectToSignIn()) {
          alert(
            'Do you want to block this user?',
            user ? `${user.username}${user.sourceName ? `@${user.sourceName}` : ''} ` : '',
            [
              { text: 'Cancel', style: 'cancel' },
              {
                text: 'OK',
                onPress: () => {
                  blockUserMutation.mutate({ id: user?.id || '', postId: id, block: true });
                },
              },
            ],
            { cancelable: true },
          );
        }
      },
      iconType: 'ionicon',
      iconName: 'person-remove-outline',
    },
    {
      title: post.reported ? 'Post reported' : 'Report post',
      onPress: () => {
        if (checkAndRedirectToSignIn()) { onReportPost(id); }
      },
      iconType: 'ionicon',
      iconName: post.reported ? 'flag' : 'flag-outline',
      iconColor: 'grey',
      disabled: post.reported,
    });
    if (showTopicSourceFilter) {
      moreItems.push({
        title: `Filter ${post.topic?.name} posts from ${post.source0.name}`,
        onPress: () => {
          if (checkAndRedirectToSignIn()) {
            alert(
              `Do not want to see posts from ${post.source0.name} on ${post.topic?.name}?`,
              '',
              [
                { text: 'Cancel', style: 'cancel' },
                {
                  text: 'OK',
                  onPress: () => {
                    blockTopicSourceMutation.mutate(
                      { topicId: post.topic?.id || '', sourceName: post.source0.name, block: true },
                    );
                  },
                },
              ],
              { cancelable: true },
            );
          }
        },
        iconType: 'ionicon',
        iconName: 'funnel-outline',
      },
      {
        title: `Filter all posts from ${post.source0.name}`,
        onPress: () => {
          if (checkAndRedirectToSignIn()) {
            alert(
              `Do not want to see all posts from ${post.source0.name}?`,
              '',
              [
                { text: 'Cancel', style: 'cancel' },
                {
                  text: 'OK',
                  onPress: () => {
                    blockTopicSourceMutation.mutate(
                      { topicId: '', sourceName: post.source0.name, block: true },
                    );
                  },
                },
              ],
              { cancelable: true },
            );
          }
        },
        iconType: 'ionicon',
        iconName: 'funnel-outline',
      });
    }
  }
  if (developerMode) {
    moreItems.push({
      title: showPromotion ? 'Hide promotion' : 'Show promotion',
      onPress: () => setShowPromotion(!showPromotion),
      iconType: 'ionicon',
      iconName: showPromotion ? 'bulb' : 'bulb-outline',
      iconColor: showPromotion ? colors.primary : 'grey',
    });
  }
  moreItems.push({
    title: 'Go back',
    onPress: () => navigateBack(),
    iconType: 'ionicon',
    iconName: 'arrow-undo',
  });

  const basicItems: MenuItem[] = [
    {
      title: 'Reader view',
      onPress: () => {
        if (isReaderView) {
          setIsReaderView(false);
        } else {
          setIsReaderView(true);
        }
      },
      iconType: 'ionicon',
      iconName: isReaderView ? 'reader' : 'reader-outline',
      iconColor: isReaderView ? colors.primary : undefined,
      disabled: parser === undefined,
    },
  ];
  if (isReaderView) {
    basicItems.push({
      title: 'Go to first page',
      onPress: () => {
        setIsLoadingPostItems(true);
        navigateToPostPage(1);
      },
      iconType: 'material-community',
      iconName: 'chevron-double-left',
      disabled: endPage === 1,
    }, {
      title: 'Go to previous page',
      onPress: () => {
        setIsLoadingPostItems(true);
        navigateToPostPage(endPage - 1);
      },
      iconType: 'material-community',
      iconName: 'chevron-left',
      disabled: endPage === 1,
    }, {
      item: () => (
        <Button
          title={`${endPage} / ${totalPage}`}
          onPress={() => setIsPageInputVisible(true)}
          buttonStyle={styles.pagination}
        />
      ),
    }, {
      title: 'Go to next page',
      onPress: () => {
        setIsLoadingPostItems(true);
        navigateToPostPage(endPage + 1);
      },
      iconType: 'material-community',
      iconName: 'chevron-right',
      disabled: endPage === totalPage,
    }, {
      title: 'Go to last page',
      onPress: () => {
        setIsLoadingPostItems(true);
        navigateToPostPage(totalPage);
      },
      iconType: 'material-community',
      iconName: 'chevron-double-right',
      disabled: endPage === totalPage,
    });
  } else {
    basicItems.push({
      title: 'Go back',
      onPress: () => webviewRef.current?.goBack(),
      iconType: 'material-community',
      iconName: 'chevron-left',
      disabled: !canGoBack,
    }, {
      title: isLoadingWeb ? 'Stop' : 'Refresh',
      onPress: isLoadingWeb
        ? () => webviewRef.current?.stopLoading()
        : () => {
          webviewRef.current?.injectJavaScript('window.location.reload(); true;');
        },
      iconType: 'ionicon',
      iconName: isLoadingWeb ? 'close' : 'refresh',
    }, {
      title: 'Go forward',
      onPress: () => webviewRef.current?.goForward(),
      iconType: 'material-community',
      iconName: 'chevron-right',
      disabled: !canGoForward,
    });
  }
  basicItems.push({
    title: 'More',
    onPress: () => drawerRef.current?.openDrawer({ velocity: 0.01 }),
    iconType: 'ionicon',
    iconName: 'md-menu',
  });

  const drawerItems = moreItems;

  const footerBarItems = basicItems;

  const renderDrawer = () => (
    <DrawerMenu
      drawerLayout={drawerRef.current}
      itemList={drawerItems}
      image=""
      imageTitle={getMainDomainFromUrl(webUrl)}
      title={webTitle}
    />
  );

  if (!parser || !url) {
    return (
      <View>
        <Text style={styles.emptyTitle}>Post does not exist 🤔</Text>
        <Button onPress={navigateBack} title="Go back"></Button>
      </View>
    );
  }

  const newPostItemId = (postViewPosition?.comments && parser.LINEAR_COMMENTS)
    ? (postViewPosition.comments + 2).toString() : undefined;

  return (
    <View
      style={{
        flex: 1, width: '100%',
      }}
      {...panResponder.panHandlers}
    >
      {/* @ts-ignore: children property. */}
      <DrawerLayout
        ref={drawerRef}
        contentContainerStyle={styles.drawerContentContainer}
        drawerWidth={Math.min(width * 0.75, 300)}
        drawerPosition="right"
        drawerType="front"
        renderNavigationView={renderDrawer}
        edgeWidth={50}
        onDrawerStateChanged={(newState, drawerWillShow) => {
          console.log(newState, drawerWillShow);
          if (newState === 'Dragging') {
            // setIsDrawerOpen(true);
            disableWebviewSelection();
          }
        }}
        onDrawerClose={() => {
          console.log('Drawer closed.');
          //   setIsDrawerOpen(false);
          enableWebviewSelection();
        }}
      >
        {(!isLoadingPost && !isLoadingPostViewPosition && post && postViewPosition) ? (
        <View style={styles.postViewContainer}>
          <View style={[styles.postView, { width, zIndex: isReaderView ? 10 : 0 }]}>
            <PostView
              ref={postViewRef}
              post={post}
              postItems={postItems}
              width={width}
              errorMessage={errorMessage}
              isLoadingPrev={isLoadingPostItemsPrev}
              isLoadingNext={isLoadingPostItemsNext}
              isLoadingItems={isLoadingPostItems}
              hasPrevious={hasPrevious}
              hasNext={hasNext}
              onCommentPost={onCommentPost}
              onUpVotePost={onUpVotePost}
              onEndReach={() => {
                if (!isLoadingPostItemsPrev && !isLoadingPostItemsNext && !isLoadingPostItems
                  && hasNext) {
                  setIsLoadingPostItemsNext(true);
                  if (parser?.FETCH_MORE_POST_ITEM_API) {
                    scrollToBottom();
                  } else {
                    navigateToPostPage(endPage + 1);
                  }
                }
              }}
              onRefresh={() => {
                if (!isLoadingPostItemsPrev && !isLoadingPostItemsNext && !isLoadingPostItems
                  && hasPrevious) {
                  setIsLoadingPostItemsPrev(true);
                  if (parser?.FETCH_MORE_POST_ITEM_API) {
                    scrollToTop();
                  } else {
                    navigateToPostPage(startPage - 1);
                  }
                }
              }}
              showSourceTitle={showSourceTitle}
              showTopic={showTopic}
              newPostItemId={newPostItemId}
              shortUrl={shortUrl}
            />
          </View>
          <WebView
            ref={webviewRef}
            source={{ uri: postViewPosition.mUrl || url || 'about:blank' }}
            style={[styles.webview, { width }]}
            onNavigationStateChange={onNavChange}
            onLoadEnd={() => setIsLoadingWeb(false)}
            onLoadStart={() => {
              setIsLoadingWeb(true);
              setTimeout(() => setIsLoadingWeb(false), 5000);
            }}
            onFileDownload={({ nativeEvent }) => {
              const { downloadUrl } = nativeEvent;
              openURL(downloadUrl);
            }}
            renderError={() => (
              <View style={styles.error}>
                <Text style={styles.errorText}>Can not open the page 😞</Text>
                <Text>{' '}</Text>
                <Text>{webUrl}</Text>
              </View>
            )}
            onContentProcessDidTerminate={(syntheticEvent) => {
              const { nativeEvent } = syntheticEvent;
              console.warn('Content process terminated, reloading: ', nativeEvent);
              webviewRef.current?.reload();
            }}
            injectedJavaScriptBeforeContentLoaded={JS_EXTRACT_HTML}
            onMessage={onMessage}
            // startInLoadingState
            // renderLoading={() => <View style={styles.loading} />}
            sharedCookiesEnabled
            allowsFullscreenVideo
            domStorageEnabled
            mediaPlaybackRequiresUserAction
            allowsInlineMediaPlayback
            allowsLinkPreview
            setSupportMultipleWindows={false}
            geolocationEnabled
        // allowsBackForwardNavigationGestures
            androidHardwareAccelerationDisabled
            cacheEnabled
            originWhitelist={['*']}
          />
        </View>) : (
        <View style={styles.loading} >
          <ActivityIndicator
            color={'grey'}
            size="large"
          />
        </View>)}
        <FooterBar showPromotion={showPromotion} appName={appName} itemList={footerBarItems} />
        <Dialog.Container
          visible={isPageInputVisible}
          onBackdropPress={onCancelPageInput}
        >
          <Dialog.Title>Go to Page</Dialog.Title>
          <Dialog.Description
            style={inputPageError ? { color: 'red' } as ViewStyle : undefined}
          >
            {inputPageError || 'Please enter a page number.'}
          </Dialog.Description>
          <Dialog.Input
            value={inputPage.toString()}
            placeholder={`${totalPage} Total Pages`}
            keyboardType="numeric"
            onChangeText={(text) => {
              setInputPage(text);
            }}
            onSubmitEditing={onEnterPageInput}
            autoFocus
          />
          <Dialog.Button
            label="Cancel"
            onPress={onCancelPageInput}
          />
          <Dialog.Button
            label="Go"
            onPress={onEnterPageInput}
          />
        </Dialog.Container>
      </DrawerLayout>
    </View>
  );
}

export { PostWebView };

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  drawerContentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  postViewContainer: {
    flex: 1,
  },
  postView: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  webview: {
    flex: 1,
  },
  loading: {
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  icon: {
    padding: 5,
  },
  error: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: 15,
  },
  errorText: {
    fontSize: 18,
  },
  pagination: {
    borderWidth: 2,
    paddingVertical: 0,
    paddingHorizontal: 15,
    borderRadius: 15,
    margin: 0,
  },
  emptyTitle: {
    fontSize: 18,
    color: colors.grey3,
    paddingVertical: 20,
    textAlign: 'center',
  },
});
